import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';

const StockMarketWidget = () => {
    const [country, setCountry] = useState('us');
    const [stockSymbol, setStockSymbol] = useState('AAPL');
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [textColor, setTextColor] = useState('#000000');
    const [fontSize, setFontSize] = useState('16');
    const [iframeContent, setIframeContent] = useState('');
    const [error, setError] = useState('');

    const countries = [
        { code: 'us', name: 'USA' },
        { code: 'de', name: 'Germany' },
        { code: 'uk', name: 'United Kingdom' },
    ];

    useEffect(() => {
        try {
            const content = `
                <!DOCTYPE html>
                <html>
                <head>
                    <style>
                        body {
                            margin: 0;
                            padding: 0;
                            background-color: ${backgroundColor};
                            color: ${textColor};
                            font-family: Arial, sans-serif;
                            font-size: ${fontSize}px;
                        }
                        .widget-container {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            height: 100vh;
                            padding: 10px;
                        }
                        .widget-header {
                            font-size: 1.2em;
                            font-weight: bold;
                            margin-bottom: 10px;
                        }
                        .widget-content {
                            text-align: center;
                        }
                    </style>
                </head>
                <body>
                    <div class="widget-container">
                        <div class="widget-header">Stock Market Information</div>
                        <div class="widget-content">
                            <p>Symbol: ${stockSymbol}</p>
                            <p>Country: ${countries.find(c => c.code === country).name}</p>
                            <p>Price: $XX.XX</p>
                            <p>Change: +/-X.XX%</p>
                        </div>
                    </div>
                </body>
                </html>
            `;
            setIframeContent(content);
        } catch (e) {
            setError("Önizleme içeriği oluşturulamadı.");
        }
    }, [country, stockSymbol, backgroundColor, textColor, fontSize]);

    const handleSaveHtml = () => {
        const blob = new Blob([iframeContent], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'stock-market-widget.html';
        link.click();
    };

    return (
        <Container>
            <Row>
                <Col xs={12} md={4} lg={3}>
                    <Card className="mb-4">
                        <Card.Header>Stock Market Widget Settings</Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group controlId="countrySelect" className="mb-3">
                                    <Form.Label>Ülke Seçiniz:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                    >
                                        {countries.map((c) => (
                                            <option key={c.code} value={c.code}>
                                                {c.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="symbolSelect" className="mb-3">
                                    <Form.Label>Hisse Senedi Sembolü:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={stockSymbol}
                                        onChange={(e) => setStockSymbol(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="backgroundColor" className="mb-3">
                                    <Form.Label>Arka Plan Rengi:</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={backgroundColor}
                                        onChange={(e) => setBackgroundColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="textColor" className="mb-3">
                                    <Form.Label>Yazı Rengi:</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={textColor}
                                        onChange={(e) => setTextColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="fontSize" className="mb-3">
                                    <Form.Label>Yazı Boyutu (px):</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={fontSize}
                                        onChange={(e) => setFontSize(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" onClick={handleSaveHtml}>
                                    HTML Kaydet
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <Card>
                        <Card.Header>Preview</Card.Header>
                        <Card.Body style={{ padding: 0 }}>
                            {error && <Alert variant="danger" className="m-3">{error}</Alert>}
                            <iframe
                                srcDoc={iframeContent}
                                title="Stock Market Widget Preview"
                                style={{
                                    width: '100%',
                                    height: '400px',
                                    border: 'none'
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default StockMarketWidget;
