import axios from "../api/axios";
import { widgetUpload } from "../api/routes";
const uploadWidget = async (name, html) => {
    try {
        const response = await axios.post(widgetUpload, {
        name,
        html,
        });
        return response.data;
    } catch (error) {
        return error;
    }
    };
    export default uploadWidget;
 