import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import uploadWidget from './uploadWidget';

const PharmacyWidget = () => {
    const [city, setCity] = useState('ankara');
    const [cityId, setCityId] = useState('6');
    const [backgroundColor, setBackgroundColor] = useState('#920938');
    const [scrollSpeed, setScrollSpeed] = useState(50);
    const [iframeContent, setIframeContent] = useState('');
    const [error, setError] = useState('');
    const iframeRef = useRef(null);

    const cities = [
        { id: '6', name: 'Ankara' },
        { id: '34', name: 'Istanbul' },
        { id: '35', name: 'Izmir' },
    ];

    const handleCityChange = (e) => {
        const selectedCity = cities.find(city => city.id === e.target.value);
        setCity(selectedCity.name.toLowerCase());
        setCityId(selectedCity.id);
    };

    useEffect(() => {
        try {
            const content = `
                <!DOCTYPE html>
                <html>
                <head>
                    <style>
                        body {
                            margin: 0;
                            padding: 0;
                            background-color: ${backgroundColor};
                            color: #fff;
                            font-family: Arial, sans-serif;
                            overflow: hidden;
                        }
                        .widget-container {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: 10px;
                        }
                        .widget-header {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;
                        }
                        .widget-header img {
                            max-width: 100%;
                        }
                        .widget-link {
                            text-align: center;
                            font-size: 17px;
                            color: #fff;
                            text-decoration: none;
                        }
                        .iframe-container {
                            width: 100%;
                            max-width: 400px;
                            height: 350px;
                            overflow: hidden;
                            position: relative;
                        }
                        .iframe-content {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            animation: scroll ${scrollSpeed}s linear infinite;
                        }
                        @keyframes scroll {
                            0% { transform: translateY(0); }
                            100% { transform: translateY(-100%); }
                        }
                    </style>
                </head>
                <body>
                    <div class="widget-container">
                        <div class="widget-header">
                            <a
                                href="https://www.trnobetcieczane.com/il/${city}-nobetci-eczane/"
                                class="widget-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="https://www.trnobetcieczane.com/assets/img/nobetcieczane.jpg"
                                    alt="${city.charAt(0).toUpperCase() + city.slice(1)} Nöbetçi Eczane Listesi"
                                />
                            </a>
                        </div>
                        <a
                            href="https://www.trnobetcieczane.com/il/${city}-nobetci-eczane/"
                            class="widget-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ${city.charAt(0).toUpperCase() + city.slice(1)} Nöbetçi Eczane Listesi
                        </a>
                        <div class="iframe-container">
                            <iframe
                                src="https://www.trnobetcieczane.com/iframe.php?sehir=${cityId}"
                                class="iframe-content"
                                name="Nöbetçi eczane ${city}"
                                title="Pharmacy Widget"
                            ></iframe>
                        </div>
                    </div>
                </body>
                </html>
            `;
            setIframeContent(content);
        } catch (e) {
            setError("Önizleme içeriği oluşturulamadı.");
        }
    }, [city, cityId, backgroundColor, scrollSpeed]);

    const handleSaveHtml = () => {
        const blob = new Blob([iframeContent], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'pharmacy-widget.html';
        link.click();
        uploadWidget('pharmacyWidget', iframeContent);
    };

    return (
        <Container>
            <Row>
                <Col xs={12} md={4} lg={3}>
                    <Card className="mb-4">
                        <Card.Header>Eczane Widget Ayarları</Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group controlId="citySelect" className="mb-3">
                                    <Form.Label>Şehir Seçiniz:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={cityId}
                                        onChange={handleCityChange}
                                    >
                                        {cities.map((city) => (
                                            <option key={city.id} value={city.id}>
                                                {city.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="backgroundColor" className="mb-3">
                                    <Form.Label>Arka Plan Rengi:</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={backgroundColor}
                                        onChange={(e) => setBackgroundColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="scrollSpeed" className="mb-3">
                                    <Form.Label>Kaydırma Hızı (sn):</Form.Label>
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        value={scrollSpeed}
                                        onChange={(e) => setScrollSpeed(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" className="mt-3" onClick={() => setIframeContent(iframeContent)}>
                                    Widget Oluştur
                                </Button>
                                <Button variant="secondary" className="mt-3 ms-2" onClick={handleSaveHtml}>
                                    HTML Kaydet
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <Card>
                        <Card.Header>Önizleme</Card.Header>
                        <Card.Body style={{ padding: 0 }}>
                            {error && <Alert variant="danger" className="m-3">{error}</Alert>}
                            <iframe
                                ref={iframeRef}
                                srcDoc={iframeContent}
                                title="Pharmacy Widget Preview"
                                style={{
                                    width: '100%',
                                    height: '600px',
                                    border: 'none'
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PharmacyWidget;
