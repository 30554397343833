import React, { useState, useContext } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow, faEdit, faTrashAlt, faBuilding } from "@fortawesome/free-solid-svg-icons";
import axios from "../api/axios";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import {
  changePasswordRoute,
  companyList,
  deleteCompany,
  updateCompany,
} from "../api/routes";
import { AuthContext } from "../contexts/AuthContext";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 15px;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const CompanyHeader = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  display: flex;
  align-items: center;
`;

const CompanyLogo = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CompanyInfo = styled.div`
  flex: 1;
`;

const CompanyName = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`;

const CompanyLocation = styled.p`
  display: flex;
  align-items: center;
  color: #6c757d;
  margin-bottom: 0;
  font-size: 0.9rem;
`;

const ActionButton = styled(Button)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;

  svg {
    margin-right: 0.25rem;
  }
`;

function CompanyCard({
  companyName,
  companyLogoPath,
  companyLocation,
  companyId,
  userId,
}) {
  const [show, setShow] = useState(false);
  const [companyNamenew, setCompanyName] = useState(companyName);
  const [companyLocationnew, setCompanyLocation] = useState(companyLocation);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const { updateCompanyLogoPath, updateUser } = useContext(AuthContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const [deleting, setDeleting] = useState(false);

  const deleteCompanies = async () => {
    try {
      const res = await axios.post(deleteCompany, {
        companyid: companyId,
        userId: userId,
      });
      console.log("res", res);
      if (res.data.success) {
        updateUser(res.data.data);
      }
      setLoading(false); // Set loading to false when the Axios call is finished
    } catch (error) {
      console.error("Error:", error);

      toast.error("Failed to delete company information.");
    }
  };

  const submit = async () => {
    setLoading(true); // Set loading to true when the Axios call starts

    try {
      const res = await axios.post(updateCompany, {
        companyName: companyNamenew?.val,
        companyLocation: companyLocationnew?.val,
        companyid: companyId,
        userId: userId,
      });

      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("description", "description");

        const result = await axios.post("/user/company-profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            companyId: companyId,
            userId: userId,
          },
        });
        console.log("result", result.data.data);

        updateUser(result.data.data);
        if (result.data.success) {
          // updateCompanyLogoPath(companyId, res.data.data.company[0].companyLogoPath);
          updateUser(result.data.data);
          setLoading(false);
          handleClose();
          return;
        }
      }

      updateUser(res.data.data);
      setLoading(false); // Set loading to false when the Axios call is finished
      handleClose();
    } catch (error) {
      console.error("Error:", error);
      setLoading(false); // Set loading to false in case of an error
      toast.error("Failed to update company information.");
    }
  };

  return (
    <StyledCard>
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirmDelete={() => deleteCompanies(companyId)}
        loading={deleting}
        title="Delete Company"
        body="Are you sure you want to delete this company?"
      />
      <CompanyHeader>
        <CompanyLogo>
          {companyLogoPath ? (
            <img src={companyLogoPath} alt={companyName} crossOrigin="anonymous" />
          ) : (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "center" }}>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
              width: "60px", // Smaller than container to provide padding
              height: "60px",
              verticalAlign: "center",
              fill: "#cd204f", // Match border color
              overflow: "hidden",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
            }}
            viewBox="0 0 1024 1024"
          >
            <path d="M901.12 841.728h-51.712V374.272c0-72.704-41.472-103.936-67.584-109.056l-165.888-51.712V156.16c0-10.24-5.12-15.36-10.24-20.992-5.12-5.12-15.36-5.12-20.992-5.12l-347.648 98.816s-62.464 15.36-62.464 98.816v514.048H122.88c-15.36 0-26.112 10.24-26.112 26.112 0 15.36 10.24 26.112 26.112 26.112h778.24c15.36 0 26.112-10.24 26.112-26.112 0-15.872-10.24-26.112-26.112-26.112zm-337.408 0H226.304V327.68c0-36.352 20.992-46.592 26.112-46.592l311.296-88.064v648.704zm233.984 0h-181.76V265.216l160.256 53.248 3.584 1.536s15.872 6.656 18.432 54.272v467.456zM371.712 300.032H320v51.712h51.712v-51.712zm119.296 0h-51.712v51.712h51.712v-51.712zM371.712 413.184H320v51.712h51.712v-51.712zm119.296 0h-51.712v51.712h51.712v-51.712zM371.712 526.336H320v51.712h51.712v-51.712zm119.296 0h-51.712v51.712h51.712v-51.712zm-119.296 112.64H320V691.2h51.712v-52.224zm119.296 0h-51.712V691.2h51.712v-52.224zM371.712 752.128H320v51.712h51.712v-51.712zm119.296 0h-51.712v51.712h51.712v-51.712zM683.52 452.096h51.712v-51.712H683.52v51.712zm0 119.296h51.712V519.68H683.52v51.712zm0 113.664h51.712v-51.712H683.52v51.712zm0 115.2h51.712v-51.712H683.52v51.712z" />
          </svg>
          </div>
          )}
        </CompanyLogo>
        <CompanyInfo>
          <CompanyName>{companyName}</CompanyName>
          <CompanyLocation>
            <FontAwesomeIcon icon={faLocationArrow} style={{ marginRight: '0.5rem' }} />
            {companyLocation}
          </CompanyLocation>
        </CompanyInfo>
      </CompanyHeader>
      <Card.Body>
        <ButtonGroup className="w-100">
          <ActionButton
            variant="outline-danger"
            onClick={handleShowDeleteModal}
          >
            <FontAwesomeIcon icon={faTrashAlt} /> Delete
          </ActionButton>
          <ActionButton
            variant="outline-primary"
            onClick={handleShow}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit
          </ActionButton>
        </ButtonGroup>
      </Card.Body>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Company Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Company name</Form.Label>
              <Form.Control
                type="text"
                placeholder={companyName}
                defaultValue={companyName}
                autoFocus
                onChange={(e) => setCompanyName({ val: e.target.value })}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Company location</Form.Label>
              <Form.Control
                type="text"
                placeholder={companyLocation}
                defaultValue={companyLocation}
                onChange={(e) => setCompanyLocation({ val: e.target.value })}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Company logo</Form.Label>
              <input
                filename={file}
                onChange={(e) => setFile(e.target.files[0])}
                type="file"
                accept="image/video"
              />
              {file ? (
                <img
                  crossOrigin="anonymous"
                  src={URL.createObjectURL(file)}
                  width="100"
                  height="50"
                />
              ) : null}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submit}>
            Save Changes
            {loading && <Spinner animation="grow" size="sm" className="ml-2" />}
          </Button>
        </Modal.Footer>
      </Modal>
    </StyledCard>
  );
}

export default CompanyCard;
