import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card, Table } from 'react-bootstrap';

const GoogleSheetWidget = () => {
    const [sheetUrl, setSheetUrl] = useState('');
    const [sheetData, setSheetData] = useState([]);
    const [error, setError] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [textColor, setTextColor] = useState('#000000');
    const [fontSize, setFontSize] = useState('14px');
    const [scrollSpeed, setScrollSpeed] = useState(1);
    const scrollRef = useRef(null);

    const fetchSheetData = async () => {
        try {
            // Extract the sheet ID from the URL
            const sheetId = sheetUrl.match(/[-\w]{25,}/);
            if (!sheetId) {
                throw new Error('Invalid Google Sheet URL');
            }

            const response = await fetch(`https://docs.google.com/spreadsheets/d/${sheetId[0]}/gviz/tq?tqx=out:json`);
            const text = await response.text();
            const json = JSON.parse(text.substr(47).slice(0, -2));

            const headers = json.table.cols.map(col => col.label);
            const rows = json.table.rows.map(row => row.c.map(cell => cell ? cell.v : ''));

            setSheetData([headers, ...rows]);
            setError('');
        } catch (e) {
            setError('Google Sheet yüklenemedi. Lütfen URL\'yi kontrol edin ve tablonun paylaşıma açık olduğundan emin olun.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchSheetData();
    };

    useEffect(() => {
        let scrollInterval;
        if (scrollRef.current && sheetData.length > 0) {
            const scrollHeight = scrollRef.current.scrollHeight;
            const clientHeight = scrollRef.current.clientHeight;
            let scrollTop = 0;

            scrollInterval = setInterval(() => {
                scrollTop += scrollSpeed;
                if (scrollTop >= scrollHeight - clientHeight) {
                    scrollTop = 0;
                }
                scrollRef.current.scrollTop = scrollTop;
            }, 50);
        }

        return () => {
            if (scrollInterval) {
                clearInterval(scrollInterval);
            }
        };
    }, [sheetData, scrollSpeed]);

    return (
        <Container>
            <Row>
                <Col xs={12} md={4} lg={3}>
                    <Card className="mb-4">
                        <Card.Header>Google Sheet Widget Settings</Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="sheetUrl">
                                    <Form.Label>Google Sheet URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Google Sheet URL'sini girin"
                                        value={sheetUrl}
                                        onChange={(e) => setSheetUrl(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="backgroundColor" className="mt-3">
                                    <Form.Label>Arka Plan Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={backgroundColor}
                                        onChange={(e) => setBackgroundColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="textColor" className="mt-3">
                                    <Form.Label>Yazı Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={textColor}
                                        onChange={(e) => setTextColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="fontSize" className="mt-3">
                                    <Form.Label>Yazı Boyutu</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={fontSize}
                                        onChange={(e) => setFontSize(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="scrollSpeed" className="mt-3">
                                    <Form.Label>Kaydırma Hızı</Form.Label>
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        max="10"
                                        value={scrollSpeed}
                                        onChange={(e) => setScrollSpeed(Number(e.target.value))}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-3">
                                    Widget Oluştur
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <Card>
                        <Card.Header>Preview</Card.Header>
                        <Card.Body style={{ padding: 0, height: '400px', overflow: 'hidden' }}>
                            {error && <Alert variant="danger" className="m-3">{error}</Alert>}
                            {sheetData.length > 0 && (
                                <div
                                    ref={scrollRef}
                                    style={{
                                        backgroundColor,
                                        color: textColor,
                                        fontSize,
                                        height: '100%',
                                        overflowY: 'scroll',
                                        overflowX: 'auto',
                                        width: '100%'
                                    }}
                                >
                                    <div style={{ minWidth: '100%', width: 'max-content' }}>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    {sheetData[0].map((header, index) => (
                                                        <th key={index}>{header}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sheetData.slice(1).map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default GoogleSheetWidget;
