import React from "react";
import { NavLink } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import { Navbar, Container, Nav, DropdownButton } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';

import { useAuthContext } from "../../contexts/AuthContext";


import Home from "../../containers/Home/Home";
import About from "../../containers/About/About";
import Login from "../../containers/Login/Login";
import Register from "../../containers/Register/Register";
import Profile from "../../containers/Profile/Profile";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/fontawesome-free-solid";

import "./Navbar.scss";
import { Switch } from "react-router-dom/cjs/react-router-dom";
const languages = ['en', 'tr', 'de', 'fr', 'es', 'hi', 'ja', 'ko', 'pt', 'ur', 'ar'];
const rtlLanguages = ["ar", "ur"]

const NavBar = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuthContext();
  const { t, i18n } = useTranslation(); // Add useTranslation hook
  function setPageDirection(language) {
    const dir = rtlLanguages.includes(language) ? "rtl" : "ltr"
    document.documentElement.dir = dir
  }

  const handleLanguageChange = (language) => {
    setPageDirection(language)
    i18n.changeLanguage(language); // Change language
  };
  const defaultItemStyle = {
    fontSize: '16px',
    transition: 'background-size 0.3s ease', // Add transition for a smooth effect
  };

  const hoverItemStyle = {
    backgroundSize: '120% 120%', // Adjust the background size as needed
  };
  const customItemStyle = {
    fontSize: 16,
    padding: '5px 10px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#eee',
    },
  };

  return (
    <>
      <div className="nav-bar">
        <Navbar collapseOnSelect justify-content-between expand="lg" bg="dark" variant="dark" sticky='top'>
          <Container>
            <Navbar.Brand className="nav-heading">
              <NavLink to="/">DFNPlayer</NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/"
                >
                  {t("navigation.home")}
                </NavLink>
                {isLoggedIn && (
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/profile"
                  >
                    Profile
                  </NavLink>
                )}
                {!isLoggedIn && (
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/login"
                  >
                    {t("navigation.login")}
                  </NavLink>
                )}
                {!isLoggedIn && (
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/register"
                  >
                    {t("navigation.register")}
                  </NavLink>
                )}
              </Nav>
              <Navbar.Collapse className="justify-content-end">
                <Dropdown>
                  <Dropdown.Toggle variant="outline-warning" id="dropdown-basic" style={{ color: 'white', borderColor: '#cd204f' }}>
                    {t("navigation.language")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="language-dropdown-menu">
                    {languages.map((language) => (
                      <Dropdown.Item
                        key={language}
                        onClick={() => handleLanguageChange(language)}
                        className={`language-dropdown-item ${i18n.language === language ? 'active' : ''}`}
                      >
                        {t(`languages.${language}`)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {isLoggedIn && (
                  <Nav>
                    <NavLink
                      to="/"
                      onClick={() => {
                        localStorage.removeItem("token");
                        setIsLoggedIn(false);
                      }}
                    >
                      <FontAwesomeIcon className="logout-icon" icon={faPowerOff} />
                    </NavLink>
                  </Nav>
                )}
              </Navbar.Collapse>


            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <Switch>
        <Route path="/" element={<Home isLoggedIn />} />
        <Route path="about" element={<About />} />
        {isLoggedIn && <Route path="profile" element={<Profile />} />}
        {!isLoggedIn && <Route path="login" element={<Login />} />}
        {!isLoggedIn && <Route path="register" element={<Register />} />}
      </Switch>
    </>
  );
};

export default NavBar;
