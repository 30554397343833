import React, { useEffect, useState } from "react";
import axios from "../api/axios";
import { toast } from "react-toastify";
import MediaGridView from "../components/MediaGridView";
import MyWidgetGrid from "../components/MyWidgetGrid";
import FloatingActionButton from "../components/FloatingActionButton";
import { OverlayTrigger, Popover, ProgressBar, Tabs, Tab } from "react-bootstrap";
import { useDevice } from "../contexts/Device/DeviceState";
import { setLoading, getMedia, getWidget } from "../contexts/Device/DeviceAction";

const MyMedia = () => {
  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus" title="Add New Media">
      Click to add images or videos to your collection
    </Popover>
  );

  const [userState, userDispatch] = useDevice();
  const { media, widget } = userState;

  // Calculate total file size
  const mediaWithSize = media.filter(item => item?.fileSizeMB !== undefined);
  const totalFileSize = mediaWithSize.reduce(
    (total, item) => total + item.fileSizeMB,
    0
  );

  // Get user info handler
  const getUserInfoHandler = async () => {
    await getMedia(userDispatch);
    setLoading(userDispatch, false);
  };

  useEffect(() => {
    getUserInfoHandler();
    getWidget(userDispatch);
  }, []);

  const containerStyle = {
    padding: "24px",
    maxWidth: "1200px",
    margin: "0 auto",
  };

  const fabContainerStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: "1",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ textAlign: "center", marginBottom: "24px", color: "#cd204f" }}>
        Media & Widget Collection
      </h1>

      {/* Display progress bar */}
      {totalFileSize > 0 ? (
        <div style={{ marginBottom: "16px", backgroundColor: "#e9ecef", borderRadius: "0.25rem" }}>
          <div
            style={{
              width: `${(totalFileSize / 10) * 100}%`, // Calculate width based on totalFileSize
              backgroundColor: "#cd204f", // Progressed color
              height: "1rem", // Height of the progress bar
              borderRadius: "0.25rem", // Rounded corners
            }}
          />
          <span style={{ marginLeft: "10px" }}>{`${totalFileSize.toFixed(2)} MB`}</span>
        </div>
      ) : null}

      {/* Tabs for Media and Widgets */}
      <Tabs defaultActiveKey="media" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="media" title={<span style={{ color: '#cd204f' }}>Media</span>}>
          <MediaGridView data={media} getUserInfoHandler={getUserInfoHandler} />
        </Tab>
        <Tab eventKey="widgets" title={<span style={{ color: '#cd204f' }}>Widgets</span>}>
          <MyWidgetGrid data={widget} getUserInfoHandler={() => {
            getWidget(userDispatch);
            setLoading(userDispatch, false);
          }} />
        </Tab>
      </Tabs>

      <div style={fabContainerStyle}>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={popoverHoverFocus}
        >
          <FloatingActionButton
            onClick={() => {
              // Handle button click here
            }}
            onSuccess={() => {
              getUserInfoHandler();
            }}
            userId={""}
            type={"mymedia"}
          />
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default MyMedia;
