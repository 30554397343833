import axios from "../../api/axios";
import { toast } from "react-toastify";
import { deviceList, getAllMedia, allPlaylist, getAllWidget } from "../../api/routes";
// Set Loading
export const setLoading = (dispatch, status) =>
  dispatch({ type: "SET_LOADING", payload: status });

// Set Error
export const setError = (dispatch, error) =>
  dispatch({
    type: "SET_ERROR",
    payload: { error: error.status, message: error.message },
  });

// Set User (get user info)
export const getDevice = async (dispatch, compnayId) => {
  setLoading(dispatch, true);
  await axios
    .post(deviceList, { companyid: compnayId })
    .then(function (response) {
   
      //toast.success("Password Changed Successfully!");
      console.log("deviceList", response.data.data);
      const result = response.data.data;

      dispatch({
        type: "SET_USER",
        payload: result,
      });
    })
    .catch(function (error) {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
  setLoading(dispatch, false);
};
export const getMedia = async (dispatch) => {
  setLoading(dispatch, true);

  await axios
    .post(getAllMedia)
    .then(function (response) {
      console.log(response.data.data);
      const result = response.data.data;

      // set user info
      dispatch({
        type: "SET_MEDIA",
        payload: result,
      });
    })
    .catch(function (error) {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
  setLoading(dispatch, false);
};

export const getWidget = async (dispatch) => {
  setLoading(dispatch, true);

  await axios
    .get(getAllWidget)
    .then(function (response) {
      console.log(response.data);
      const result = response.data;
      console.log("widget", result);
      // set user info
      dispatch({
        type: "SET_WIDGET",
        payload: result,
      });
    })
    .catch(function (error) {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
  setLoading(dispatch, false);
};
export const getAllPlaylist = async (dispatch) => {
  setLoading(dispatch, true);

  await axios
    .get(allPlaylist)
    .then(function (response) {
      console.log(response.data);
      const result = response.data.playlists;
      console.log("playlist", result);
      // set user info
      dispatch({
        type: "SET_PLAYLIST",
        payload: result,
      });
    })
    .catch(function (error) {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
  setLoading(dispatch, false);
};
export const setCompany = async (dispatch, companyID) => {
  dispatch({
    type: "SET_COMPANY",
    payload: {
      id: companyID,
    },
  });
};
