import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';

const API_URL = 'https://api.metalpriceapi.com/v1/latest?base=USD&symbols=XAU';

const GoldPriceWidget = () => {
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [textColor, setTextColor] = useState('#000000');
    const [fontSize, setFontSize] = useState('24');
    const [price, setPrice] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [iframeContent, setIframeContent] = useState('');
    const iframeRef = useRef(null);

    const fetchGoldPrice = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(API_URL);
            const data = await response.json();
            if (data && data.rates && data.rates.XAU) {
                setPrice(data.rates.XAU);
            } else {
                setError('Gold price data is unavailable.');
            }
        } catch (err) {
            setError('Failed to fetch gold price.');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchGoldPrice();
    }, []);

    useEffect(() => {
        const content = `
            <!DOCTYPE html>
            <html>
            <head>
                <style>
                    body {
                        margin: 0;
                        padding: 0;
                        background-color: ${backgroundColor};
                        color: ${textColor};
                        font-family: Arial, sans-serif;
                        text-align: center;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                    .widget-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 100vh;
                        padding: 10px;
                    }
                    .widget-header {
                        font-size: ${parseInt(fontSize) * 0.75}px;
                        margin-bottom: 10px;
                    }
                    .widget-price {
                        font-size: ${fontSize}px;
                        font-weight: bold;
                    }
                </style>
            </head>
            <body>
                <div class="widget-container">
                    <div class="widget-header">Gold Price</div>
                    <div class="widget-price">${price !== null ? `$${price.toFixed(2)}` : 'Loading...'}</div>
                </div>
            </body>
            </html>
        `;
        setIframeContent(content);
    }, [price, backgroundColor, textColor, fontSize]);

    const handleSaveHtml = () => {
        const blob = new Blob([iframeContent], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'gold-price-widget.html';
        link.click();
    };

    return (
        <Container>
            <Row>
                <Col xs={12} md={4} lg={3}>
                    <Card className="mb-4">
                        <Card.Header>Gold Price Widget Settings</Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group controlId="backgroundColor" className="mb-3">
                                    <Form.Label>Background Color</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={backgroundColor}
                                        onChange={(e) => setBackgroundColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="textColor" className="mb-3">
                                    <Form.Label>Text Color</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={textColor}
                                        onChange={(e) => setTextColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="fontSize" className="mb-3">
                                    <Form.Label>Font Size (px)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={fontSize}
                                        onChange={(e) => setFontSize(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" onClick={fetchGoldPrice}>
                                    Fetch Gold Price
                                </Button>
                                <Button variant="secondary" className="ms-2" onClick={handleSaveHtml}>
                                    Save HTML
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <Card>
                        <Card.Header>Preview</Card.Header>
                        <Card.Body style={{ padding: 0 }}>
                            {loading && <Alert variant="info" className="m-3">Loading...</Alert>}
                            {error && <Alert variant="danger" className="m-3">{error}</Alert>}
                            <iframe
                                ref={iframeRef}
                                srcDoc={iframeContent}
                                title="Gold Price Widget Preview"
                                style={{
                                    width: '100%',
                                    height: '400px',
                                    border: 'none'
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default GoldPriceWidget;
