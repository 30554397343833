export const Image = ({ title, largeImage, smallImage }) => {
  const imageStyle = {
    maxWidth: '100%',
    height: 'auto', // This maintains the aspect ratio
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };

  return (
    <div className='col-md-6 col-sm-6'>
      <div className='portfolio-item'>
        <div className='hover-bg'>
          <a
            href={largeImage}
            title={title}
            data-lightbox-gallery='gallery1'
          >
            <div className='hover-text'>
              <h4>{title}</h4>
            </div>
            <div style={containerStyle}>
              <img
                src={smallImage}
                style={imageStyle}
                alt={title}
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}
