import "./FormField.scss";

const FormField = (props) => {
  const { type, name, placeholder, setFunc } = props;

  return (
    <input
      type={type}
      className="form-control"
      id={name ? name : `${type}Input`}
      name={name ? name : `${type}Input`}
      placeholder={placeholder}
      onChange={(event) => setFunc?.(event.target.value)}
    />
  );
};

export default FormField;
