import { 
  faTv, 
  faList, 
  faFilter, 
  faPhotoVideo, 
  faChartBar, 
  faCreditCard,
  faPersonRays,faSquare
} from "@fortawesome/free-solid-svg-icons";


const SidebarItems = [
  {
    name: "Devices",
    route: "/dashboard",
    icon: faTv,
  },
  {
    name: "Playlists",
    route: "/playlist",
    icon: faList,
  },
  {
    name: "Filters",
    route: "/filter",
    icon: faFilter,
  },
  {
    name: "My Media",
    route: "mymedia",
    icon: faPhotoVideo,
  },
  {
    name: "Widgets",
    route: "/widgets",
    icon: faSquare,
    badge: "Coming soon",
  },
  {
    name: "Analytics",
    route: "analytics",
    icon: faChartBar,
  },
  {
    name: "Plans",
    route: "plans",
    icon: faCreditCard,
  },
  {
    name: "Account",
    route: "account",
    icon: faPersonRays,
  },
];

export default SidebarItems;
