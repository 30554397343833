import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import validator from "validator";
import axios from "../api/axios";
import { getAllFilters, addPlaylist, updatePlaylist } from "../api/routes";
import { AuthContext } from "../contexts/AuthContext";
import "./Login.scss";
import FileUploadModal from "../components/FileUploadModal";
import SidePanel from "../components/SidePanel";
import PlaylistCard from "../components/PlaylistCard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faClose } from "@fortawesome/free-solid-svg-icons";
import FloatingActionButton from "../components/FloatingActionButton";
import SetPlaylistToDeviceModal from "../components/SetPlaylistToDeviceModal";
import CreatePlaylistCard from "../components/CreatePlaylistCard";
import WidgetCard from "../components/WidgetCard";
import TextInput from "../components/TextInput";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Form, Row, Breadcrumb } from "react-bootstrap";
import { useDevice } from "../contexts/Device/DeviceState";
import {
  setLoading,
  getMedia,
  getAllPlaylist,
  getWidget
} from "../contexts/Device/DeviceAction";

const Page1 = () => {
  const [userState, userDispatch] = useDevice();
  const { media, playlist,widget,companyId} = userState;
  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(false);

  const [addDevicePlaylistModal, setAddDevicePlaylistModal] = useState(false);
  const [playlistId, setPlaylistId] = useState(false);

  const { setIsLoggedIn, setUser, isLoggedIn } = useContext(AuthContext);
  const [label, setLabel] = useState(null);
  const [name, setName] = useState(playlist[editingIndex]?.playlistName??null);
  const [isVisible, setIsVisible] = useState(true);




  const getPlaylist = async () => {
    await getAllPlaylist(userDispatch);
    setLoading(userDispatch, false);
  };
  const getUserWidgets = async () => {
    await getWidget(userDispatch);
    setLoading(userDispatch, false);
  };
  const [filters, setFilters] = useState([]);

  const getFilter = () => {
    // Fetch all user filters from the backend API
    axios
      .get(getAllFilters)
      .then((response) => {
        if (response.data.success) {
          setFilters(response.data.filters);
        }
      })
      .catch((error) => {
        console.error("Error fetching filters:", error);
      });
  };

  useEffect(() => {
    getPlaylist();
    getUserMedia();
    getFilter();
    getUserWidgets();
  }, []);

  const getUserMedia = async () => {
    await getMedia(userDispatch);
    setLoading(userDispatch, false);
  };

  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus" title="Popover bottom">
      <strong>Create new Playlist</strong> Click to create
    </Popover>
  );

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    setMediaItems((prevItems) => {
      const updatedMediaItems = [...prevItems];
      const [reorderedItem] = updatedMediaItems.splice(result.source.index, 1);
      updatedMediaItems.splice(result.destination.index, 0, reorderedItem);
      return updatedMediaItems;
    });
  };

  const [mediaItems, setMediaItems] = useState([]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const mediaData = event.dataTransfer.getData("mediaItem");
    const widgetData = event.dataTransfer.getData("widgetItem");

    if (mediaData) {
      handleMediaDrop(event);
    } else if (widgetData) {
      handleWidgetDrop(event);
    }
  };

  const handleMediaDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("mediaItem");
    const mediaItem = JSON.parse(data);
    console.log("mediaitem", mediaItem);
    mediaItem.duration = 5;
    mediaItem.selectedPlaylist = null;
    setMediaItems((prevItems) => [...prevItems, mediaItem]);
  };

  const handleWidgetDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("widgetItem");
    const widgetItem = JSON.parse(data);
    setMediaItems((prevItems) => [...prevItems, { ...widgetItem, type: 'widget', id: `widget-${Date.now()}` }]);
  };

  const dragDropContextStyle = {
    width: "100%",
    flexDirection: "column",
  };

  const updateDurationAtIndex = (index, newDuration = 5) => {
    setMediaItems((prevItems) => {
      const updatedItems = [...prevItems];
      if (index >= 0 && index < updatedItems.length) {
        updatedItems[index].duration = newDuration;
      }
      return updatedItems;
    });
  };
  const updateFilterAtIndex = (index, filter) => {
    console.log("filter", filter);
    setMediaItems((prevItems) => {
      const updatedItems = [...prevItems];
      if (index >= 0 && index < updatedItems.length) {
        updatedItems[index].filter = filter;
      }
      return updatedItems;
    });
  };

  const handleSave = async () => {
    if (name === null) {
      toast.error("Name could not be empty");
      return;
    }

    // Process mediaItems to ensure correct type for widgets and non-widgets
    const processedMediaItems = mediaItems.map(item => {
      const baseItem = {
        type: item.type || 'image',
        fileName: item.fileName || item.name || 'Unnamed Item',
        downloadUrl:item.type==='widget'?  `${item._id}/${item.fileName || item.name || 'unnamed-item'}` :item.downloadUrl,
        duration: item.duration || 5,
               filter: item.filter || null 
      };

      if (item.type === 'widget') {
        return {
          ...baseItem,
          ...item,
          type: 'widget',
          name: item.name || 'Unnamed Widget',
          html: item.html,
          downloadUrl:item.type==='widget'?  `${item._id}/${item.fileName || item.name || 'unnamed-item'}` :item.downloadUrl,
          widgetType: item.widgetType || 'custom',
          filter: item.filter || null 
        };
      } else {
        return baseItem;
      }
    });

    const playlistData = {
      playlistName: name,
      label: label,
      playlist: processedMediaItems,
    };

    try {
      const res = await axios.post(addPlaylist, playlistData);
      getPlaylist();
      toast.info("Success to add playlist.");
      setEditing(false);
      setEditingIndex(null);
      setMediaItems([]);
      console.log("res", res);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add playlist: " + (error.response?.data?.msg || error.message));
    }
  };

  const onClickDelete = (indexToRemove) => {
    setMediaItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(indexToRemove, 1);
      return updatedItems;
    });
  };

 
  const fabContainerStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: "1",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  };

 

  
  return (
    <div
      className="container"
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        paddingRight: isVisible && editing ? "300px" : "40px",
        height: "100%", 
       }}
    >
     <Breadcrumb>
        {editing ? (
          <>
            <Breadcrumb.Item 
              onClick={() => {
                setEditing(false); // Discard changes and exit editing mode
                setMediaItems([]); // Clear media items if needed
                setEditingIndex(null); // Reset editing index
              }}
              style={{ cursor: 'pointer' }} // Change cursor to pointer
            >
              Playlists
         
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
            {playlist[editingIndex]?.playlistName} Editing
            </Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>
            Create New Playlist
          </Breadcrumb.Item>
        )}
      </Breadcrumb>

      {editing ? (
        <><SidePanel
            onMediaDrop={handleMediaDrop}
            onWidgetDrop={handleWidgetDrop}
            isVisible={isVisible}
            toggleVisibility={toggleVisibility}
            mediaData={media}
            widgetData={widget} // Add this prop
            onFileUploadSuccess={() => {
              getUserMedia();
            }}
          />
          <div
            className="container"
            style={{
              padding: "20px",
              flex: 1,
              gap: "10px",
              minHeight: "calc(70vh)",
              //maxHeight: "80vh", // Updated to set maxHeight to 80% of the screen height
              //backgroundColor: "#efefef",
              width: "100%"}}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
             <div style={{display:'flex',flexDirection:'row', backgroundColor:'#CCC',padding: "20px",borderRadius:16}}>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
              <TextInput
                label="Name"
                name="name"
                row
                handleValue={(e) => setName(e)}
                defaultValue={playlist[editingIndex]?.playlistName}
              />
              <TextInput
                label="Label"
                name="label"
                row
                handleValue={(e) => setLabel(e)}
                defaultValue={playlist[editingIndex]?.label}
              />
            </div>
            <div
              style={{
                flex:1,
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end", // Added to align buttons to the right
                gap: "10px",
              }}
            >
              <Button                 style={{ backgroundColor: '#cd204f', borderColor: '#cd204f' }} // Added style for background color
                variant="primary" size="sm" onClick={handleSave}>
                Save{" "}
                <FontAwesomeIcon icon={faSave} style={{ fontSize: "1rem" }} />
              </Button>
              <Button
                variant="primary"
                style={{ backgroundColor: '#cd204f', borderColor: '#cd204f' }} // Added style for background color

                size="sm"
                onClick={() => {
                  setEditingIndex(null);
                  setMediaItems([]);
                  setEditing(false);
                }}
              >
                Discard{" "}
                <FontAwesomeIcon icon={faClose} style={{ fontSize: "1rem" }} />
              </Button>
            </div>
          </div>
            {mediaItems.length ? null : (
              <p
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  color: "black",
                }}
              >
                Drop media items here
              </p>
            )}
            <DragDropContext
              onDragEnd={handleOnDragEnd}
              style={dragDropContextStyle}
            >
              <div style={{  overflow: "auto"}}>
                <Droppable droppableId="media-items" direction="vertical">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}       style={{marginTop:30}}>
                      {mediaItems.map((item, index) => (
                        <Draggable
                          key={item.id || item.fileName || `item-${index}`}
                          draggableId={item.id || item.fileName || `item-${index}`}
                          index={index}
                        >
                         {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                        
                            >
                              {item.type === 'widget' ? (
                                <WidgetCard
                                widget={item}
                                onDelete={() => onClickDelete(index)}
                                index={index}
                                updateDurationAtIndex={updateDurationAtIndex}
                                filters={filters}
                                updateFilterAtIndex={updateFilterAtIndex}
                              />
                              ) : (
                                <CreatePlaylistCard
                                  items={item}
                                  index={index}
                                  onClickDelete={onClickDelete}
                                  updateDurationAtIndex={updateDurationAtIndex}
                                  filters={filters}
                                  updateFilterAtIndex={updateFilterAtIndex}
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </div>
          
        </>
      ) : (
        <PlaylistCard
          onClickEdit={(items, index) => {
            setMediaItems(items.playlist);
            setEditing(!editing);
            setEditingIndex(index);
            setName(items.playlistName);
            setLabel(items.label);
          }}
          onSuccessDelete={() => {
            getPlaylist();
          }}
          handleShowSetDeviceModal={(id) => {
            setAddDevicePlaylistModal(true);
            setPlaylistId(id);
          }}
          items={playlist}
        />
      )}

      {/* Floating Action Button (FAB) */}
      {!editing ? (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={popoverHoverFocus}
        >
          <div style={fabContainerStyle}>
            <FloatingActionButton
              onClick={() => {
                setEditing(true);
              }}
              userId={""}
            />
          </div>
        </OverlayTrigger>
      ) : null}
      <SetPlaylistToDeviceModal
        show={addDevicePlaylistModal}
        playlistId={playlistId}
        onHide={() => {
          //  getDevice(userDispatch, companyId);
          setAddDevicePlaylistModal(false);
          setPlaylistId(null);
        }}
      />
    </div>
  );
};

export default Page1;
