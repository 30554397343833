import { ToastContainer } from "react-toastify";
import { AuthContextProvider } from "./contexts/AuthContext";
import { DeviceState } from "./contexts/Device/DeviceState";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Playlist from "./pages/Playlist";
import Filter from "./pages/Filter";
import MyMedia from "./pages/MyMedia";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Layout from "./components/Layout";
import Main from "./pages/Main";
import DFNPlayer from "./pages/DFNPlayer";
import Login from "./containers/Login/Login";
import Register from "./containers/Register/Register";
import { useAuthContext } from "./contexts/AuthContext";
import Profile from "./containers/Profile/Profile";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import Plans from "./pages/Plans";
import AccountPage from "./pages/AccountPage";
import Widget from "./pages/Widget";
import Analytics from "./pages/Analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKsHdo7p-0Tg16GJNmcVbL_xTsDzxfdGE",
  authDomain: "dfnplayer-ffd27.firebaseapp.com",
  projectId: "dfnplayer-ffd27",
  storageBucket: "dfnplayer-ffd27.appspot.com",
  messagingSenderId: "239038889332",
  appId: "1:239038889332:web:3bee91619a70d51e85c021",
  measurementId: "G-WEBT9Q76YT",
  databaseURL:'https://dfnplayer-ffd27-default-rtdb.europe-west1.firebasedatabase.app/'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app);
function Routes() {
  return (
    <HelmetProvider>
      <AuthContextProvider>
        <DeviceState>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={true}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <BrowserRouter>
            <Route
              render={(props) => (
                <Layout {...props}>
                  <Switch>
                    <HomeRoute path="/" exact component={DFNPlayer} />
                    <HomeRoute path="/qr" exact component={DFNPlayer} />
                    <PrivateRoute path="/main" exact component={Main} />
                    <PrivateRoute
                      path="/dashboard"
                      exact
                      component={Dashboard}
                    />
                    <PrivateRoute path="/playlist" component={Playlist} />
                    <PrivateRoute path="/filter" component={Filter} />
                    <PrivateRoute path="/mymedia" component={MyMedia} />
                    <PrivateRoute path="/profile" component={Profile} />
                    <PrivateRoute path="/plans" component={Plans} />
                    <PrivateRoute path="/account" component={AccountPage} />
                    <PrivateRoute path="/widgets" component={Widget} />
                    <PrivateRoute path='/analytics' component={Analytics}/>
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route component={NotFound} />
                  </Switch>
                </Layout>
              )}
            />
          </BrowserRouter>
        </DeviceState>
      </AuthContextProvider>
    </HelmetProvider>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  const isLoggedIn = !!localStorage.getItem("token");
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}
function HomeRoute({ component: Component, ...rest }) {
  const isLoggedIn = !!localStorage.getItem("token");
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Redirect
            to={{ pathname: "/main", state: { from: props.location } }}
          />
        ) : (
          <Component {...props} db={db}/>
        )
      }
    />
  );
}
export default Routes;
