import React, { useState,useContext } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import axios from "../api/axios";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import {
  changePasswordRoute,
  companyList,
  list,
  updateCompany,
} from "../api/routes";
import { AuthContext } from "../contexts/AuthContext";
const CreateNewCompnayPopup = ({
  onClick,
  userId,
  showModal,
  handleCloseModal,
}) => {
  const [companyNamenew, setCompanyName] = useState("");
  const [companyLocationnew, setCompanyLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const { updateCompanyLogoPath,updateUser } = useContext(AuthContext);


  const submit = async () => {
    setLoading(true); // Set loading to true when the Axios call starts

    try {
      console.log("userID", userId);
      const res = await axios.post(updateCompany, {
        companyName: companyNamenew.val,
        companyLocation: companyLocationnew.val,

        userId: userId,
      });
      console.log("ressss", res);
      console.log(
        "res.data.data.compnay[0]._id",
        res?.data?.data?.company[res?.data?.data?.company.length - 1]?._id
      );
      const id =
        res?.data?.data?.company[res?.data?.data?.company.length - 1]?._id;
      if (file && id) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("description", "description");

        const result = await axios.post("/user/company-profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            companyId: id,
            userId: userId,
          },
        });
        if(result.data.success){
          updateUser( result.data.data );
        }
        console.log(result.data);
      }

      setLoading(false); // Set loading to false when the Axios call is finished
      handleCloseModal();
    } catch (error) {
      console.error("Error:", error);
      setLoading(false); // Set loading to false in case of an error
      toast.error("Failed to update company information.");
    }
  };
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Company or Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Company name</Form.Label>
            <Form.Control
              type="text"
              placeholder={"company name"}
              autoFocus
              onChange={(e) => setCompanyName({ val: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Company location</Form.Label>
            <Form.Control
              type="text"
              placeholder={"company location"}
              autoFocus
              onChange={(e) => setCompanyLocation({ val: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Company logo</Form.Label>
            <input
              filename={file}
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
              accept="image/video"
            />
            {file ? (
              <img
                crossOrigin="anonymous"
                src={URL.createObjectURL(file)}
                width="100"
                height="50"
              />
            ) : null}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={submit}>
          Save Changes
          {loading && <Spinner animation="grow" size="sm" className="ml-2" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateNewCompnayPopup;
