import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';

const GoogleCalendarWidget = () => {
    const [calendarId, setCalendarId] = useState('');
    const [events, setEvents] = useState([]);
    const [error, setError] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [textColor, setTextColor] = useState('#000000');
    const [fontSize, setFontSize] = useState('14px');
    const [scrollSpeed, setScrollSpeed] = useState(50);
    const [iframeContent, setIframeContent] = useState('');
    const iframeRef = useRef(null);

    const fetchEvents = async () => {
        try {
            // This is a placeholder. In a real application, you would need to use Google Calendar API
            // to fetch events. You'll need to set up OAuth2 authentication for this.
            const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`);
            const data = await response.json();
            setEvents(data.items);
            setError('');
        } catch (e) {
            setError('Takvim etkinlikleri yüklenemedi. Lütfen Takvim ID\'sini kontrol edin.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchEvents();
    };

    useEffect(() => {
        const content = `
            <!DOCTYPE html>
            <html>
            <head>
                <style>
                    body {
                        margin: 0;
                        padding: 0;
                        background-color: ${backgroundColor};
                        overflow: hidden;
                    }
                    .event-container {
                        height: calc(100vh - 10px);
                        overflow-y: scroll;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 10px;
                        color: ${textColor};
                        font-size: ${fontSize};
                    }
                    .event {
                        margin-bottom: 15px;
                        width: 100%;
                        border-bottom: 1px solid #e1e8ed;
                        padding-bottom: 10px;
                    }
                    .progress-bar {
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        height: 10px;
                        background-color: #4285F4;
                        width: 0%;
                        transition: width 0.3s;
                    }
                </style>
            </head>
            <body>
                <div class="event-container" id="eventContainer">
                    ${events.map(event => `
                        <div class="event">
                            <h3>${event.summary}</h3>
                            <p><strong>Başlangıç:</strong> ${new Date(event.start.dateTime || event.start.date).toLocaleString()}</p>
                            <p><strong>Bitiş:</strong> ${new Date(event.end.dateTime || event.end.date).toLocaleString()}</p>
                            ${event.description ? `<p>${event.description}</p>` : ''}
                            ${event.location ? `<p><strong>Konum:</strong> ${event.location}</p>` : ''}
                        </div>
                    `).join('')}
                </div>
                <div class="progress-bar" id="progressBar"></div>
                <script>
                    (function() {
                        var scrollSpeed = ${scrollSpeed};
                        var eventContainer = document.getElementById('eventContainer');
                        var progressBar = document.getElementById('progressBar');

                        function updateProgressBar() {
                            var scrollTop = eventContainer.scrollTop;
                            var scrollHeight = eventContainer.scrollHeight;
                            var clientHeight = eventContainer.clientHeight;
                            var scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
                            progressBar.style.width = scrollPercentage + '%';
                        }

                        setInterval(function() {
                            if (eventContainer) {
                                var scrollTop = eventContainer.scrollTop;
                                var scrollHeight = eventContainer.scrollHeight;
                                var clientHeight = eventContainer.clientHeight;
                                if (scrollTop + clientHeight >= scrollHeight) {
                                    eventContainer.scrollTop = 0;
                                } else {
                                    eventContainer.scrollTop += 1;
                                }
                                updateProgressBar();
                            }
                        }, scrollSpeed);

                        eventContainer.addEventListener('scroll', updateProgressBar);
                    })();
                </script>
            </body>
            </html>
        `;
        setIframeContent(content);
    }, [events, backgroundColor, textColor, fontSize, scrollSpeed]);

    return (
        <Container>
            <Row>
                <Col xs={12} md={4} lg={3}>
                    <Card className="mb-4">
                        <Card.Header>Google Calendar Widget Settings</Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="calendarId">
                                    <Form.Label>Google Takvim ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Takvim ID'sini girin"
                                        value={calendarId}
                                        onChange={(e) => setCalendarId(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="backgroundColor" className="mt-3">
                                    <Form.Label>Arka Plan Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={backgroundColor}
                                        onChange={(e) => setBackgroundColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="textColor" className="mt-3">
                                    <Form.Label>Yazı Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={textColor}
                                        onChange={(e) => setTextColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="fontSize" className="mt-3">
                                    <Form.Label>Yazı Boyutu</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={fontSize}
                                        onChange={(e) => setFontSize(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="scrollSpeed" className="mt-3">
                                    <Form.Label>Kaydırma Hızı (ms)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        min="10"
                                        max="200"
                                        value={scrollSpeed}
                                        onChange={(e) => setScrollSpeed(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-3">
                                    Widget Oluştur
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <Card>
                        <Card.Header>Preview</Card.Header>
                        <Card.Body style={{ padding: 0 }}>
                            {error && <Alert variant="danger" className="m-3">{error}</Alert>}
                            <iframe
                                ref={iframeRef}
                                srcDoc={iframeContent}
                                title="Google Calendar Preview"
                                style={{
                                    width: '100%',
                                    height: '600px',
                                    border: 'none'
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default GoogleCalendarWidget;