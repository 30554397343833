import React from 'react';

const NotFound = props => {
    return (
        <div>
            <p>You took a wrong turn, mate.</p>
        </div>
    );
};

export default NotFound;
