import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faSun, faDiceTwo, faPalette } from "@fortawesome/free-solid-svg-icons";
import uploadWidget from './uploadWidget';

const CityWeatherWidget = () => {
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [weather, setWeather] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [textColor, setTextColor] = useState('#ffffff'); // Default text color
    const [backgroundColor, setBackgroundColor] = useState('#1c1e21'); // Default background color
    const [iconColor, setIconColor] = useState('#ffffff'); // Default icon color
    const [headerTextColor, setHeaderTextColor] = useState('#ffffff'); // Default header text color
    const [subtextColor, setSubtextColor] = useState('#b0b3b8'); // Default subtext color
    const [iframeContent, setIframeContent] = useState('');
    const iframeRef = useRef(null);

    const fetchCoordinates = async () => {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/search?city=${city}&country=${country}&format=json`
        );
        const data = await response.json();
        if (data && data.length > 0) {
            return {
                latitude: data[0].lat,
                longitude: data[0].lon,
            };
        }
        throw new Error('Geçerli bir şehir ve ülke bulunamadı.');
    };

    const fetchWeather = async () => {
        if (!city || !country) {
            setError('Lütfen şehir ve ülke seçiniz.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const { latitude, longitude } = await fetchCoordinates();
            const response = await fetch(
                `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current_weather=true`
            );
            const data = await response.json();
            if (!data.current_weather) {
                setError('Hava durumu bilgisi alınamadı.');
                setWeather(null);
            } else {
                setWeather(data.current_weather);
            }
        } catch (err) {
            setError('Hava durumu bilgisi alınırken bir hata oluştu.');
            setWeather(null);
        }

        setLoading(false);
    };

    useEffect(() => {
        const content = `
            <!DOCTYPE html>
            <html>
            <head>
                <style>
                    body {
                        margin: 0;
                        padding: 0;
                        background-color: ${backgroundColor};
                        color: ${textColor};
                        overflow: hidden;
                    }
                    .widget-container {
                        height: 100vh;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 15px;
                        color: ${textColor};
                        font-family: Arial, sans-serif;
                    }
                    .widget-header {
                        color: ${headerTextColor};
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }
                    .widget-content {
                        color: ${textColor};
                        font-size: 18px;
                    }
                    .widget-content .icon {
                        color: ${iconColor};
                        margin-right: 8px;
                    }
                    .widget-content .subtext {
                        color: ${subtextColor};
                        font-size: 14px;
                    }
                </style>
            </head>
            <body>
                <div class="widget-container">
                    <div class="widget-header">Hava Durumu Bilgisi</div>
                    <div class="widget-content">
                        <p><span class="icon">${weather ? '<i class="fa fa-cloud"></i>' : ''}</span><strong>Şehir:</strong> ${city}</p>
                        <p><span class="icon">${weather ? '<i class="fa fa-sun"></i>' : ''}</span><strong>Durum:</strong> ${weather ? weather.weathercode : ''}</p>
                        <p><span class="icon">${weather ? '<i class="fa fa-sun"></i>' : ''}</span><strong>Sıcaklık:</strong> ${weather ? weather.temperature : ''} °C</p>
                        <p><span class="icon">${weather ? '<i class="fa fa-dice-two"></i>' : ''}</span><strong>Rüzgar Hızı:</strong> ${weather ? weather.windspeed : ''} km/h</p>
                    </div>
                </div>
            </body>
            </html>
        `;
        setIframeContent(content);
    }, [weather, backgroundColor, textColor, iconColor, headerTextColor, subtextColor]);

    const handleSaveHtml = () => {
        const blob = new Blob([iframeContent], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'weather-widget.html';
        link.click();
        uploadWidget('weatherWidget', iframeContent);
    };

    return (
        <Container>
            <Row>
                <Col xs={12} md={4} lg={3}>
                    <Card className="mb-4">
                        <Card.Header>Hava Durumu Widget Ayarları</Card.Header>
                        <Card.Body>
                            <Form onSubmit={(e) => { e.preventDefault(); fetchWeather(); }}>
                                <Form.Group controlId="city">
                                    <Form.Label>Şehir</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Şehir adı girin"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="country" className="mt-3">
                                    <Form.Label>Ülke</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ülke adı girin (ör. Turkey)"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="backgroundColor" className="mt-3">
                                    <Form.Label>Arka Plan Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={backgroundColor}
                                        onChange={(e) => setBackgroundColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="textColor" className="mt-3">
                                    <Form.Label>Yazı Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={textColor}
                                        onChange={(e) => setTextColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="iconColor" className="mt-3">
                                    <Form.Label>İkon Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={iconColor}
                                        onChange={(e) => setIconColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="headerTextColor" className="mt-3">
                                    <Form.Label>Başlık Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={headerTextColor}
                                        onChange={(e) => setHeaderTextColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="subtextColor" className="mt-3">
                                    <Form.Label>Alt Açıklama Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={subtextColor}
                                        onChange={(e) => setSubtextColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-3">
                                    Widget Oluştur
                                </Button>
                                <Button variant="secondary" className="mt-3 ms-2" onClick={handleSaveHtml}>
                                    HTML Kaydet
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <Card>
                        <Card.Header>Önizleme</Card.Header>
                        <Card.Body style={{ padding: 0 }}>
                            {error && <Alert variant="danger" className="m-3">{error}</Alert>}
                            <iframe
                                ref={iframeRef}
                                srcDoc={iframeContent}
                                title="Weather Widget Preview"
                                style={{
                                    width: '100%',
                                    height: '600px',
                                    border: 'none'
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CityWeatherWidget;
