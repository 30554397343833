import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faTv } from "@fortawesome/free-solid-svg-icons";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import axios from "../api/axios";
import { deletePlaylist, addPlaylist } from "../api/routes";
import { toast } from "react-toastify";

const CardView = ({
  onClickEdit,
  items,
  onSuccessDelete,
  handleShowSetDeviceModal,
}) => {
  // Sample data for the card view
  console.log("items", items);
  // State to handle modal show/hide
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [id, setID] = useState();

  // Function to show the modal
  const handleShowModal = async (id) => {
    console.log("id", id);
    setShowModal(true);
    setID(id);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmDelete = async () => {
    // Call the delete playlist function here
    // e.g., deletePlaylist(playlistId);

    // Close the modal after the deletion is done

    try {
      setDeleting(true);
      const res = await axios.post(deletePlaylist, { playlistId: id });
      onSuccessDelete?.();
      console.log("res", res);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to delete playlist.");
    } finally {
      setDeleting(false);
      handleCloseModal();
    }
  };

  const cardContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "20px",
  };

  const cardStyle = {
    width: "calc(100% - 10px)", // Full width for single item
    marginBottom: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    border: "1px solid #e5e5e5",
    borderRadius: "8px",
    transition: "box-shadow 0.2s",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px",
  };

  const mediaContainerStyle = {
    flex: "1",
    display: "flex",
    alignItems: "center",
  };

  const mediaStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "4px",
    margin: "0 5px", // Add margin to separate images and videos
  };

  const infoContainerStyle = {
    flex: "1",
    display: "flex",
    flexDirection: "column", // Display text content in a column
    color: "black", // Set text color to black
  };

  const selectedInfoStyle = {
    fontSize: "10px", // Reduce font size for selected info
    color: "black", // Set text color to black
    display: "flex", // Arrange Selected Days and Selected Hours horizontally
  };


  const cardHoverStyle = {
    boxShadow: "0 4px 16px rgba(0, 0, 0, 0.15)",
    transform: "scale(1.02)",
  };

  const [hoveredItem, setHoveredItem] = useState(null);

  return (
    <div style={cardContainerStyle}>
      {items?.map((item, index) => (
        <Card
          key={index}
          style={{
            ...cardStyle,
            ...(hoveredItem === index ? cardHoverStyle : {}),
          }}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div style={infoContainerStyle}>
            <Card.Title>{item.playlistName}</Card.Title>
            <Card.Text>
              <span style={selectedInfoStyle}>
                <strong >{item.label}</strong>
              </span>
            </Card.Text>
            {/* 
            <Card.Text style={selectedInfoStyle}>
              <span style={selectedInfoItemStyle}>
                <strong>Days:</strong> {item.selectedDays.join(", ")}
              </span>
              <span>
                <strong>Hours:</strong> {item.selectedHours.join(", ")}
              </span>
            </Card.Text>
            <Card.Text>
              <strong>Is Playing:</strong> {item.isPlaying ? "Yes" : "No"}
            </Card.Text>
            */}
          </div>
          <div style={mediaContainerStyle}>
            {item.playlist.map((content, idx) => {
              if (content.type.startsWith("image")) {
                return (
                  <img
                    key={idx}
                    src={content.downloadUrl}
                    alt={item.name}
                    style={mediaStyle}
                    crossOrigin="anonymous"
                  />
                );
              } else if (content.type.startsWith("video")) {
                return (
                  <video
                    key={idx}
                    src={content.downloadUrl}
                    controls
                    style={mediaStyle}
                  />
                );
              } else if (content.type.startsWith("widget")) {
              
                return (
                  <div key={idx} style={{
                    ...mediaStyle,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'lightblue',
                    overflow: 'hidden',  // Prevent content from overflowing
                    padding: '1px',  // Add some padding
                  }}>
                  <span style={{
                    fontSize: '8px',  // Reduce font size further
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',  // Allow the span to take full width of the parent
                    maxWidth: '100%',  // Ensure it doesn't exceed the parent's width
                  }}>
                    {content.fileName}
                  </span>
                  
                </div>
                );
              }
              return null;
            })}
          </div>
          <div>
            <Button
              variant="outline-primary"
              style={{ marginRight: '10px' }} // Add margin to the right
              onClick={() => onClickEdit(item, index)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
              variant="outline-danger"
              style={{ marginRight: '10px' }} // Add margin to the right
              onClick={() => handleShowModal(item._id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Button variant="outline-dark" onClick={() => handleShowSetDeviceModal(item._id)}>
              <FontAwesomeIcon
                icon={faTv}
              />
            </Button>
          </div>
        </Card>
      ))}
      <DeleteConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirmDelete={handleConfirmDelete}
        loading={deleting}
        title="Delete Playlist"
        body="Are you sure you want to delete this playlist?"
      />
    </div>
  );
};

export default CardView;
