import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import SidebarItems from "./SidebarItems";
import CompanyPicker from "./CompanyPicker";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faChevronLeft, faChevronRight, faTv } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../contexts/AuthContext";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const SIDEBAR_WIDTH = 250;
const SIDEBAR_COLLAPSED_WIDTH = 80;
const MOBILE_BREAKPOINT = 768;

const SidebarParent = styled.div`
  background-color: #1a1a1a;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  width: ${props => props.isCollapsed ? `${SIDEBAR_COLLAPSED_WIDTH}px` : `${SIDEBAR_WIDTH}px`};
  overflow: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: ${SIDEBAR_COLLAPSED_WIDTH}px;
  }
`;

const SidebarContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CompanyPickerWrapper = styled.div`
  padding: 16px;
  position: relative;
  z-index: 222;
margin-top: 30px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 10px;
  }
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

const SidebarItem = styled.div`
  padding: ${props => props.isCollapsed ? '16px 0' : '16px 24px'};
  transition: all 0.25s ease-in-out;
  background: ${(props) => (props.active ? "#000000" : "")};
  margin: ${props => props.isCollapsed ? '4px 0' : '4px 12px'};
  border-radius: ${props => props.isCollapsed ? '0' : '4px'};
  display: flex;
  align-items: center;
  justify-content: ${props => props.isCollapsed ? 'center' : 'flex-start'};
  min-height: 50px;
  overflow: hidden;
  width: ${props => props.isCollapsed ? `${SIDEBAR_COLLAPSED_WIDTH}px` : 'auto'};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 10px 0;
    margin: 4px 0;
    justify-content: center;
    width: ${SIDEBAR_COLLAPSED_WIDTH}px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.isCollapsed ? '100%' : '20px'};
  margin-right: ${props => props.isCollapsed ? '0' : '10px'};
  color: #cd204f;
  position: relative;
  z-index: 2;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-right: 0;
  }
`;

const ItemText = styled.p`
  color: white;
  font-weight: bold;
  margin: 0;
  opacity: ${props => props.isCollapsed ? 0 : 1};
  width: ${props => props.isCollapsed ? 0 : 'auto'};
  overflow: hidden;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
  position: relative;
  z-index: 2;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    opacity: 0;
    width: 0;
  }
`;

const Badge = styled.div`
  background: #007BFF;
  color: #FFF;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 0.5em;
  font-weight: bold;
  margin-left: auto;
  display: ${props => props.isCollapsed ? 'none' : 'block'};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const LogoutLink = styled.div`
  margin-top: auto;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #cd204f;
  font-size: 1.2em;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 1px; // Position it outside the sidebar
  top: 10px; // Align with CompanyPickerWrapper
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 4px 4px 0;
  z-index: 2;
  background-color: #1a1a1a; // Match sidebar background
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

function Sidebar(props, { defaultActive}) {
  const location = props.history.location;
  const lastActiveIndexString = localStorage.getItem("lastActiveIndex");
  const lastActiveIndex = Number(lastActiveIndexString);
  const [activeIndex, setActiveIndex] = useState(
    lastActiveIndex || defaultActive
  );


  

  const { isLoggedIn, setIsLoggedIn, user } = useAuthContext();
  function changeActiveIndex(newIndex) {
    localStorage.setItem("lastActiveIndex", newIndex);
    setActiveIndex(newIndex);
  }

  function getPath(path) {
    if (path.charAt(0) !== "/") {
      return "/" + path;
    }
    return path;
  }

  useEffect(() => {
    const activeItem = SidebarItems.findIndex(
      (item) => getPath(item.route) === getPath(location.pathname)
    );
    changeActiveIndex(activeItem);
  }, [location]);
  console.log("nemiş from auth", user);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = () => {
    setDeleting(true);
    localStorage.removeItem("token");
              setIsLoggedIn(false);
              props.history.push("/");
              setDeleting(false);
              setShowDeleteModal(false);
            
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const toggleSidebar = () => {
    props.setIsCollapsed(!props.isCollapsed);
  };

  return (
    <SidebarParent isCollapsed={props.isCollapsed}>
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirmDelete={() => handleDelete()}
        loading={deleting}
        title="Logout"
        body="Are you sure you want to logout?"
        buttonText="Logout"
      />
      <SidebarContainer>
        <div style={{ position: 'relative' }}> {/* Make sure this wrapper has position relative */}
          <CompanyPickerWrapper>
            <CompanyPicker isCollapsed={props.isCollapsed} />
          </CompanyPickerWrapper>
          <ToggleButton onClick={toggleSidebar}>
            <FontAwesomeIcon icon={props.isCollapsed ? faChevronRight : faChevronLeft} />
          </ToggleButton>
          <ScrollableContent>
            {SidebarItems.map((item, index) => (
              <StyledLink to={item.route} key={item.name}>
                <SidebarItem active={index === activeIndex} isCollapsed={props.isCollapsed}>
                  {item.icon && (
                    <IconWrapper isCollapsed={props.isCollapsed}>
                      <FontAwesomeIcon icon={item.icon} />
                    </IconWrapper>
                  )}
                  <ItemText isCollapsed={props.isCollapsed}>{item.name}</ItemText>
                  {item.badge && <Badge isCollapsed={props.isCollapsed}>{item.badge}</Badge>}
                </SidebarItem>
              </StyledLink>
            ))}
          </ScrollableContent>
          <LogoutLink onClick={() => setShowDeleteModal(true)}>
            <SidebarItem isCollapsed={props.isCollapsed}>
              <IconWrapper isCollapsed={props.isCollapsed}>
                <FontAwesomeIcon icon={faPowerOff} />
              </IconWrapper>
              <ItemText isCollapsed={props.isCollapsed}>Logout</ItemText>
            </SidebarItem>
          </LogoutLink>
          
        </div>
      </SidebarContainer>
    </SidebarParent>
  );
}

export default Sidebar;
