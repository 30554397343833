import { useContext, useState } from "react";
import { toast } from "react-toastify";
import validator from "validator";
import { AuthContext } from "../contexts/AuthContext";
import { Button, Container } from "react-bootstrap";
import axios from "../api/axios";
import {
  changePasswordRoute,
  companyList,
  list,
  updateCompany,
  createInvoiceRoute,
  listInvoicesRoute,
} from "../api/routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faLock, faClipboardList, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import FormField from "../components/FormField/FormField";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CompanyCard from "../components/CompanyCard";
import FloatingActionButton from "../components/FloatingActionButton";
import { OverlayTrigger, Popover } from "react-bootstrap";
import PricingCard from "../components/PricingCard";
import "./AccountPage.css";

const AccountPage = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const { user } = useContext(AuthContext);

  const handleValidation = () => {
    let formIsValid = true;

    if (
      !validator.isStrongPassword(currentPassword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      formIsValid = false;
      setpasswordError(
        "Must contain 8 characters, one uppercase letter, one lowercase letter, one number, and one special symbol"
      );
    } else {
      setpasswordError("");
    }
    setFormValid(formIsValid);
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    handleValidation();

    if (formValid) {
      axios
        .post(changePasswordRoute, {
          email: user.email,
          currentPassword: currentPassword,
          newPassword: newPassword,
          newConfirmPassword: newConfirmPassword,
        })
        .then(function (response) {
          toast.success("Password Changed Successfully!");
        })
        .catch(function (error) {
          toast.error(error.response.data.msg);
        });
    }
  };

  const [showPopover, setShowPopover] = useState(false);
  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus" title="Popover bottom">
      <strong>Create new company or branch</strong> Click to create
    </Popover>
  );

  const [key, setKey] = useState("company");

  const handleCreateInvoice = async (e) => {
    e.preventDefault();
    console.log('e.target', e.target.elements);
    const { subscriptionId, name, address, city, state, zip_code, country, notes } = e.target.elements;

    try {
      const response = await axios.post(createInvoiceRoute(subscriptionId.value), {
        name: name.value,
        address: address.value,
        city: city.value,
        state: state.value,
        zip_code: zip_code.value,
        country: country.value,
        notes: notes.value,
      });

      toast.success("Invoice Created Successfully!");
    } catch (error) {
      toast.error("Failed to create invoice" + error);
    }
  };

  const [invoices, setInvoices] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState(user.currentSubscriptionId);

  const fetchInvoices = async (subscriptionId) => {
    try {
      const response = await axios.get(listInvoicesRoute(subscriptionId));
      setInvoices(response.data);
    } catch (error) {
      toast.error("Failed to fetch invoices");
    }
  };

  return (
    <Container fluid>
      <Tabs
        defaultActiveKey="company"
        id="uncontrolled-tab-example"
        className="mb-2 custom-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab
          eventKey="company"
          title={
            <span className={key === "company" ? "active-tab" : "inactive-tab"}>
              <FontAwesomeIcon icon={faBuilding} /> Company
            </span>
          }
        >
          <div className="tab-content-wrapper">

            <div className="row" style={{ marginTop: 30 }}>
              {user?.company?.map((item) => (
                <div key={item._id} className="col-12 col-sm-6 col-md-4 mb-4 d-flex align-items-stretch">
                  <CompanyCard
                    companyName={item.companyName}
                    companyLogoPath={item.companyLogoPath}
                    companyLocation={item.companyLocation}
                    companyId={item._id}
                    userId={user.id}
                  />
                </div>
              ))}
            </div>

          </div>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={popoverHoverFocus}
          >
            <div
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                zIndex: "1",
              }}
            >
              <FloatingActionButton
                onClick={() => {
                  // Handle the action when the button is clicked
                }}
                userId={user.id}
                accountType={user.accountType}
                onMouseEnter={() => setShowPopover(true)}
                onMouseLeave={() => setShowPopover(false)}
                type={"createcompany"}
              />
            </div>
          </OverlayTrigger>
        </Tab>
        <Tab
          eventKey="password"
          title={
            <span className={key === "password" ? "active-tab" : "inactive-tab"}>
              <FontAwesomeIcon icon={faLock} /> Password
            </span>
          }
        >
          <div className="tab-content-wrapper">

            <div className="row">

              <form onSubmit={loginSubmit} className="login-form">
                <h3 className="text-center login-header">Change Password</h3>
                <div className="form-group mt-4">
                  <h5>Current Password</h5>
                  <FormField
                    type="password"
                    placeholder="Enter Password"
                    setFunc={setCurrentPassword}
                  />
                  <small id="passworderror" className="text-danger form-text">
                    {passwordError}
                  </small>
                </div>
                <div className="form-group mt-4">
                  <h5>New Password</h5>
                  <FormField
                    type="password"
                    placeholder="Enter Password"
                    setFunc={setNewPassword}
                  />
                </div>
                <div className="form-group mt-4">
                  <h5>New Confirm Password</h5>
                  <FormField
                    type="password"
                    placeholder="Enter Password"
                    setFunc={setNewConfirmPassword}
                  />
                </div>
                <div className="text-center submit-btn">
                  <Button type="submit" className="btn btn-dark mt-4">
                    Submit
                  </Button>
                </div>
              </form>

            </div>

          </div>
        </Tab>
        <Tab
          eventKey="plans"
          title={
            <span className={key === "plans" ? "active-tab" : "inactive-tab"}>
              <FontAwesomeIcon icon={faClipboardList} /> Plans
            </span>
          }
        >
          <div className="tab-content-wrapper">

            <div className="row justify-content-center">
              <div className="col">
                <PricingCard
                  title="One Screen Free"
                  price={0}
                  billingCode="basic-plan"
                  priceOverview="Ücretsiz Paket"
                  description="Tüm kullanıcılarımıza default olarak sunduğumuz ücretsiz paketimizdir."
                  priceDesc="Bir ekranda sınırsız kullanım."
                  onClick="http://www.example.com/"
                  ctaText="Use one screen forever free"
                  free
                  cardType="Free"
                />
              </div>
              <div className="col">
                <PricingCard
                  title="Beginner"
                  price={9.99}
                  billingCode="basic-plan"
                  priceOverview="Basic Version"
                  description="This is the basic package for up to 3 screens."
                  priceDesc="Usage up to 3 screens."
                  onClick={() => {
                    const itemsList = [
                      {
                        priceId: "pri_01h7xhs055z0a4bx0c6fn640b0",
                        quantity: 1,
                      }
                    ];
                    window.Paddle.Checkout.open({
                      items: itemsList,
                      customer: {
                        email: "emre@123.com",
                      },
                      passthrough: JSON.stringify({
                        userId: 9,
                        email: "email",
                      }),
                      customData: {
                        "user_id": user.id,
                        "utm_source": "web_site"
                      },
                    });
                  }}
                  ctaText="Buy Now"
                  cardType="Beginner"
                />
              </div>
              <div className="col">
                <PricingCard
                  title="Middle"
                  price={19.99}
                  billingCode="pro-plan"
                  priceOverview="Standard Version"
                  description="This is the standard package for up to 5 screens."
                  priceDesc="Usage up to 5 screens."
                  onClick={() => {
                    const itemsList = [

                      {
                        priceId: "pri_01hsvct7tqm1cvxvrs7kba0cf2",
                        quantity: 1,
                      }
                    ];
                    window.Paddle.Checkout.open({
                      items: itemsList,
                      customer: {
                        email: user.email,
                      },
                      passthrough: JSON.stringify({
                        userId: 9,
                        email: "email",
                      }),
                      customData: {
                        "user_id": user.id,
                        "utm_source": "web_site"
                      },
                    });

                  }}
                  ctaText="Buy Now"

                />
              </div>
              <div className="col">
                <PricingCard
                  title="Unlimited"
                  price={49.99}
                  featured
                  billingCode="enterprise-plan"
                  priceOverview="Standard Version"
                  description="Best selling option. This is well suited for coffee shops, restaurants, and other businesses with multiple screens."
                  priceDesc="Unlimited screens with all features."
                  onClick={() => {
                    const itemsList = [
                      {
                        priceId: "pri_01hsvgppahr3xkfygk9vazbmrd",
                        quantity: 1,
                      },
                    ];
                    window.Paddle.Checkout.open({
                      items: itemsList,
                      customer: {
                        email: "emre@123.com",
                      },
                      passthrough: JSON.stringify({
                        userId: 9,
                        email: "email",
                      }),
                      customData: {
                        "user_id": user.id,
                        "utm_source": "web_site"
                      },
                    });

                  }}
                  ctaText="Buy Now"
                  cardType="Unlimited"
                />
              </div>

            </div>
          </div>
        </Tab>
        <Tab
          eventKey="invoices"
          title={
            <span className={key === "invoices" ? "active-tab" : "inactive-tab"}>
              <FontAwesomeIcon icon={faFileInvoice} /> Invoices
            </span>
          }
        >
          <div className="tab-content-wrapper">

            <div className="row justify-content-center">
              <div className="col-md-6">
                <form onSubmit={handleCreateInvoice} className="invoice-form">
                  <h3 className="text-center invoice-header">Create Invoice</h3>
                  <div className="form-group mt-4">
                    <h5>Subscription ID</h5>
                    <FormField
                      type="text"
                      placeholder="Enter Subscription ID"
                      name="subscriptionId"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <h5>Name</h5>
                    <FormField
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <h5>Address</h5>
                    <FormField
                      type="text"
                      placeholder="Enter Address"
                      name="address"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <h5>City</h5>
                    <FormField
                      type="text"
                      placeholder="Enter City"
                      name="city"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <h5>State</h5>
                    <FormField
                      type="text"
                      placeholder="Enter State"
                      name="state"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <h5>Zip Code</h5>
                    <FormField
                      type="text"
                      placeholder="Enter Zip Code"
                      name="zip_code"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <h5>Country</h5>
                    <FormField
                      type="text"
                      placeholder="Enter Country"
                      name="country"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <h5>Notes</h5>
                    <FormField
                      type="text"
                      placeholder="Enter Notes"
                      name="notes"
                    />
                  </div>
                  <div className="text-center submit-btn">
                    <Button type="submit" className="btn btn-dark mt-4">
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-md-6">
                <h3 className="text-center">List Invoices</h3>
                <div className="form-group mt-4">
                  <h5>Subscription ID</h5>
                  <FormField
                    type="text"
                    placeholder="Enter Subscription ID"
                    name="subscriptionId"
                    value={subscriptionId}
                    setFunc={(value) => fetchInvoices(value)}
                  />
                </div>
                <div className="mt-4">

                </div>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default AccountPage;
