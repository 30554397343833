import React, { useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { mediaUpload } from "../api/routes";
function FileUploadModal({
  onClick,
  userId,
  showModal,
  handleCloseModal,
  onSuccess,
}) {
  const [files, setFiles] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileUpload = async (selectedFiles) => {
    try {
      const formData = new FormData();

      // Append each selected file to the formData
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });

      const response = await axios.post(mediaUpload, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setUploadProgress(progress);
        },
      });

      // File(s) uploaded successfully
      console.log(response.data);
      onSuccess?.();
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform any additional handling with the uploaded files here.
    console.log(files);
    handleFileUpload(files);
    setFiles([]);
    handleCloseModal();
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>File Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="fileUpload">
              <Form.Label>Select Image and Video Files</Form.Label>
              <Form.Control
                type="file"
                multiple
                accept="image/*, video/*"
                onChange={handleFileChange}
              />
            </Form.Group>
          </Form>

          <Row>
            {files.map((file, index) => (
              <Col xs={6} md={4} key={file.name}>
                <div
                  className="preview-container"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  style={{ position: "relative", height: "150px" }} // Set fixed height here
                >
                  {file.type.startsWith("image/") ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }} // Set fixed height and object-fit
                    />
                  ) : (
                    <video
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      controls
                      muted
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }} // Set fixed height and object-fit
                    />
                  )}

                  {index === hoveredItem && (
                    <div
                      className="delete-icon-container"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgba(255, 0, 0, 0.7)",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRemoveFile(index)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="delete-icon"
                        style={{
                          color: "white",
                          fontSize: "24px",
                          padding: "10px",
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Upload Files
          </Button>
          {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>}
        </Modal.Footer>
      </Modal>
  );
}

export default FileUploadModal;
