import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';

export const Features = (props) => {
  const { t } = useTranslation();

  return (
    <section id='features' className='text-center'>
      <Helmet>
        <title>{t('description.features')} | Digital Signage Solutions</title>
        <meta name="description" content={t('description.featuresDescription')} />
        <link rel="canonical" href="https://yourdomain.com/features" />
        <meta property="og:title" content={t('description.features')} />
        <meta property="og:description" content={t('description.featuresDescription')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yourdomain.com/features" />
      </Helmet>
      <div className='container'>
        <div className='section-title'>
          <h2>{t('description.features')}</h2>
          <p>{t('description.featuresSubtitle')}</p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                {d.icon && <i className={d.icon} aria-hidden="true"></i>}
                <h3>{t(d.title)}</h3>
                <p>{t(d.text)}</p>
              </div>
            ))
            : <p>Loading features...</p>}
        </div>
      </div>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ItemList",
          "itemListElement": props.data ? props.data.map((d, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "name": t(d.title),
            "description": t(d.text)
          })) : []
        })}
      </script>
    </section>
  )
}