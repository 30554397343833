import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card, Dropdown } from 'react-bootstrap';
import RSSWidget from '../widgets/RssWidget';
import CityWeatherWidget from '../widgets/CityWeatherWidget';
import PharmacyWidget from '../widgets/PharmacyWidget';
import StockMarketWidget from '../widgets/StockMarketWidget';
import GoldPriceWidget from '../widgets/GoldPriceWidget';
import ImageQRWidget from '../widgets/ImageQRWidget';
import WordClock from '../widgets/WordClock';
import GoogleSheetWidget from '../widgets/GoogleSheetWidget';
import TwitterWallWidget from '../widgets/TwitterWallWidget';
import GoogleCalendarWidget from '../widgets/GoogleCalendarWidget';

const WidgetCardContainer = styled.div`
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
`;

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 15px;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const WidgetHeader = styled(Card.Header)`
  background-color: #f8f9fa;
  padding: 20px;
  display: flex;
  width: fit-content;
  align-items: center;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 15px;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const WidgetTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
`;

const WidgetPage = () => {
  const [widgets, setWidgets] = useState([
    { id: 1, type: 'RSS', title: 'RSS Widget 1', rssUrl: 'http://example.com/feed1', settings: { refreshRate: 5 } },
   /* { id: 2, type: 'CityWeather', title: 'Şehir Hava Durumu Widget', settings: { city: 'Istanbul' } },
    { id: 3, type: 'Pharmacies', title: 'Duty Pharmacies', settings: { location: 'Downtown' } },
    { id: 4, type: 'StockMarket', title: 'Stock Market Widget', settings: { market: 'NASDAQ' } },
    { id: 5, type: 'GoldPrice', title: 'GoldPriceWidget Widget', settings: { currency: 'USD' } },
    { id: 6, type: 'ImageQR', title: 'Image and QR Code Widget', settings: { imageUrl: 'http://example.com/image.png' } },
    { id: 7, type: 'WordClock', title: 'Word Clock Widget', settings: { timezone: 'GMT' } },
    { id: 8, type: 'GoogleSheet', title: 'Google Sheet Widget', settings: { sheetId: '12345' } },
    { id: 9, type: 'TwitterWall', title: 'Twitter Wall Widget', settings: { hashtag: '#news' } },
    { id: 10, type: 'GoogleCalendar', title: 'Google Calendar Widget', settings: { calendarId: 'abc123' } },
     */
  ]);
  
  const [selectedWidgetType, setSelectedWidgetType] = useState('RSS');

  const handleSettingsChange = (id, newSettings) => {
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) =>
        widget.id === id ? { ...widget, settings: newSettings } : widget
      )
    );
  };

  const handleSelectWidgetType = (type) => {
    setSelectedWidgetType(type);
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col xs={12} className="mb-4">
            <WidgetHeader>
              <WidgetTitle>Widget Types</WidgetTitle>
              <Dropdown onSelect={handleSelectWidgetType} className="ms-3">
                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="w-200">
                  {selectedWidgetType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="RSS">RSS Widget</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </WidgetHeader>
        </Col>
        <Col xs={12}>
          <WidgetCardContainer>
            {widgets.map((widget) =>
              widget.type === selectedWidgetType ? (
                <StyledCard key={widget.id}>
                  <Card.Body style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {widget.type === 'RSS' ? (
                      <RSSWidget
                        title={widget.title}
                        rssUrl={widget.rssUrl}
                        settings={widget.settings}
                        onSettingsChange={(newSettings) => handleSettingsChange(widget.id, newSettings)}
                      />
                    ) : widget.type === 'CityWeather' ? (
                      <CityWeatherWidget settings={widget.settings} />
                    ) : widget.type === 'Pharmacies' ? (
                      <PharmacyWidget settings={widget.settings} />
                    ) : widget.type === 'StockMarket' ? (
                      <StockMarketWidget settings={widget.settings} />
                    ) : widget.type === 'GoldPrice' ? (
                      <GoldPriceWidget settings={widget.settings} />
                    ) : widget.type === 'ImageQR' ? (
                      <ImageQRWidget settings={widget.settings} />
                    ) : widget.type === 'WordClock' ? (
                      <WordClock settings={widget.settings} />
                    ) : widget.type === 'GoogleSheet' ? (
                      <GoogleSheetWidget settings={widget.settings} />
                    ) : widget.type === 'TwitterWall' ? (
                      <TwitterWallWidget settings={widget.settings} />
                    ) : widget.type === 'GoogleCalendar' ? (
                      <GoogleCalendarWidget settings={widget.settings} />
                    ) : null}
                  </Card.Body>
                </StyledCard>
              ) : null
            )}
          </WidgetCardContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default WidgetPage;
