// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        device: action.payload,
      };
    case "SET_MEDIA":
      return {
        ...state,
        media: action.payload,
      };
    case "SET_WIDGET":
      console.log("widget settor", action.payload);
      return {
        ...state,
        widget: action.payload
      };  
    case "SET_PLAYLIST":
      return {
        ...state,
        playlist: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };
    case "SET_COMPANY":
      return {
        ...state,
        companyId: action.payload.id,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
