import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
export const About = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img
              src="img/about.jpg"
              className="img-responsive"
              alt="About Us"
              style={{ maxWidth: "100%", height: "auto" }}
            />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <Helmet
                title="How digital signage works"
                meta={[
                  {
                    name: "description",
                    content:
                      "Digital signage is a sub-segment of electronic signage. Digital displays use technologies such as LCD, LED, projection and e-paper to display digital images, video, web pages, weather data, restaurant menus, or text.",
                  },
                  {
                    property: "og:description",
                    content:
                      "Digital signage is a sub-segment of electronic signage. Digital displays use technologies such as LCD, LED, projection and e-paper to display digital images, video, web pages, weather data, restaurant menus, or text.",
                  },
                ]}
              >
              </Helmet>
              <h2 style={{ marginTop: "20px" }}>{t('description.howitworks')}</h2>

              <p>{props.data ? props.data.paragraph : "loading..."}</p>

              <Helmet
                title="Why choose us"
                meta={[
                  {
                    name: "description",
                    content:
                      "Digital signage is a sub-segment of electronic signage. Digital displays use technologies such as LCD, LED, projection and e-paper to display digital images, video, web pages, weather data, restaurant menus, or text.",
                  },
                  {
                    property: "og:description",
                    content:
                      "Digital signage is a sub-segment of electronic signage. Digital displays use technologies such as LCD, LED, projection and e-paper to display digital images, video, web pages, weather data, restaurant menus, or text.",
                  },
                ]}
              >
              </Helmet>
              <h3>{t('description.whychoseus')}</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li
                            key={`${d}-${i}`}
                            style={{ marginBottom: "10px" }}
                          >
                            {d}
                          </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li
                            key={`${d}-${i}`}
                            style={{ marginBottom: "10px" }}
                          >
                            {d}
                          </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
