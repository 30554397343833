import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import Parser from '../../node_modules/rss-parser/dist/rss-parser.min';
import uploadWidget from './uploadWidget';
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';

const RSSWidget = () => {
    const [rssUrl, setRssUrl] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [textColor, setTextColor] = useState('#000000');
    const [scrollSpeed, setScrollSpeed] = useState(50);
    const [fontSize, setFontSize] = useState('16px');
    const [progressColor, setProgressColor] = useState('#4CAF50');
    const [feedItems, setFeedItems] = useState([]);
    const [error, setError] = useState('');
    const [iframeContent, setIframeContent] = useState('');
    const iframeRef = useRef(null);
    const [widgetName, setWidgetName] = useState('');
    const [nameError, setNameError] = useState('');
    const history = useHistory();
    const [isUploaded, setIsUploaded] = useState(false);

    const parser = new Parser();

    const fetchFeed = async () => {
        try {
            const feed = await parser.parseURL(rssUrl);
            setFeedItems(feed.items);
            setError('');
        } catch (e) {
            setError("RSS feed yüklenemedi. Lütfen URL'yi kontrol edin." + e);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchFeed();
    };

    useEffect(() => {
        const content = `
            <!DOCTYPE html>
            <html>
            <head>
                <style>
                    body {
                        margin: 0;
                        padding: 0;
                        background-color: ${backgroundColor};
                        overflow: hidden;
                    }
                    .feed-container {
                        height: calc(100vh - 10px);
                        overflow-y: scroll;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 10px;
                        color: ${textColor};
                        font-size: ${fontSize};
                    }
                    .feed-item {
                        margin-bottom: 15px;
                        width: 100%;
                    }
                    .feed-item img {
                        max-width: 100%;
                        height: auto;
                        margin-bottom: 10px;
                    }
                    .progress-bar {
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        height: 10px;
                        background-color: ${progressColor};
                        width: 0%;
                        transition: width 0.3s;
                    }
                </style>
            </head>
            <body>
                <div class="feed-container" id="feedContainer">
                    ${feedItems.map(item => `
                        <div class="feed-item">
                            <h5>${item.title}</h5>
                            ${item.enclosure && item.enclosure.url ? `<img src="${item.enclosure.url}" alt="${item.title}" />` : ''}
                            <p>${item.contentSnippet}</p>
                            <a href="${item.link}" target="_blank" rel="noopener noreferrer" style="color: ${textColor};">Habere Git</a>
                            <hr />
                        </div>
                    `).join('')}
                </div>
                <div class="progress-bar" id="progressBar"></div>
                <script>
                    (function() {
                        var scrollSpeed = ${scrollSpeed};
                        var feedContainer = document.getElementById('feedContainer');
                        var progressBar = document.getElementById('progressBar');

                        function updateProgressBar() {
                            var scrollTop = feedContainer.scrollTop;
                            var scrollHeight = feedContainer.scrollHeight;
                            var clientHeight = feedContainer.clientHeight;
                            var scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
                            progressBar.style.width = scrollPercentage + '%';
                        }

                        setInterval(function() {
                            if (feedContainer) {
                                var scrollTop = feedContainer.scrollTop;
                                var scrollHeight = feedContainer.scrollHeight;
                                var clientHeight = feedContainer.clientHeight;
                                if (scrollTop + clientHeight >= scrollHeight) {
                                    feedContainer.scrollTop = 0;
                                } else {
                                    feedContainer.scrollTop += 1;
                                }
                                updateProgressBar();
                            }
                        }, scrollSpeed);

                        feedContainer.addEventListener('scroll', updateProgressBar);
                    })();
                </script>
            </body>
            </html>
        `;
        setIframeContent(content);
    }, [feedItems, backgroundColor, textColor, fontSize, scrollSpeed, progressColor]);

    const validateWidgetName = (name) => {
        if (!name || name.trim().length === 0) {
            setNameError('Widget adı boş olamaz');
            return false;
        }
        if (name.length < 3) {
            setNameError('Widget adı en az 3 karakter olmalıdır');
            return false;
        }
        if (name.length > 50) {
            setNameError('Widget adı en fazla 50 karakter olabilir');
            return false;
        }
        setNameError('');
        return true;
    };

    const handleSaveHtml = async () => {
        if (!validateWidgetName(widgetName)) {
            return;
        }
        try {
            await uploadWidget(widgetName, iframeContent);
            toast.success('Widget başarıyla oluşturuldu!');
            setIsUploaded(true);
        } catch (error) {
            toast.error('Widget oluşturulurken bir hata oluştu: ' + error.message);
        }
    };

    return (
        <Container>
            <Row>
                <Col xs={10} md={6} lg={3}>
                    <Card className="mb-4">
                        <Card.Header>RSS Widget Settings</Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="rssUrl">
                                    <Form.Label>RSS Feed URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="RSS Feed URL'sini girin"
                                        value={rssUrl}
                                        onChange={(e) => setRssUrl(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="widgetName" className="mt-3">
                                    <Form.Label>Widget Adı *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Widget adını girin"
                                        value={widgetName}
                                        onChange={(e) => {
                                            setWidgetName(e.target.value);
                                            validateWidgetName(e.target.value);
                                        }}
                                        isInvalid={!!nameError}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {nameError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="backgroundColor" className="mt-3">
                                    <Form.Label>Arka Plan Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={backgroundColor}
                                        onChange={(e) => setBackgroundColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="textColor" className="mt-3">
                                    <Form.Label>Yazı Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={textColor}
                                        onChange={(e) => setTextColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="progressColor" className="mt-3">
                                    <Form.Label>İlerleme Çubuğu Rengi</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={progressColor}
                                        onChange={(e) => setProgressColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="fontSize" className="mt-3">
                                    <Form.Label>Yazı Boyutu</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={fontSize}
                                        onChange={(e) => setFontSize(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="scrollSpeed" className="mt-3">
                                    <Form.Label>Kaydırma Hızı (ms)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        min="10"
                                        max="200"
                                        value={scrollSpeed}
                                        onChange={(e) => setScrollSpeed(e.target.value)}
                                    />
                                </Form.Group>

                                <div className="d-flex gap-2 mt-3">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="flex-grow-1 py-2"
                                    >
                                        Create Preview
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        className="flex-grow-1 py-2"
                                        onClick={handleSaveHtml}
                                        disabled={!widgetName || !!nameError}
                                    >
                                        Save
                                    </Button>
                                    {isUploaded && (
                                        <Button
                                            variant="success"
                                            className="flex-grow-1 py-2"
                                            onClick={() => history.push('/mymedia?tab=widgets')}
                                        >
                                            Medya'ya Git
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <Card>
                        <Card.Header>Preview</Card.Header>
                        <Card.Body style={{ padding: 0 }}>
                            {error && <Alert variant="danger" className="m-3">{error}</Alert>}
                            <iframe
                                ref={iframeRef}
                                srcDoc={iframeContent}
                                title="RSS Feed Preview"
                                style={{
                                    width: '100%',
                                    height: '600px',
                                    border: 'none'
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default RSSWidget;
