import { createContext, useContext, useEffect, useState } from "react";

export const AuthContext = createContext();

const { Provider } = AuthContext;

export function useAuthContext() {
  const contextValues = useContext(AuthContext);
  return contextValues;
}

export function AuthContextProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const updateCompanyLogoPath = (companyId, newLogoPath) => {
    setUser((prevUser) => {
      const updatedCompanies = prevUser.company.map((comp) => {
        if (comp._id === companyId) {
          return { ...comp, companyLogoPath: newLogoPath };
        }
        return comp;
      });

      const updatedUser = { ...prevUser, company: updatedCompanies };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return updatedUser;
    });
  };
  // Effects
  useEffect(() => {
    const isLogin = !!localStorage.getItem("token");

    console.log('token ne', localStorage.getItem("token"))
    if (isLogin) setIsLoggedIn(true);
    const _user = JSON.parse(localStorage.getItem("user"));
    if (user) setUser(_user);


  }, []);


  const updateUser = (newUserData) => {
    console.log("newUserData", newUserData);

    setUser((prevUser) => {
      const updatedUser = {
        ...prevUser,
        company: newUserData.company, // Fully replace or fallback to existing
      };

      console.log("updatedUser", updatedUser);
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return updatedUser; // Return the updated user object to update the state
    });
  };

  return (
    <Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        updateCompanyLogoPath,
        updateUser,
      }}
    >
      {children}
    </Provider>
  );
}
