import React, { useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "../api/axios";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { matchDevice, setDevicePlaylist } from "../api/routes";
import { useDevice } from "../contexts/Device/DeviceState";

const SetPlaylistToDeviceModal = ({ show, onHide, playlistId }) => {
  const [deviceId, setDeviceId] = useState("");
  const [loadings, setLoadings] = useState(false);

  const [userState, userDispatch] = useDevice();
  const { device, loading, error, message, media, companyId } = userState;
  console.log("devicelar gelmedi yar", device);
  const handleSave = async () => {
    setLoadings(true); // S
    try {
      const result = await axios.post(setDevicePlaylist, {
        deviceuuid: deviceId,
        playlistId: playlistId,
      });
      console.log("result", result);
      console.log("result", result.data.success);
      if (result.data.success) {
        toast.info("Device added success");
      } else toast.error("Device added failed: " + result?.data?.msg);
    } catch (error) {
      console.log("error", error);
      toast.error("Device added failed");
    }

    setLoadings(false); // S
    // You can perform saving logic here using the deviceId state
    onHide?.();
  };

  const setSelectedDevice = (id) => {
    setDeviceId(id);
  };

  const getSelectedDeviceName = () => {
    const selected = device.find((d) => d.deviceuuid === deviceId);
    return selected.label + "+" + selected.location;
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        setDeviceId("");
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Playlist to device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id={`filter-dropdown-${device.deviceuuid}`}
          >
            {deviceId ? getSelectedDeviceName() : "Select Device"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {device.map((devices) => (
              <Dropdown.Item
                key={devices.deviceuuid}
                onClick={() => {
                  setSelectedDevice(devices.deviceuuid);
                }}
              >
                {devices.label}-{devices.location}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <Spinner animation="grow" size="sm" className="ml-2" />
        ) : (
          <>
            <Button
              variant="secondary"
              onHide={() => {
                setDeviceId("");
                onHide();
              }}
            >
              Discard
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SetPlaylistToDeviceModal;
