import React, { useContext, useReducer } from "react";
import { DeviceContext } from "./DeviceContext";
import DeviceReducer from "./DeviceReducer";

export const useDevice = () => {
  const { state, dispatch } = useContext(DeviceContext);
  return [state, dispatch];
};

export const DeviceState = ({ children }) => {
  const initialState = {
    device: [],
    media: [],
    playlist: [],
    widget: [],
    loading: false,
    error: false,
    message: "",
    companyId: null,
  };

  const [state, dispatch] = useReducer(DeviceReducer, initialState);

  return (
    <DeviceContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
