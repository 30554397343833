import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const Navigation = (props) => {
  const navStyle = {
    backgroundColor: "white", // Background color
    color: "#212529", // Text color
    fontSize: "18px", // Text size
  };
  const { t } = useTranslation();
  return (
    <nav id="menu" className="navbar  navbar-fixed-top" style={navStyle}>
      <div style={{ width: "100%" }}>
        <ul
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
            listStyle: "none",
          }}
        >
          <li>
            <a
              href="#features"
              className="page-scroll"
              style={{ color: "#212529", textDecoration: "none" }}
            >

              {t("description.features")}
            </a>
          </li>
          <li>
            <a
              href="#about"
              className="page-scroll"
              style={{ color: "#212529", textDecoration: "none" }}
            >
              {t("description.howitworks")}
            </a>
          </li>
          <li>
            <a
              href="#services"
              className="page-scroll"
              style={{ color: "#212529", textDecoration: "none" }}
            >
              {t("description.services")}

            </a>
          </li>
          <li>
            <a
              href="#gallery"
              className="page-scroll"
              style={{ color: "#212529", textDecoration: "none" }}
            >
              {t("description.gallery")}
            </a>
          </li>
          <li>
            <a
              href="#contact"
              className="page-scroll"
              style={{ color: "#212529", textDecoration: "none" }}
            >
              {t("description.contact")}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};
