import { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getDatabase, ref, set } from "firebase/database";


const initialState = {
  name: '',
  email: '',
  message: '',
};

export const Contact = (props) => {
  const { t } = useTranslation();
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name, email, message);
    try {
      const db = getDatabase();
      set(ref(db, 'users/' + new Date().getTime().toString()), {
        name: name,
        email: email,
        message: message,
      });

      console.log("Document written with ID: ");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    alert('Submission has been sent. We will reach out to you soon.');
  };

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <Row>
            <div className='col-md-6'>
              <div className='section-title'>
                <h2>{t('description.contactget')}</h2>
                <p>
                  {t('description.contactdescription')}
                </p>
              </div>
              <Form name='sentMessage' validate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Control
                        type='text'
                        id='name'
                        name='name'
                        placeholder={t('description.name')}
                        required
                        onChange={handleChange}
                        value={name}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Control
                        type='email'
                        id='email'
                        name='email'
                        placeholder={t('description.emailaddress')}
                        required
                        onChange={handleChange}
                        value={email}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Form.Control
                    as='textarea'
                    name='message'
                    id='message'
                    rows='4'
                    placeholder={t('description.message')}
                    required
                    onChange={handleChange}
                    value={message}
                  />
                </Form.Group>
                <div id='success'></div>
                <Button type='submit' className='btn btn-custom btn-lg'
                >
                  {t('description.sendmessage')}

                </Button>
              </Form>
            </div>
            <div className='col-md-6 contact-info'>
              <div className='section-title'>
                <h2>{t('description.contactinfo')}</h2>
                <p>
                  <span>
                    <i className='fa fa-map-marker'></i> {t('description.address')}
                  </span>
                  {props.data ? props.data.address : 'loading'}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-phone'></i> {t('description.phone')}
                  </span>{' '}
                  {props.data ? props.data.phone : 'loading'}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-envelope-o'></i> {t('description.email')}
                  </span>{' '}
                  {props.data ? props.data.email : 'loading'}
                </p>
              </div>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='social'>
                    <ul>
                      <li>
                        <a href={props.data ? props.data.facebook : '/'}>
                          <i className='fa fa-facebook'></i>
                        </a>
                      </li>
                      <li>
                        <a href={props.data ? props.data.twitter : '/'}>
                          <i className='fa fa-twitter'></i>
                        </a>
                      </li>
                      <li>
                        <a href={props.data ? props.data.youtube : '/'}>
                          <i className='fa fa-youtube'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p></p>
        </div>
      </div>
    </div>
  );
};
