import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { createFilter, updateFilter } from "../api/routes";
function FilterModal({ show, handleClose, selectedFilter }) {
  console.log("selectedFİLTER", selectedFilter);
  console.log(
    "selectedFİLTER",
    selectedFilter ? selectedFilter.filtername : ""
  );
  const [hourRange, setHourRange] = useState(
    selectedFilter ? selectedFilter.selectedHour : []
  );
  const [selectedDays, setSelectedDays] = useState(
    selectedFilter ? selectedFilter.selectedDays : []
  );
  const [filterName, setFilterName] = useState(
    selectedFilter ? selectedFilter.filtername : ""
  );
  const [filterLabel, setFilterLabel] = useState(
    selectedFilter ? selectedFilter.label : ""
  );
  const [isPlaying, setIsPlaying] = useState(
    selectedFilter ? selectedFilter.isPlaying : false
  );

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleFilterNameChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterLabelChange = (event) => {
    setFilterLabel(event.target.value);
  };

  const handleDaySelect = (index) => {
    if (selectedDays.includes(index)) {
      setSelectedDays((prevDays) =>
        prevDays.filter((selectedDay) => selectedDay !== index)
      );
    } else {
      setSelectedDays((prevDays) => [...prevDays, index]);
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setIsPlaying(event.target.checked);
  };

  const handleHourChange = (event, isFromRange) => {
    const { value } = event.target;
    if (isFromRange) {
      const items = [];
      items[1] = hourRange[1];
      items[0] = value;
      // update state

      setHourRange(items);
    } else {
      const items = [];
      items[1] = value;
      items[0] = hourRange[0];
      setHourRange(items);
    }
  };
  return (
    <Modal show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label style={{ fontWeight: "bold" }}>Filter Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter filter name"
              value={filterName}
              onChange={handleFilterNameChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={{ fontWeight: "bold" }}>Filter Label</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter filter label"
              value={filterLabel}
              onChange={handleFilterLabelChange}
            />
          </Form.Group>

          <Form.Group style={{ marginTop: "20px" }}>
            <Form.Label style={{ fontWeight: "bold" }}>Hour</Form.Label>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Form.Control
                as="input"
                type="time"
                name="from"
                value={hourRange[0]}
                onChange={(e) => handleHourChange(e, true)}
              />
              <span style={{ margin: "0 5px" }}>to</span>
              <Form.Control
                as="input"
                type="time"
                name="to"
                value={hourRange[1]}
                onChange={(e) => handleHourChange(e, false)}
              />
            </div>
          </Form.Group>

          <Form.Group style={{ marginTop: "20px" }}>
            <Form.Label style={{ fontWeight: "bold" }}>Days</Form.Label>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridGap: "10px",
              }}
            >
              {days.map((day, index) => (
                <div
                  key={day}
                  onClick={() => handleDaySelect(index)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedDays.includes(index)
                      ? "#007bff"
                      : "white",
                    color: selectedDays.includes(index) ? "white" : "black",
                    padding: "5px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  {day}
                </div>
              ))}
            </div>
          </Form.Group>

          <Form.Group>
            <p style={{ color: "black", marginTop: "20px" }}>
              When meets filter criteria
            </p>
            <Form.Check
              type="checkbox"
              label="Is Play or not"
              checked={isPlaying} // Set the checked state
              onChange={handleCheckboxChange} // Handle change event
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            if (!filterName) {
              toast('Name is required');
              return;
            }
            console.log("arr1.some(r=> arr2.indexOf(r) >= 0)", selectedDays);
            console.log("selected", hourRange);
            try {
              if (selectedFilter) {
                const response = await axios.put(
                  updateFilter(selectedFilter._id),
                  {
                    filtername: filterName,
                    label: filterLabel,
                    isPlaying: isPlaying,
                    selectedDays: selectedDays,
                    selectedHour: hourRange,
                  }
                );
                toast.info("Success update filter");
                console.log("response", response);
              } else {
                const response = await axios.post(createFilter, {
                  filtername: filterName,
                  label: filterLabel,
                  isPlaying: isPlaying,
                  selectedDays: selectedDays,
                  selectedHour: hourRange,
                });
                toast.info("Success creating filter");
                console.log("response", response);
              }
              handleClose(true);
            } catch (error) {
              toast.error("Error creating filter" + error, {
                position: "top-right",
              });
              handleClose(false);
            }
          }}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FilterModal;
