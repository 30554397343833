import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { toast } from "react-toastify";
import validator from "validator";
import axios from "../../api/axios";
import { loginRoute } from "../../api/routes";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./Login.scss";
import styled from 'styled-components';

const CustomButton = styled(Button)`
  background-color: #cd204f;
  border-color: #cd204f;
  
  &:hover, &:focus, &:active {
    background-color: #b51c45 !important;
    border-color: #b51c45 !important;
  }

  &:disabled {
    background-color: #cd204f;
    border-color: #cd204f;
    opacity: 0.65;
  }
`;
const Login = () => {

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const { setIsLoggedIn, setUser } = useContext(AuthContext);
  const history = useHistory();

  const clearError = (setter) => {
    setter("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    clearError(setEmailError);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    clearError(setPasswordError);
  };

  const handleValidation = () => {
    let isValid = true;

    if (!validator.isEmail(email)) {
      isValid = false;
      setEmailError("Email Not Valid");
    } else {
      setEmailError("");
    }

    if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      isValid = false;
      setPasswordError(t('description.passworderror'));
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);

    if (handleValidation()) {
      try {
        const response = await axios.post(loginRoute, {
          email: email,
          password: password,
        });
        toast.success(t('description.loggedinsuccess'));
        setIsLoggedIn(true);
        setUser(response.data.user);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        history.replace("/dashboard");
      } catch (error) {
        console.error("Login error:", error);
        toast.error(t('description.loginerror') + (error.response?.data?.message || error.message));
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        loginSubmit(event);
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [email, password]); // Add dependencies here

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <Card className="shadow">
            <Card.Body>
              <h3 className="text-center mb-4">{t('description.login')}</h3>
              <Form id="loginform" onSubmit={loginSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('description.emailaddress')}</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t('description.enteremail')}
                    onChange={handleEmailChange}
                  />
                  <Form.Text className="text-danger">
                    {emailError}
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t('description.password')}</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t('description.enterpassword')}
                    onChange={handlePasswordChange}
                  />
                  <Form.Text className="text-danger">
                    {passwordError}
                  </Form.Text>
                </Form.Group>
                <div className="d-grid">
                  <CustomButton
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? t('description.loggingIn') : t('description.submit')}
                  </CustomButton>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
