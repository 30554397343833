import React, { useContext, useEffect, useState } from 'react';
import PricingCard from '../components/PricingCard';
import { AuthContext } from "../contexts/AuthContext";
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import axios from "../api/axios";
import { getSubscriptionProducts, createCheckout, updateSubscription, getSubscription } from "../api/routes";
import { useHistory } from 'react-router-dom';

const Plans = () => {
  const { user } = useContext(AuthContext);
  const [variants, setVariants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [subscription, setSubscription] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchUserSubscription();
    fetchProductsAndVariants();
    setupLemonSqueezy();
  }, [history]);

  const fetchUserSubscription = async () => {
    if (user && user.currentSubscriptionId) {
      try {
        const subscriptionData = await getSubscription(user.currentSubscriptionId);
        setSubscription(subscriptionData);
      } catch (error) {
        console.error('Error fetching user subscription:', error);
      }
    }
  };

  const setupLemonSqueezy = () => {
    window.createLemonSqueezy();
    window.LemonSqueezy.Setup({
      eventHandler: (event) => {
        switch (event.event) {
          case 'Checkout.Success':
            console.log('Checkout was successful');
            setShowModal(false);
            fetchUserSubscription(); // Refresh subscription data
            break;
          case 'PaymentMethodUpdate.Updated':
            console.log('Payment method has been updated successfully');
            alert('Payment method updated successfully!');
            break;
          default:
            console.log('Unhandled event:', event);
        }
      }
    });
  };

  const fetchProductsAndVariants = async () => {
    try {
      setLoading(true);
      const response = await axios.get(getSubscriptionProducts);
      setVariants(response.data.variants);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching LemonSqueezy products and variants:', error);
      setError('Failed to load products. Please try again later.');
      setLoading(false);
    }
  };

  const handleSubscription = async (variantId) => {
    if (!user) {
      alert('Please log in to subscribe');
      return;
    }

    try {
      const response = await axios.post(createCheckout, {
        variantId,
        userId: user.id,
        userEmail: user.email,
        quantity: 3
      });
      setCheckoutUrl(response.data.checkoutUrl);
      setShowModal(true);
    } catch (error) {
      console.error('Error creating checkout:', error);
      alert('Failed to initiate checkout. Please try again.');
    }
  };

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        await axios.delete(updateSubscription(user.currentSubscriptionId));
        alert('Subscription cancelled successfully');
        setSubscription(null);
      } catch (error) {
        console.error('Error cancelling subscription:', error);
        alert('Failed to cancel subscription. Please try again.');
      }
    }
  };

  const handleUpdateSubscription = async (newVariantId) => {
    try {
      const response = await axios.patch(updateSubscription(subscription.id), {
        variant_id: newVariantId,
        billing_anchor: new Date().getDay()
      });
      setSubscription(response.data.subscription);
      alert('Subscription updated successfully');
    } catch (error) {
      console.error('Error updating subscription:', error);
      alert('Failed to update subscription. Please try again.');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCheckoutUrl('');
  };

  const getFilteredVariants = () => {
    console.log("Current subscription:", subscription);
    console.log("All variants:", variants);

    if (!subscription) {
      console.log("No subscription found. Returning published variants.");
      const publishedVariants = variants.filter(v => v.attributes.status === "published");
      console.log("Published variants:", publishedVariants);
      return publishedVariants;
    }

    console.log("Filtering variants based on current subscription.");
    const currentVariant = variants.find(v => v.id === subscription.variant_id && v.attributes.status === "published");
    console.log("Current variant:", currentVariant);

    if (!currentVariant) {
      console.log("Current variant not found. Returning all published variants.");
      return variants.filter(v => v.attributes.status === "published");
    }

    const currentPrice = currentVariant.attributes.price;
    console.log("Current price:", currentPrice);

    const filteredVariants = variants.filter(v =>
      (v.attributes.price >= currentPrice || v.id === subscription.variant_id) && v.attributes.status === "published"
    ).sort((a, b) => a.attributes.price - b.attributes.price);

    console.log("Filtered variants:", filteredVariants);
    return filteredVariants;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const filteredVariants = getFilteredVariants();

  return (
    <Container fluid className="p-4 m-1">
      {subscription && (
        <div>
          <h2>Your Current Subscription</h2>
          <p>Status: {subscription.status_formatted}</p>
          <p>Plan: {subscription.product_name} - {subscription.variant_name}</p>
          <p>Renews on: {new Date(subscription.renews_at).toLocaleDateString()}</p>
          <Button onClick={handleCancelSubscription} variant="danger">Cancel Subscription</Button>
          <h3 className="mt-4">Upgrade Your Plan</h3>
        </div>
      )}
      <div className="row justify-content-center" style={{ margin: '20px' }}>
        {filteredVariants.map((variant) => (
          <div className="col" key={variant.id}>
            <PricingCard
              title={variant.attributes.name}
              price={variant.attributes.price / 100}
              billingCode={variant.attributes.slug}
              priceOverview={variant.attributes.description || 'No description available'}
              description={variant.attributes.description || 'No description available'}
              priceDesc={`${variant.attributes.interval || 'One-time'} payment`}
              onClick={() => subscription ? handleUpdateSubscription(variant.id) : handleSubscription(variant.id)}
              ctaText={subscription ? (subscription.variant_id === variant.id ? "Current Plan" : "Upgrade to This Plan") : "Subscribe Now"}
              cardType={variant.attributes.name}
              badge={subscription && subscription.variant_id === variant.id ? (
                <Badge bg="success">Current Plan</Badge>
              ) : null}
              disabled={subscription && subscription.variant_id === variant.id}
            />
          </div>
        ))}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Checkout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title='LemonSqueezy Checkout'
            src={checkoutUrl}
            width="100%"
            height="600px"
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Plans;
