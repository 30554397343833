import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../api/axios";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { matchDevice } from "../api/routes";
import { useHistory } from 'react-router-dom';


const AddDeviceModal = ({ show, onHide, companyid }) => {
  const [deviceId, setDeviceId] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleDeviceIdChange = (value) => {
    setDeviceId(value);
  };

  const handleSave = async () => {
    setLoading(true); // S
    try {
      const result = await axios.post(matchDevice, {
        createdDeviceId: deviceId.toLocaleLowerCase(),
        companyid: companyid,
      });
      console.log("result12", result);
      console.log("result21", result.data.success);
      if (result.data.success) {
        toast.info("Device added success");
      } 
        
    
    } catch (error) {
      console.log("error1", error.response.data.action);
      if(error.response.data.action) {
        if(error.response.data.action==='limit'){
         
          toast(<Msg  msg='Device limit exceeded' actionMsg='Buy extra screen' action={
            () => {
              history.push("/plans?action=extra_screen");
            }
          }/>, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
     
        }else if(error.response.data.action==='noSubs'){
          toast(<Msg msg='No active subscription'   actionMsg='Buy extra screen' action={
            () => {
              history.push("/plans?action=extra_screen");
            }
          } />);
        }
    }
      toast.error("Device added failed");
    }

    setLoading(false); // S
    // You can perform saving logic here using the deviceId state
    onHide?.();
  };

  const Msg = ({closeToast,msg,actionMsg,action}) => (
    <div>
     {msg} 
      <button onClick={()=>action?.()}>{actionMsg}</button>
      <button onClick={closeToast}>Close</button>
    </div>
  )
  

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Enter 8-digit device ID"
            value={deviceId}
            maxLength={8}
            onChange={(e) => handleDeviceIdChange(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <Spinner animation="grow" size="sm" className="ml-2" />
        ) : (
          <>
            <Button variant="secondary" onClick={onHide}>
              Discard
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddDeviceModal;
