import React, { useState } from "react";
import { Button,Card } from "react-bootstrap";
import styled, { keyframes } from "styled-components";

const waveAnimation = keyframes`
  0%, 100% {
    transform:  translateX(50%) translateY(5%) scaleY(1);
  }
  25% {
    transform:  translateX(50%) translateY(5%) scaleY(1.2);
  }
  75% {
    transform:  translateX(50%) translateY(5%) scaleY(1);
  }
`;

const Ribbon = styled.div`
  position: absolute;
  top: -10px;
  right: 10%;
  transform: translateX(50%);
  width: 80px;
  height: 80px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 6px), 0 calc(100% - 12px));
  z-index: 1;
  background-color: #FFD700;
  animation: ${waveAnimation} 1s 4 linear;
`;



const BadgeText = styled.span`
  color: #000; /* Adjust color as needed */
  font-family: Arial, sans-serif; /* Adjust font family */
  font-size: 1em; /* Adjust font size */
  font-weight: bold; /* Adjust font weight */
`;

const PricingCard = ({
  title,
  price,
  billingCode,
  priceOverview,
  description,
  priceDesc,
  onClick,
  cardType,
  featured,
  free,
  ctaText,
  ctaSecondaryText,
  badge
}) => {
  const priceString = price.toFixed(2);
  const [dollar, cent] = priceString.split(".");
  const [isHovered, setIsHovered] = useState(false);

  let pastelColor = "#89CFF0";
  let textColor = "#fff";
  let badgeText = "";

  switch (cardType) {
    case "Free":
      pastelColor = "#FFD700";
      textColor = "#000";
      break;
    case "Beginner":
      pastelColor = "#FFDAB9";
      textColor = "#000";
      break;
    case "Middle":
      pastelColor = "#B0E0E6";
      break;
    case "Unlimited":
      pastelColor = "#800080";
      textColor = "#fff";
      badgeText = "Best Option for Business";
      break;
    default:
      break;
  }

  const cardStyle = {
    border: `1px solid ${pastelColor}`,
    borderRadius: "10px",
    // Set a fixed width for the card
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    backgroundColor: featured ? pastelColor : free ? "#f0f0f0" : pastelColor,
    color: textColor,
    transition: "transform 0.3s, box-shadow 0.3s",
    transform: isHovered ? "scale(1.05)" : "scale(1)",
    boxShadow: isHovered ? "0 0 20px rgba(0, 0, 0, 0.1)" : "none",
    width: '16rem', minHeight:'450px', marginBottom:'35px',
    height: "95%",
  };

  return (
    <Card style={cardStyle}  onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}>
     
        {cardType === "Unlimited" && <Ribbon><BadgeText>{badgeText}</BadgeText></Ribbon>}
        <div
          style={{
            background: featured ? pastelColor : free ? pastelColor : cardType === "Beginner" ? "#FFDAB9" : cardType === "Unlimited" ? "#800080" : "#ccc",
            padding: "20px 25px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            fontSize: "1.7em",
            fontWeight: "bold",
            
          }}
        >
          {title}
        </div>
        {badge && <div style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'red', color: 'white', padding: '5px 10px', borderRadius: '5px' }}>{badge}</div>}
        <div style={{ padding: "20px" }}>
          <h2 style={{ fontSize: "2em", paddingRight: "3px", fontWeight: "bold", color: textColor }}>
            <span style={{ fontSize: "2em", paddingRight: "3px", fontWeight: "bold", color: textColor }}>$</span>
            <span style={{ fontSize: "2em", paddingRight: "3px", fontWeight: "bold", color: textColor }}>{dollar}</span>.
            <span style={{ fontSize: "1em", fontWeight: "bold", color: textColor }}>{cent}</span>
          </h2>
          <div style={{ fontSize: "1em", marginTop: "0", color: textColor }}>{description}</div>
          <div style={{ fontWeight: "bold", marginTop: "20px", color: textColor }}>{priceOverview}</div>
          <div style={{ fontSize: "0.8em", marginTop: "20px", color: textColor }}>{priceDesc}</div>
        </div>

        {!free && (
          <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'end'
          }}>
          <Button
            onClick={onClick}
            style={{
              display: "block",
              textAlign: "center",
              position: "relative",
              margin: "20px auto",
              padding: "10px 25px",
              borderColor:'white',
              background: featured ? "#fff" : pastelColor,
              border: `1px solid white`,
              color: featured ? pastelColor : free ? textColor : "#fff",
              fontWeight: "bold",
              borderRadius: "25px",
              textDecoration: "none",
            }}
          >
            {ctaText}
          </Button>
          </div>
        )}
      
    </Card>
  );
};

export default PricingCard;
