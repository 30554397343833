import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

const TextInput = ({ label, name, handleValue, defaultValue = "", row }) => {
  const [value, setValue] = useState(defaultValue); // Initial value for the input

  const handleChange = (e) => {
    setValue(e.target.value);
    handleValue?.(e.target.value);
  };

  return (
    <Form.Group as={row ? Row : 'div'} controlId={name} style={{ display: 'flex', alignItems: 'center' }}>
      <Form.Label column sm={3}>
        {label}
      </Form.Label>
      <Col>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={`Enter ${label}`}
        />
      </Col>
    </Form.Group>
  );
};

export default TextInput;
