import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import uploadWidget from './uploadWidget';

const WordClock = () => {
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [backgroundColor, setBackgroundColor] = useState('#000000');
    const [textColor, setTextColor] = useState('#FFFFFF');
    const [fontSize, setFontSize] = useState('24');
    const [iframeContent, setIframeContent] = useState('');

    useEffect(() => {
        fetch('https://restcountries.com/v3.1/all?fields=name,capital,timezones')
            .then(response => response.json())
            .then(data => {
                const sortedCountries = data.sort((a, b) => a.name.common.localeCompare(b.name.common));
                setCountries(sortedCountries);
            })
            .catch(error => console.error('Error fetching countries:', error));
    }, []);

    useEffect(() => {
        if (selectedCountries.length > 0) {
            const content = `
                <!DOCTYPE html>
                <html>
                <head>
                    <style>
                        body {
                            margin: 0;
                            padding: 20px;
                            background-color: ${backgroundColor};
                            font-family: 'Arial', sans-serif;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            min-height: 100vh;
                        }
                        .clock-frame {
                            background-color: #333;
                            border-radius: 20px;
                            padding: 20px;
                            margin: 10px;
                            box-shadow: 0 0 20px rgba(0,0,0,0.3);
                        }
                        .word-clock {
                            color: ${textColor};
                            font-size: ${fontSize}px;
                            text-align: center;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                        }
                        .country-name {
                            color: ${textColor};
                            font-size: ${parseInt(fontSize) * 0.6}px;
                            text-align: center;
                            margin-bottom: 10px;
                        }
                    </style>
                </head>
                <body>
                    ${selectedCountries.map(country => `
                        <div class="clock-frame">
                            <div class="country-name">${country.name.common}</div>
                            <div class="word-clock" id="wordClock-${country.name.common.replace(/\s+/g, '-')}"></div>
                        </div>
                    `).join('')}
                    <script>
                        function getTimeForTimezone(timezone) {
                            const now = new Date();
                            let time;
                            try {
                                time = now.toLocaleString('en-US', { timeZone: timezone, hour: 'numeric', minute: 'numeric', hour12: true });
                            } catch (e) {
                                console.warn(\`Invalid timezone: \${timezone}. Using local time.\`);
                                time = now.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                            }
                            return time;
                        }

                        function updateClock(countryName, timezone) {
                            const timeString = getTimeForTimezone(timezone);
                            const [time, period] = timeString.split(' ');
                            const [hours, minutes] = time.split(':').map(Number);
                            
                            const wordTime = numberToWords(hours % 12 || 12) + ' ' + numberToWords(minutes) + ' ' + period.toLowerCase();
                            document.getElementById('wordClock-' + countryName.replace(/\\s+/g, '-')).textContent = wordTime;
                        }

                        function numberToWords(num) {
                            const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
                            const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty'];
                            
                            if (num < 20) return ones[num];
                            const ten = Math.floor(num / 10);
                            const one = num % 10;
                            return (tens[ten] + (one ? ' ' + ones[one] : '')).trim();
                        }

                        const countries = ${JSON.stringify(selectedCountries)};
                        
                        function updateAllClocks() {
                            countries.forEach(country => {
                                updateClock(country.name.common, country.timezones[0]);
                            });
                        }

                        setInterval(updateAllClocks, 1000);
                        updateAllClocks();
                    </script>
                </body>
                </html>
            `;
            setIframeContent(content);
        }
    }, [selectedCountries, backgroundColor, textColor, fontSize]);

    const handleCountryChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        const selectedCountriesData = countries.filter(country => selectedOptions.includes(country.name.common));
        setSelectedCountries(selectedCountriesData);
    };

    const handleSaveHtml = () => {
        const blob = new Blob([iframeContent], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'word-clock-widget.html';
        link.click();
        uploadWidget('wordClockWidget', iframeContent);
    };

    return (
        <Container>
            <Form>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="country">
                            <Form.Label>Countries</Form.Label>
                            <Form.Select
                                multiple
                                value={selectedCountries.map(c => c.name.common)}
                                onChange={handleCountryChange}
                                style={{ height: '200px' }}
                            >
                                {countries.map((country) => (
                                    <option key={country.name.common} value={country.name.common}>
                                        {country.name.common}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={4}>
                        <Form.Group controlId="backgroundColor">
                            <Form.Label>Background Color</Form.Label>
                            <Form.Control
                                type="color"
                                value={backgroundColor}
                                onChange={(e) => setBackgroundColor(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="textColor">
                            <Form.Label>Text Color</Form.Label>
                            <Form.Control
                                type="color"
                                value={textColor}
                                onChange={(e) => setTextColor(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="fontSize">
                            <Form.Label>Font Size (px)</Form.Label>
                            <Form.Control
                                type="number"
                                value={fontSize}
                                onChange={(e) => setFontSize(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <div className="mt-4">
                <iframe
                    srcDoc={iframeContent}
                    title="Word Clock Preview"
                    style={{
                        width: '100%',
                        height: '400px',
                        border: 'solid 1em #333',
                        borderRadius: '0.5em'
                    }}
                />
            </div>

            <Button variant="secondary" className="mt-3" onClick={handleSaveHtml}>
                Save HTML
            </Button>
        </Container>
    );
};

export default WordClock;
