import React, { useState } from "react";
import FilterModal from "../components/FilterModal";
import FloatingActionButton from "../components/FloatingActionButton";
import { OverlayTrigger, Popover } from "react-bootstrap";
import UserFilterList from "../components/FilterCard";
function Filter(props) {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = (status) => {
    setShowModal(false);
  };

  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus" title="Popover bottom">
      <strong>Create new Filter</strong> Click to create
    </Popover>
  );
  const fabContainerStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: "1",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div
      fluid
      className="d-flex flex-column "
      style={{ height: "100vh", padding: "20px" }}
    >
      <h1 style={{ textAlign: "center" }} className="mb-4">Filter Settings</h1>
      <UserFilterList refresh={showModal} />
      <FilterModal show={showModal} handleClose={handleCloseModal} />

      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="top"
        overlay={popoverHoverFocus}
      >
        <div style={fabContainerStyle}>
          <FloatingActionButton
            onClick={() => {
              handleOpenModal();
            }}
            userId={""}
          />
        </div>
      </OverlayTrigger>
    </div>
  );
}

export default Filter;
