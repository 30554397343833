// User
export const loginRoute = "/user/login";
export const registerRoute = "/user/signup";
export const changePasswordRoute = "/user/update-password";
export const updateCompany = "/user/update-company";
export const deleteCompany = "/user/delete-company";
export const companyList = "/user/company-list";
export const profile = "/user/profile";
export const allPlaylist = "/playlist/list";
export const addPlaylist = "/playlist/addPlaylist";
export const deletePlaylist = "/playlist/deletePlaylist";
export const updatePlaylist = "/playlist/updatePlaylist";
export const createFilter = "/playlist/createFilter";
export const fetchCompanyLogs = (companyId) => `/devices/company-logs/${companyId}`;
export const deleteFilter = (filterId) => {
  return `/playlist/deleteFilter/${filterId}`;
};
export const updateFilter = (filterId) => {
  return `/playlist/updateFilter/${filterId}`;
};
export const getAllFilters = "/playlist/getAllFilters";
export const deviceList = "/devices/deviceList";
export const deviceUpdate = "/devices/deviceUpdate";
export const matchDevice = "/devices/matchDevice";
export const setDevicePlaylist = "/devices/setDevicePlaylist";
export const mediaUpload = "/media/uploadMedia";
export const getAllMedia = "/media/getAll";
export const getImageFromDevice = (deviceId) => {
  return `screen/${deviceId}/request-screenshot`;
};
export const deleteMadia = (mediaId) => {
  return `/media/removeMedia/${mediaId}`;
};
export const widgetUpload = "/widget/upload";
export const getAllWidget = "/widget/get";
export const deleteWidget = "/widget/delete";

export const getSubscriptionProducts = "/api/products";
export const createCheckout = "/api/create-checkout";
export const getSubscription = (subscriptionId) => `/api/subscription/${subscriptionId}`;
export const cancelSubscription = (subscriptionId) => `/api/subscription/${subscriptionId}`;
export const updateSubscription = (subscriptionId) => `/api/subscription/${subscriptionId}`;
export const createInvoiceRoute = (subscriptionId) => `/api/subscription-invoices/${subscriptionId}/generate-invoice`;
export const listInvoicesRoute = (subscriptionId) => `/api/subscription-invoices?subscription_id=${subscriptionId}`;