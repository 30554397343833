import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faCode,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs, TabPane, TabContent } from "react-bootstrap";
import FileUpload from "./FileUpload";
import styled from "styled-components";

const sidePanelStyle = {
  position: "fixed",
  right: 0,
  top: 0,
  bottom: 0,
  width: "300px",
  backgroundColor: "#f8f9fa",
  padding: "20px",
  transition: "right 0.3s ease-in-out",
  boxShadow: "-4px 0 8px rgba(0, 0, 0, 0.1)", // Add the left-side shadow
  zIndex: "999",
};

const toggleIconStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  width:"20px",
  height:"20px",
  zIndex:999,
  alignItems:'center',
  justifyContent:'center',
  display:'flex',
  alignSelf:'center'
};

const SidePanel = ({
  onMediaDrop,
  onWidgetDrop,
  isVisible,
  toggleVisibility,
  mediaData,
  widgetData,
  onFileUploadSuccess,
}) => {
  const handleDragStart = (event, item, itemType) => {
    event.dataTransfer.setData(
      itemType === 'widget' ? "widgetItem" : "mediaItem",
      JSON.stringify(item)
    );
  };
  const [activeTab, setActiveTab] = useState("media");
  const WidgetIcon = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  overflow: hidden;
  
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4a90e2;
`;
  const renderItem = (item, index, itemType) => (
    <div
      key={index}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#f9f9f9",
        marginTop: "10px",
      }}
      draggable
      onDragStart={(event) => handleDragStart(event, item, itemType)}
    >
      {itemType === 'widget' ? (
        <div
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "5px",
            backgroundColor: "lightblue",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WidgetIcon>
            <FontAwesomeIcon icon={faCode} size="30" color="#ffffff" />
          </WidgetIcon>
        </div>
      ) : item.type.startsWith("image") ? (
        <img
          src={item.downloadUrl}
          alt={item.fileName}
          style={{
            width: "100%",
            height: "150px",
            objectFit: "cover",
          }}
          crossOrigin="anonymous"
        />
      ) : (
        <div
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "5px",
            backgroundColor: "gray",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faFolder}
            style={{ fontSize: "30px" }}
          />
        </div>
      )}
      <span style={{ marginTop: "5px", fontSize: "12px" }}>
        {item.fileName || item.name}
      </span>
    </div>
  );

  return (
    <>
      {!isVisible ? (
        <div
          style={toggleIconStyle}
          onClick={toggleVisibility}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      ) : null}
      <div style={{ ...sidePanelStyle, right: isVisible ? 0 : "-300px" }}>
        {isVisible ? (
          <div
          
            style={toggleIconStyle}
            onClick={toggleVisibility}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        ) : null}
        <Tabs
          defaultActiveKey="media"
          id="side-panel-tabs"
          activeKey={activeTab}
          onSelect={(tabKey) => setActiveTab(tabKey)}
        >
          <Tab eventKey="media" title="Media">
            <Tab.Content
              style={{
                maxHeight: "calc(100vh - 100px)",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "10px",
                }}
              >
                {mediaData.map((item, index) => renderItem(item, index, 'media'))}
              </div>
            </Tab.Content>
          </Tab>
          <Tab eventKey="widgets" title="Widgets">
            <Tab.Content
              style={{
                maxHeight: "calc(100vh - 100px)",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "10px",
                }}
              >
                {widgetData.map((item, index) => renderItem(item, index, 'widget'))}
              </div>
            </Tab.Content>
          </Tab>
          <Tab eventKey="upload" title="Upload">
            <Tab.Content>
              <div>
                <FileUpload
                  onSuccess={() => {
                    setActiveTab("media");
                    onFileUploadSuccess();
                  }}
                />
              </div>
            </Tab.Content>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default SidePanel;