import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "react-bootstrap";

function CropImageModal({ imageUrl, show, onClose }) {
  const imageRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 16 / 9,
  });
  alert(imageUrl);
  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const handleCropSubmit = () => {
    if (!imageRef.current || !crop.width || !crop.height) return;

    getCroppedImage(imageRef.current, crop)
      .then((croppedImage) => {
        console.log("Cropped image:", croppedImage);
        onClose();
      })
      .catch((error) => {
        console.error("Error while cropping:", error);
        onClose();
      });
  };

  const getCroppedImage = (image, crop) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <ReactCrop
            src={"https://picsum.photos/200"}
            crop={crop}
            crossOrigin="anonymous"
            onChange={handleCropChange}
            ref={imageRef}
          />
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button onClick={handleCropSubmit}>Crop</button>
            <button onClick={onClose}>Cancel</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

CropImageModal.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CropImageModal;
