import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/fontawesome-free-solid";
import { useEffect, useState, useCallback, useRef } from "react";
import axios from "../api/axios";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  changePasswordRoute,
  companyList,
  deviceUpdate,
  getImageFromDevice,
  updateCompany,
} from "../api/routes";
import {
  faMapMarkerAlt,
  faTag,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
function DeviceCard({ d, getDevice, es }) {
  const [show, setShow] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [label, setLabel] = useState(d.label);
  const [location, setLocation] = useState(d.location);
  const [dataURL, setdataURL] = useState(null);
  const [deviceOffline, setdeviceOffline] = useState(false);
  const wasCalled = useRef(false);

  //    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII="
  //  );

  const submit = async () => {
    try {
      const res = await axios.post(deviceUpdate, {
        label: label.val,
        location: location.val,
        deviceId: d.deviceuuid,
      });
      getDevice?.();
      if (res.data.success) {
        toast.info("Device changed success");
      } else toast.error("Device changes failed");
    } catch (error) {
      toast.error("Device changes failed");
    }
  };

  const getImage = async (open) => {
    try {
      const res = await axios.post(getImageFromDevice(d.deviceuuid), {
        openOrClose: open,
      });
      console.log("getImage res", res);
      console.log("getImage res", res.data);
      if (!res.data.success) {
        setdeviceOffline(true);
      }
    } catch (error) {
      setdeviceOffline(true);
      console.log("error", error);
    }
  };

  useEffect(() => {
    // Call getImage(true) when the component mounts
    getImage(true);

    // Return a cleanup function that will be executed when the component unmounts
    return () => {
      // Call getImage(false) when the component unmounts
      getImage(false);
    };
  }, []);

  useEffect(() => {
    console.log("CALLED", wasCalled.current);
    if (wasCalled.current) return;
    wasCalled.current = true;
    es?.addEventListener(d.deviceuuid.toString(), (msg) => {
      console.log("message on card", msg);
      console.log("message on card", d.deviceuuid.toString());
      setdataURL(
        "data:image/png;base64," + msg.data.substring(1, msg.data.length - 1)
      );
    });
    es?.addEventListener(d.deviceuuid.toString() + "isOnline", (msg) => {
      console.log(
        "isOnline or offline",
        msg,
        "device uuid " + d.deviceuuid.toString()
      );
      console.log("message on card", d.deviceuuid.toString());
      if (msg.data) {
        setdeviceOffline(false);
        getImage(true);
        toast.info("Device now online: " + d.label + " " + d.location);
      } else {
        getImage(false);
        //setdeviceOffline(true);
        toast.info("Device now offline: " + d.label + " " + d.location);
      }
    });
  }, [d.deviceuuid, d.label, d.location, es, getImage]);

  return (
    <div id="container">
      <article>
        <div class="stand">
          <div
            class="monitor"
            onMouseEnter={() => setShowEditIcon(true)}
            onMouseLeave={() => setShowEditIcon(false)}
          >
            <div className="d-flex align-items-stretch justify-content-between">
              <div className="location-container d-flex align-items-center mr-3">
                <FontAwesomeIcon
                  className="location-icon"
                  icon={faMapMarkerAlt}
                />
                <div className="location-text">{d.location}</div>
              </div>
              <div className="label-container d-flex align-items-center">
                <div className="label">
                  <FontAwesomeIcon className="label-icon mr-1" icon={faTag} />
                  <span className="label-text">{d.label}</span>
                </div>
                {showEditIcon && (
                  <div
                    className="edit-icon-container ml-2"
                    onClick={handleShow}
                  >
                    <FontAwesomeIcon className="edit-icon" icon={faEdit} />
                  </div>
                )}
              </div>
            </div>
            <div>
              {deviceOffline ? (
                <div
                  style={{
                    width: "300px",
                    height: "200px",
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "24px",
                  }}
                >
                  OFFLINE
                </div>
              ) : dataURL ? (
                <img
                  src={dataURL}
                  alt="Loaded Image"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "auto",
                    height: "auto",
                    minHeight:'200px',
                    minWidth:'280px'
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "300px",
                    height: "200px",
                    margin: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Spinner
                    animation="border"
                    role="status"
                    variant="primary"
                    color="light"
                  >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
            </div>
          </div>
        </div>
      </article>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change device Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Device location</Form.Label>
              <Form.Control
                type="text"
                placeholder={d.location}
                defaultValue={d.location}
                autoFocus
                onChange={(e) => setLocation({ val: e.target.value })}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Device label</Form.Label>
              <Form.Control
                type="text"
                placeholder={d.label}
                defaultValue={d.label}
                autoFocus
                onChange={(e) => setLabel({ val: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              submit();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeviceCard;
