import { useState, useEffect } from "react";
import { Navigation } from "../components/MainPageComponents/navigation";
import { Header } from "../components/MainPageComponents/header";
import { Features } from "../components/MainPageComponents/features";
import { About } from "../components/MainPageComponents/about";
import { Services } from "../components/MainPageComponents/services";
import { Gallery } from "../components/MainPageComponents/gallery";
import { Testimonials } from "../components/MainPageComponents/testimonials";
import { Team } from "../components/MainPageComponents/Team";
import { Contact } from "../components/MainPageComponents/contact";
import JsonData from "../components/MainPageComponents/data/data.json";
import SmoothScroll from "smooth-scroll";
import "./DFN.css";
import { Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const DFNPlayer = ({db}) => {
  const { t, i18n } = useTranslation(); 
  const [landingPageData, setLandingPageData] = useState({});
  const [showVideo, setShowVideo] = useState(true);
  let lang = navigator.language || navigator.userLanguage;

  const handleClose = () => setShowVideo(false);

  useEffect(() => {
    i18n.changeLanguage('tr');
  },[navigator.language, navigator.userLanguage]);

  useEffect(() => {
    const lang=i18n.language;
    const languages = ['en', 'tr','de','fr','es','hi','ja','ko','pt','ur','ar']
    if (languages.includes(lang)) {
      import(`../components/MainPageComponents/data/data${lang.toLocaleUpperCase()}.json`).then((data) => {
        setLandingPageData(data.default);
      });
    } else {
      setLandingPageData(JsonData);
    }
   
  }, [i18n.language,lang]);

  return (
    <div style={{width:'100%',flex:1,justifyContent:'center',alignItems:'center'}}>
      {/* Video Modal */}
      <Modal 
        show={showVideo} 
        onHide={handleClose}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>How it works</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ratio ratio-16x9">
            <iframe  src="https://www.youtube-nocookie.com/embed/Raz1zw6Vuuc?si=A1YVWp9mjaVTc52n&amp;controls=0"
             title="DFN Player" 
             frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
             referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
              style={{ borderRadius: '10px' }}></iframe>
          </div>
        </Modal.Body>
      </Modal>
      
      <Navigation />
      
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      {/* <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />*/}
      <Contact data={landingPageData.Contact} db={db}/>
    </div>
  );
};

export default DFNPlayer;
