import React from 'react';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  width: 50px;
  height: 50px;
  border: 2px solid #cd204f;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const CompanyLogo = () => {
  return (
    <LogoWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: "30px", // Smaller than container to provide padding
          height: "30px",
          verticalAlign: "middle",
          fill: "#cd204f", // Match border color
          overflow: "hidden",
        }}
        viewBox="0 0 1024 1024"
      >
        <path d="M901.12 841.728h-51.712V374.272c0-72.704-41.472-103.936-67.584-109.056l-165.888-51.712V156.16c0-10.24-5.12-15.36-10.24-20.992-5.12-5.12-15.36-5.12-20.992-5.12l-347.648 98.816s-62.464 15.36-62.464 98.816v514.048H122.88c-15.36 0-26.112 10.24-26.112 26.112 0 15.36 10.24 26.112 26.112 26.112h778.24c15.36 0 26.112-10.24 26.112-26.112 0-15.872-10.24-26.112-26.112-26.112zm-337.408 0H226.304V327.68c0-36.352 20.992-46.592 26.112-46.592l311.296-88.064v648.704zm233.984 0h-181.76V265.216l160.256 53.248 3.584 1.536s15.872 6.656 18.432 54.272v467.456zM371.712 300.032H320v51.712h51.712v-51.712zm119.296 0h-51.712v51.712h51.712v-51.712zM371.712 413.184H320v51.712h51.712v-51.712zm119.296 0h-51.712v51.712h51.712v-51.712zM371.712 526.336H320v51.712h51.712v-51.712zm119.296 0h-51.712v51.712h51.712v-51.712zm-119.296 112.64H320V691.2h51.712v-52.224zm119.296 0h-51.712V691.2h51.712v-52.224zM371.712 752.128H320v51.712h51.712v-51.712zm119.296 0h-51.712v51.712h51.712v-51.712zM683.52 452.096h51.712v-51.712H683.52v51.712zm0 119.296h51.712V519.68H683.52v51.712zm0 113.664h51.712v-51.712H683.52v51.712zm0 115.2h51.712v-51.712H683.52v51.712z" />
      </svg>
    </LogoWrapper>
  );
};

export default CompanyLogo;
