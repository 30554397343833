import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
export const Header = (props) => {
  const { t, i18n } = useTranslation();
  return (
    
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <Helmet>
                <title>{props.data ? props.data.title : "Loading"}</title>
                <meta
                  name="description"
                  content={
                    props.data
                      ? props.data.paragraph
                      : "Digital signage easy and cheap solution"
                  }
                />
                {/* Add more meta tags as needed */}
              </Helmet>

              <div className="intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a href="#features" className="btn-custom btn-lg page-scroll">
                  {t("description.learnmore")}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
