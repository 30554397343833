import React, { useState, useContext, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { useDevice } from '../contexts/Device/DeviceState';
import { setCompany } from '../contexts/Device/DeviceAction';
import CompanyLogo from '../assets/CompanyLogo';
import { AuthContext } from "../contexts/AuthContext";
import { useHistory } from 'react-router-dom';

const CompanyPicker = () => {
  const [userState, userDispatch] = useDevice();
  const { companyId } = userState;
  const history = useHistory();
  const { user } = useContext(AuthContext);
  
  if (companyId == null) {
    setCompany(userDispatch, user?.company[0]._id);
  }

  const [selectedCompany, setSelectedCompany] = useState(user?.company[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleCompanySelect = (event, company) => {
    event.stopPropagation();
    setSelectedCompany(company);
    setCompany(userDispatch, company?._id);
  };

  const handleToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleSelect = (e, company) => {
    handleCompanySelect(e, company);
    setDropdownOpen(false);
  };

  useEffect(() => {
    setSelectedCompany(user?.company.find((company) => company._id === companyId));
  }, [user, companyId]);

  const handleLogoClick = () => {
    history.push('/account');
  };

  return (
    <CompanyPickerContainer>
      <LogoContainer onClick={handleLogoClick}>
        {selectedCompany?.companyLogoPath ? (
          <CompanyImage
            crossOrigin="anonymous"
            src={selectedCompany.companyLogoPath}
            alt={selectedCompany.companyName}
          />
        ) : (
          <CompanyLogo />
        )}
      </LogoContainer>
      <DropdownContainer>
        <Dropdown show={dropdownOpen} onToggle={handleToggle}>
          <StyledDropdownToggle variant="success" id="dropdown-basic">
            {selectedCompany ? selectedCompany.companyName : 'Select a company'}
          </StyledDropdownToggle>

          <Dropdown.Menu>
            {user?.company.map((company, index) => (
              <Dropdown.Item
                key={index}
                onClick={(e) => handleSelect(e, company)}
              >
                <DropdownItemContent>
                  {company?.companyLogoPath ? (
                    <CompanyImage
                      crossOrigin="anonymous"
                      src={company?.companyLogoPath}
                      alt={company?.companyName}
                    />
                  ) : (
                    <CompanyLogo />
                  )}
                  <span>{company?.companyName}</span>
                </DropdownItemContent>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </DropdownContainer>
    </CompanyPickerContainer>
  );
};

export default CompanyPicker;

const CompanyPickerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoContainer = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const CompanyImage = styled.img`
  width: 50px;
  height: 50px;
  border: 2px solid #cd204f;
  border-radius: 25px;
`;

const DropdownContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  background-color: transparent;
  color: white;
  border: none;
  box-shadow: none;
  &:hover, &:focus, &:active {
    background-color: transparent !important;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

const DropdownItemContent = styled.div`
  display: flex;
  align-items: center;
  
  span {
    margin-left: 10px;
  }
`;
