import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card, Table, Badge, ProgressBar, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faChartBar, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import axios from "../api/axios";
import { fetchCompanyLogs } from '../api/routes';
import { useContext } from 'react';
import { DeviceContext } from '../contexts/Device/DeviceContext';
import { useDevice } from "../contexts/Device/DeviceState";
import { getImageFromDevice } from "../api/routes";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin-top: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FullWidthCard = styled(Card)`
  grid-column: 1 / -1;
  margin-top: 20px;
`;

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 15px;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const CardHeader = styled(Card.Header)`
  background-color: #f8f9fa;
  padding: 20px;
  display: flex;
  align-items: center;
`;

const CardIcon = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cd204f;
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
`;

// Add these helper functions at the top level
const EVENT_CATEGORIES = {
    APP: 'Application',
    DEVICE: 'Device',
    PLAYLISTS: 'Playlists',
    PLAYER: 'Player',
    DOWNLOAD_MANAGER: 'Download Manager',
    NETWORK: 'Network',
    ERROR: 'Error'
};

const getEventTypeColor = (eventType) => {
    console.log('eventType:', eventType); // For debugging
    if (!eventType) return 'secondary';

    // Split the type (e.g., "PLAYER_START" -> ["PLAYER", "START"])
    const parts = eventType.split('_');
    const category = parts[0].toUpperCase();
    const action = parts.slice(1).join('_').toUpperCase();

    console.log('category:', category, 'action:', action); // For debugging

    const colors = {
        PLAYER: {
            START: 'success',
            PLAY: 'info',
            CHANGE_ITEM: 'warning',
            STOP: 'danger',
            LOADING: 'warning',
            default: 'primary'
        },
        APP: {
            START: 'success',
            STOP: 'danger',
            GET_DEVICE_ID: 'info',
            CREATED_DEVICE_ID: 'primary',
            ACTIVATED: 'success',
            CHECK_ACTIVATED: 'warning',
            CONNECTED: 'success',
            WEB_SOCKET_CONNECTED: 'info',
            default: 'primary'
        },
        DEVICE: {
            START: 'success',
            ORIENTATION: 'info',
            DEVICE_CHANGE: 'warning',
            READ_FROM_STORAGE: 'info',
            WRITE_TO_STORAGE: 'primary',
            CLEAR_STORAGE: 'danger',
            CHECK_FILES_EXIST: 'info',
            DELETE_FILE_ISNOT_EXIST: 'warning',
            FILE_DELETED: 'danger',
            DELETE_ALL_DIRECTORY: 'danger',
            default: 'secondary'
        },
        PLAYLISTS: {
            GET_PLAYLIST: 'info',
            PLAYLIST_ERROR: 'danger',
            START: 'success',
            DOWNLOAD: 'primary',
            STOP: 'danger',
            PLAYLIST_CHANGE: 'warning',
            ITEM_CHANGE: 'info',
            default: 'primary'
        },
        DOWNLOAD_MANAGER: {
            START: 'success',
            STOP: 'danger',
            PERMISSION: 'warning',
            default: 'info'
        },
        NETWORK: {
            STATUS: 'info',
            default: 'primary'
        },
        ERROR: {
            default: 'danger'
        }
    };

    const categoryColors = colors[category];
    if (!categoryColors) return 'secondary';

    return categoryColors[action] || categoryColors.default || 'secondary';
};

const formatEventName = (eventType) => {
    if (!eventType) return 'Unknown Event';

    // Split the type (e.g., "PLAYER_START" -> ["PLAYER", "START"])
    const [category, ...rest] = eventType.split('_');

    // Format the rest of the type
    const formattedType = rest
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');

    return `${EVENT_CATEGORIES[category] || category} - ${formattedType}`;
};

// Add a function to format payload data based on event type
const formatPayloadData = (type, payload) => {
    if (!payload) return '';

    const [category, action] = type.toLowerCase().split('_');

    switch (category) {
        case 'app':
            switch (action) {
                case 'start':
                case 'createddeviceid':
                    return `v${payload.version}`;
                case 'getdeviceid':
                    return `ID: ${payload.id}`;
                case 'activated':
                    return payload.activated ? 'Activated' : 'Not Activated';
                case 'checkactivated':
                    return payload.error ? `Error: ${payload.error}` : 'Checked';
                default:
                    return '';
            }

        case 'device':
            switch (action) {
                case 'orientation':
                case 'devicechange':
                    return `Orientation: ${payload.orientation}`;
                case 'readfromstorage':
                case 'writetostorage':
                    return `Key: ${payload.key}`;
                case 'checkfilesexist':
                case 'deletefileisnotexist':
                case 'filedeleted':
                    return `Files: ${payload.pathArray?.length || 0}`;
                default:
                    return '';
            }

        case 'playlists':
            switch (action) {
                case 'download':
                case 'playlistchange':
                    return `Playlist: ${payload.playlist}`;
                case 'itemchange':
                    return `Item: ${payload.item?.split('/').pop() || ''}`;
                case 'playlistError':
                    return `Error: ${payload.error}`;
                default:
                    return payload.version ? `v${payload.version}` : '';
            }

        case 'player':
            switch (action) {
                case 'play':
                    return `File: ${payload.name?.split('/').pop() || ''}`;
                case 'changeitem':
                    return `Item: ${payload.item?.split('/').pop() || ''}`;
                case 'start':
                case 'loading':
                    return `v${payload.version}`;
                default:
                    return '';
            }

        case 'downloadmanager':
            switch (action) {
                case 'start':
                    return `File: ${payload.fileUrl?.split('/').pop() || ''}`;
                case 'stop':
                case 'permission':
                    return payload.error ? `Error: ${payload.error}` : payload.status;
                default:
                    return '';
            }

        case 'network':
            if (action === 'status') {
                return `${payload.isConnected ? 'Connected' : 'Disconnected'} (${payload.name})`;
            }
            return '';

        case 'error':
            return payload.error;

        default:
            return '';
    }
};

const Analytics = () => {
    const [userState] = useDevice();
    const { device: devices } = userState;
    const [deviceStatuses, setDeviceStatuses] = useState({});
    const [deviceStatus, setDeviceStatus] = useState({
        online: 0,
        offline: 0,
        total: 0
    });
    const [eventCounts, setEventCounts] = useState({});
    const [recentLogs, setRecentLogs] = useState([]);
    const [logs, setLogs] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { state, dispatch } = useContext(DeviceContext);
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    // Add new state variables for Event Summary pagination
    const [eventPage, setEventPage] = useState(1);
    const eventsPerPage = 5;

    // Add function to handle event pagination
    const handleEventPageChange = (pageNumber) => {
        setEventPage(pageNumber);
    };

    const getImage = async (deviceUuid, open) => {
        try {
            const res = await axios.post(getImageFromDevice(deviceUuid), {
                openOrClose: open,
            });
            setDeviceStatuses(prev => ({
                ...prev,
                [deviceUuid]: res.data.success
            }));
        } catch (error) {
            setDeviceStatuses(prev => ({
                ...prev,
                [deviceUuid]: false
            }));
            console.log("error", error);
        }
    };

    // Initialize device statuses
    useEffect(() => {
        console.log("devices", devices);
        if (!devices || !devices.length) return;

        devices.forEach(device => {
            getImage(device.deviceuuid, true);
        });

        return () => {
            devices.forEach(device => {
                getImage(device.deviceuuid, false);
            });
        };
    }, [devices]);


    // Update device status counts
    useEffect(() => {
        console.log('devices', devices);
        if (!devices) return;

        const onlineCount = Object.values(deviceStatuses).filter(status => status).length;
        setDeviceStatus({
            online: onlineCount,
            offline: devices.length - onlineCount,
            total: devices.length
        });
    }, [deviceStatuses, devices]);

    useEffect(() => {
        // Simulated event counts data
        setEventCounts({
            'app.start': 156,
            'app.stop': 148,
            'app.connected': 302,
            'app.webSocketConnected': 289,
            'device.start': 145,
            'device.deviceChange': 78,
            'device.clearStorage': 12,
            'playlists.start': 234,
            'playlists.download': 189,
            'playlists.stop': 178,
            'playlists.playlistError': 23,
            'player.start': 567,
            'player.play': 1240,
            'player.stop': 1235,
            'player.loading': 890,
            'network.status': 302,
            'error.default': 45
        });

        // Set sample logs
        setRecentLogs([
            {
                _id: "1",
                deviceId: {
                    _id: "device1",
                    deviceuuid: "device123",
                    label: "Device 1"
                },
                type: "APP_START",
                payload: {
                    time: new Date().toISOString(),
                    version: "1.0.0"
                },
                timestamp: new Date().toISOString()
            },
            {
                _id: "2",
                deviceId: {
                    _id: "device1",
                    deviceuuid: "device123",
                    label: "Device 1"
                },
                type: "PLAYER_START",
                payload: {
                    time: new Date().toISOString(),
                    version: "1.0.0"
                },
                timestamp: new Date().toISOString()
            },
            {
                _id: "3",
                deviceId: {
                    _id: "device1",
                    deviceuuid: "device123",
                    label: "Device 1"
                },
                type: "PLAYLISTS_DOWNLOAD",
                payload: {
                    time: new Date().toISOString(),
                    version: "1.0.0",
                    playlist: "playlist1"
                },
                timestamp: new Date().toISOString()
            },
            {
                _id: "4",
                deviceId: {
                    _id: "device1",
                    deviceuuid: "device123",
                    label: "Device 1"
                },
                type: "DEVICE_CLEAR_STORAGE",
                payload: {
                    time: new Date().toISOString()
                },
                timestamp: new Date().toISOString()
            },
            {
                _id: "5",
                deviceId: {
                    _id: "device1",
                    deviceuuid: "device123",
                    label: "Device 1"
                },
                type: "NETWORK_STATUS",
                payload: {
                    time: new Date().toISOString(),
                    isConnected: true,
                    name: "WiFi"
                },
                timestamp: new Date().toISOString()
            }
        ]);
    }, []);

    const fetchLogs = async (pageNumber = 1) => {
        if (!state.companyId) return;
        setLoading(true);
        try {
            const response = await axios.get(`/devices/company-logs/${state.companyId}`, {
                params: {
                    limit: itemsPerPage,
                    skip: (pageNumber - 1) * itemsPerPage,
                },
            });

            // Transform the API response to match our expected format
            const transformedLogs = response.data.data.map(log => ({
                ...log,
                category: log.category?.toUpperCase(),
                type: log.type?.toLowerCase(),
                deviceId: {
                    label: log.deviceId?.label || log.deviceId?.deviceuuid,
                    deviceuuid: log.deviceId?.deviceuuid
                }
            }));

            setLogs(transformedLogs);
            setTotalCount(response.data.totalCount);
        } catch (err) {
            setError('Failed to fetch logs');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
        fetchLogs(pageNumber);
    };

    useEffect(() => {
        console.log('companyID', state.companyId);
        if (state.companyId) {
            fetchLogs(1);
        }
    }, [state.companyId]);

    return (
        <Container fluid className="py-4">
            <h1 className="mb-4">Analytics Dashboard</h1>

            <GridContainer>
                <StyledCard>
                    <CardHeader>
                        <CardIcon>
                            <FontAwesomeIcon icon={faDesktop} size="2x" color="#ffffff" />
                        </CardIcon>
                        <CardTitle>Device Status</CardTitle>
                    </CardHeader>
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <div>
                                <h3 className="mb-0">{Object.values(deviceStatuses).filter(status => status).length}</h3>
                                <p className="text-success mb-0">Online</p>
                            </div>
                            <div>
                                <h3 className="mb-0">{Object.values(deviceStatuses).filter(status => !status).length}</h3>
                                <p className="text-danger mb-0">Offline</p>
                            </div>
                            <div>
                                <h3 className="mb-0">{Object.keys(deviceStatuses).length}</h3>
                                <p className="text-muted mb-0">Total</p>
                            </div>
                        </div>
                        <ProgressBar className="mt-4">
                            <ProgressBar variant="success" now={(deviceStatus.online / deviceStatus.total) * 100} key={1} />
                            <ProgressBar variant="danger" now={(deviceStatus.offline / deviceStatus.total) * 100} key={2} />
                        </ProgressBar>
                    </Card.Body>
                </StyledCard>

                <StyledCard>
                    <CardHeader>
                        <CardIcon>
                            <FontAwesomeIcon icon={faChartBar} size="2x" color="#ffffff" />
                        </CardIcon>
                        <CardTitle>Event Summary</CardTitle>
                    </CardHeader>
                    <Card.Body>
                        <Table responsive className="mt-3">
                            <thead>
                                <tr>
                                    <th>Event Type</th>
                                    <th>Count</th>
                                    <th>Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(eventCounts)
                                    .sort(([, a], [, b]) => b - a) // Sort by count in descending order
                                    .slice((eventPage - 1) * eventsPerPage, eventPage * eventsPerPage)
                                    .map(([eventKey, count]) => {
                                        const [category, eventType] = eventKey.split('.');
                                        return (
                                            <tr key={eventKey}>
                                                <td>
                                                    <Badge
                                                        bg={getEventTypeColor(eventType)}
                                                        style={{
                                                            padding: '8px 12px',
                                                            fontSize: '0.9rem',
                                                            fontWeight: '500',
                                                            textTransform: 'none'
                                                        }}
                                                    >
                                                        {formatEventName(eventType)}
                                                    </Badge>
                                                </td>
                                                <td>{count}</td>
                                                <td>
                                                    <ProgressBar
                                                        now={(count / Math.max(...Object.values(eventCounts))) * 100}
                                                        label={`${((count / Math.max(...Object.values(eventCounts))) * 100).toFixed(1)}%`}
                                                        variant={getEventTypeColor(eventType)}
                                                        style={{ height: '20px' }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="text-muted">
                                Showing {Math.min(((eventPage - 1) * eventsPerPage) + 1, Object.keys(eventCounts).length)} to{' '}
                                {Math.min(eventPage * eventsPerPage, Object.keys(eventCounts).length)} of{' '}
                                {Object.keys(eventCounts).length} events
                            </div>
                            <Pagination>
                                <Pagination.First
                                    onClick={() => handleEventPageChange(1)}
                                    disabled={eventPage === 1}
                                />
                                <Pagination.Prev
                                    onClick={() => handleEventPageChange(eventPage - 1)}
                                    disabled={eventPage === 1}
                                />

                                {Array.from({ length: Math.ceil(Object.keys(eventCounts).length / eventsPerPage) }, (_, i) => i + 1)
                                    .filter(num =>
                                        num === 1 ||
                                        num === Math.ceil(Object.keys(eventCounts).length / eventsPerPage) ||
                                        (num >= eventPage - 1 && num <= eventPage + 1)
                                    )
                                    .map((num) => (
                                        <Pagination.Item
                                            key={num}
                                            active={num === eventPage}
                                            onClick={() => handleEventPageChange(num)}
                                        >
                                            {num}
                                        </Pagination.Item>
                                    ))
                                }

                                <Pagination.Next
                                    onClick={() => handleEventPageChange(eventPage + 1)}
                                    disabled={eventPage >= Math.ceil(Object.keys(eventCounts).length / eventsPerPage)}
                                />
                                <Pagination.Last
                                    onClick={() => handleEventPageChange(Math.ceil(Object.keys(eventCounts).length / eventsPerPage))}
                                    disabled={eventPage >= Math.ceil(Object.keys(eventCounts).length / eventsPerPage)}
                                />
                            </Pagination>
                        </div>
                    </Card.Body>
                </StyledCard>

                <FullWidthCard as={StyledCard}>
                    <CardHeader>
                        <CardIcon>
                            <FontAwesomeIcon icon={faClipboardList} size="2x" color="#ffffff" />
                        </CardIcon>
                        <CardTitle>Recent Logs</CardTitle>
                    </CardHeader>
                    <Card.Body>
                        {loading ? (
                            <p>Loading logs...</p>
                        ) : error ? (
                            <p>{error}</p>
                        ) : (
                            <>
                                <Table responsive striped hover className="mt-3">
                                    <thead>
                                        <tr>
                                            <th>Device</th>
                                            <th>Event Type</th>
                                            <th>Timestamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logs.map((log) => (
                                            <tr key={log._id}>
                                                <td>{log.deviceId.label || log.deviceId.deviceuuid}</td>
                                                <td>
                                                    <Badge
                                                        bg={getEventTypeColor(log.type)}
                                                        style={{
                                                            padding: '8px 12px',
                                                            fontSize: '0.9rem',
                                                            fontWeight: '500',
                                                            textTransform: 'none'
                                                        }}
                                                    >
                                                        {formatEventName(log.type)}
                                                    </Badge>
                                                    {log.payload && (
                                                        <small className="d-block text-muted mt-1">
                                                            {formatPayloadData(log.type, log.payload)}
                                                        </small>
                                                    )}
                                                </td>
                                                <td>{new Date(log.timestamp).toLocaleString()}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <div className="text-muted">
                                        Showing {Math.min(((page - 1) * itemsPerPage) + 1, totalCount)} to {Math.min(page * itemsPerPage, totalCount)} of {totalCount} entries
                                    </div>
                                    <Pagination>
                                        <Pagination.First
                                            onClick={() => handlePageChange(1)}
                                            disabled={page === 1}
                                        />
                                        <Pagination.Prev
                                            onClick={() => handlePageChange(page - 1)}
                                            disabled={page === 1}
                                        />

                                        {Array.from({ length: Math.ceil(totalCount / itemsPerPage) }, (_, i) => i + 1)
                                            .filter(num =>
                                                num === 1 ||
                                                num === Math.ceil(totalCount / itemsPerPage) ||
                                                (num >= page - 1 && num <= page + 1)
                                            )
                                            .map((num) => (
                                                <Pagination.Item
                                                    key={num}
                                                    active={num === page}
                                                    onClick={() => handlePageChange(num)}
                                                >
                                                    {num}
                                                </Pagination.Item>
                                            ))
                                        }

                                        <Pagination.Next
                                            onClick={() => handlePageChange(page + 1)}
                                            disabled={page >= Math.ceil(totalCount / itemsPerPage)}
                                        />
                                        <Pagination.Last
                                            onClick={() => handlePageChange(Math.ceil(totalCount / itemsPerPage))}
                                            disabled={page >= Math.ceil(totalCount / itemsPerPage)}
                                        />
                                    </Pagination>
                                </div>
                            </>
                        )}
                    </Card.Body>
                </FullWidthCard>
            </GridContainer>
        </Container>
    );
};

export default Analytics;
