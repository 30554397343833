import React from "react";
import { Row, Col } from "react-bootstrap";
import { Image } from "./image";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Gallery = (props) => {
  const { t } = useTranslation();

  const galleryTitle = t("description.gallery");
  const galleryDescription = t("description.gallerydescription");
  const canonicalUrl = "https://yourdomain.com/digital-signage-gallery";

  return (
    <section id="gallery" className="text-center">
      <Helmet>
        <title>{`${galleryTitle} - Digital Signage Solutions | DFN Player`}</title>
        <meta name="description" content={`${galleryDescription} Explore our innovative digital signage projects and solutions.`} />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:title" content={`${galleryTitle} - Digital Signage Portfolio | DFN Player`} />
        <meta property="og:description" content={`${galleryDescription} View our cutting-edge digital signage implementations.`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="https://yourdomain.com/images/digital-signage-gallery-preview.jpg" />
      </Helmet>
      <div className="container">
        <h1 className="section-title">{galleryTitle}</h1>
        <p className="lead mb-4">{galleryDescription}</p>
        <Row>
          {props.data && props.data.length > 0 ? (
            props.data.map((d, i) => (
              <Col key={`${d.title}-${i}`} lg={4} md={6} sm={12} className="mb-4">
                <article className="gallery-item">
                  <Image
                    title={t(d.title)}
                    largeImage={d.largeImage}
                    smallImage={d.smallImage}
                    alt={`${t(d.title)} - Digital Signage Solution by DFN Player`}
                    loading="lazy"
                    width="300"
                    height="200"
                  />
                  <h2 className="h4 mt-3">{t(d.title)}</h2>
                  <p>{t(d.description)}</p>

                </article>
              </Col>
            ))
          ) : (
            <Col>
              <p>We're updating our digital signage portfolio. Please check back soon to see our latest innovative projects.</p>
            </Col>
          )}
        </Row>
      </div>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          "name": galleryTitle,
          "description": galleryDescription,
          "url": canonicalUrl,
          "mainEntity": {
            "@type": "ItemList",
            "itemListElement": props.data ? props.data.map((d, index) => ({
              "@type": "ListItem",
              "position": index + 1,
              "item": {
                "@type": "ImageObject",
                "name": t(d.title),
                "description": t(d.description),
                "contentUrl": d.largeImage,
                "thumbnailUrl": d.smallImage
              }
            })) : []
          }
        })}
      </script>
    </section>
  );
};
