import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import validator from "validator";
import { useTranslation } from 'react-i18next';
import axios from "../../api/axios";
import { registerRoute } from "../../api/routes";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import "./Register.scss";

const CustomButton = styled(Button)`
  background-color: #cd204f;
  border-color: #cd204f;
  
  &:hover, &:focus, &:active {
    background-color: #b51c45 !important;
    border-color: #b51c45 !important;
  }

  &:disabled {
    background-color: #cd204f;
    border-color: #cd204f;
    opacity: 0.65;
  }
`;

const Register = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const { t } = useTranslation();
  const [formValid, setFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();

  const clearError = (setter) => {
    setter("");
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    clearError(setNameError);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    clearError(setemailError);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    clearError(setpasswordError);
  };

  const handleValidation = (event) => {
    let formIsValid = true;

    if (!validator.isAlpha(name.replace(/\s/g, ''))) {
      formIsValid = false;
      setNameError(t('description.namenotvalid'));
    } else {
      setNameError("");
    }

    if (!validator.isEmail(email)) {
      formIsValid = false;
      setemailError(t('description.emailnotvalid'));
    } else {
      setemailError("");
    }

    if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      formIsValid = false;
      setpasswordError(t('description.passworderror'));
    } else {
      setpasswordError("");
    }

    return formIsValid;
  };

  const loginSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (isSubmitting) return;

    setIsSubmitting(true);

    const isValid = handleValidation();
    if (isValid) {
      try {
        await axios.post(registerRoute, {
          name: name,
          email: email,
          password: password,
        });

        toast.success(t('description.registersuccess'));
        history.replace("/login");
      } catch (error) {
        console.error("Register error:", error);
        toast.error(error.response?.data?.msg || error.message);
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && name && email && password) {
        loginSubmit();
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [name, email, password]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <Card className="shadow">
            <Card.Body>
              <h3 className="text-center mb-4">{t('description.register')}</h3>
              <Form id="registerform" onSubmit={loginSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('description.name')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t('description.entername')}
                    onChange={handleNameChange}
                    value={name}
                  />
                  <Form.Text className="text-danger">
                    {nameError}
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t('description.entermail')}</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t('description.enteremail')}
                    onChange={handleEmailChange}
                    value={email}
                  />
                  <Form.Text className="text-danger">
                    {emailError}
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t('description.password')}</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t('description.enterpassword')}
                    onChange={handlePasswordChange}
                  />
                  <Form.Text className="text-danger">
                    {passwordError}
                  </Form.Text>
                </Form.Group>
                <div className="d-grid">
                  <CustomButton
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? t('description.registering') : t('description.submit')}
                  </CustomButton>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
