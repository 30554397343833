import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col, Form, Card, Modal, ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faTrash,
  faPlayCircle,
  faImagePortrait,
  faFile,
  faVideo,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import CropImageModal from "./CropImageModal";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { deleteMadia } from "../api/routes";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import FilerobotImageEditor from 'react-filerobot-image-editor';

const MediaCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`;

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 15px;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const MediaHeader = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  display: flex;
  align-items: center;
  min-height: 180px;
`;

const MediaIcon = styled.div`
  width: 80px;
  height: 80px;
  min-width: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cd204f;
`;

const MediaInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MediaName = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MediaDetail = styled.p`
  display: flex;
  align-items: center;
  color: #6c757d;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
`;

const ActionButton = styled(Button)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;

  svg {
    margin-right: 0.25rem;
  }
`;

function GridViewComponent({ data, getUserInfoHandler }) {
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [orderByDate, setOrderByDate] = useState("decreasing");
  const [hoveredItem, setHoveredItem] = useState(null);
  const [videoPopupUrl, setVideoPopupUrl] = useState(null);

  const [showImageEditor, setShowImageEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [itemId, setItemId] = useState(null);

  const handleImageEdit = (imageUrl, id) => {
    console.log("handleImageEdit", imageUrl, id);
    setSelectedImage(imageUrl);
    setItemId(id);
    setShowImageEditor(true);
  };

  const handleSaveImage = async (editedImageObject) => {
    try {
        const { imageBase64 } = editedImageObject;

        // Create a FormData object
        const formData = new FormData();
        // Convert the base64 image to a Blob
        const response = await fetch(imageBase64);
        const blob = await response.blob();
        // Append the Blob to the FormData
        

       
        formData.append("files", blob, selectedImage.split("/").pop() || "edited-image.png"); // You can change the filename as needed

        // Update media with new image
        await axios.post(`/media/uploadMedia`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        toast.success("Image updated successfully");
        getUserInfoHandler();
        setShowImageEditor(false);
        //setSelectedImage(null);
    } catch (error) {
        console.error("Error saving image:", error);
        toast.error(error.response?.data?.message || "Error saving image");
    }
};

  useEffect(() => {
    filterData(searchValue, orderByDate);
  }, [data, searchValue, orderByDate]);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);
  };

  const handleDateChange = (event) => {
    const value = event.target.value;
    setOrderByDate(value);
  };

  const filterData = (searchValue, orderByDate) => {
    let filtered = data;

    // Filter by name
    if (searchValue) {
      filtered = data.filter((item) =>
        item.fileName.toLowerCase().includes(searchValue)
      );
    }

    // Order by date
    if (orderByDate === "increasing") {
      filtered.sort((a, b) => new Date(a.date) - new Date(b.date));
    } else {
      filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
    }

    setFilteredData(filtered);
  };

  const handleItemHover = (itemId) => {
    setHoveredItem(itemId);
  };

  const closeImageEditor = () => {
    setShowImageEditor(false);
    setSelectedImage(null);
  };

  const handleVideoClick = (videoUrl) => {
    setVideoPopupUrl(videoUrl);
  };
  const handleDelete = async (id) => {
    console.log("deleteMadia", deleteMadia(id));

    const path = deleteMadia(id);
    try {
      const response = await axios.post(path, { mediaId: id });
      console.log("response delete", response);
      toast.info(response?.data?.message);
      setShowModal(false);
      setItemId(null);
      getUserInfoHandler();
    } catch (error) {
      toast.info(error);
    }
  };

  const closeVideoPopup = () => {
    setVideoPopupUrl(null);
  };

  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setItemId(null);
  };
  

  return (
    <div>
      <DeleteConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirmDelete={() => handleDelete(itemId)}
        loading={deleting}
        title="Delete Media"
        body="Are you sure you want to delete this media?"
      />
      
      
      <Modal show={!!videoPopupUrl} onHide={closeVideoPopup}>
        <Modal.Body style={{ padding: "0" }}>
          <video
            src={videoPopupUrl}
            alt="Video Popup"
            controls
            crossOrigin="anonymous"
            autoPlay
            style={{ width: "100%", maxHeight: "90vh" }}
          />
        </Modal.Body>
      </Modal>
      {showImageEditor && selectedImage ? (
        <div style={{position: "absolute", top: 50, left: 290, right: 150, bottom: 50, background: "black"}}>
          
          <FilerobotImageEditor
            source={selectedImage}
            onSave={handleSaveImage}
            
            onClose={() => {
              console.log("onClose");
              setShowImageEditor(false);
              
            }}
            defaultSavedImageType="png"
            fileName={itemId}
            avoidChangesNotSavedAlertOnLeave={true}
            defaultSavedImageQuality={1}
            onChange={(editedImageObject) => {
              // Trigger save when the image is changed
              handleSaveImage(editedImageObject);
            }}
            closeAfterSave={true}
            Crop={{
              presetsItems: [
                {
                  titleKey: 'classicHd',
                  descriptionKey: 'Classic HD (16:9)',
                  ratio: 16 / 9,
                  width: 1280,
                  height: 720,
                },
                {
                  titleKey: 'fullHd',
                  descriptionKey: 'Full HD (16:9)',
                  ratio: 16 / 9,
                  width: 1920,
                  height: 1080,
                },
                {
                  titleKey: 'ultraHd',
                  descriptionKey: 'Ultra HD (4K)',
                  ratio: 16 / 9,
                  width: 3840,
                  height: 2160,
                },
                {
                  titleKey: '2K',
                  descriptionKey: '2K (2048x1080)',
                  ratio: 2048 / 1080,
                  width: 2048,
                  height: 1080,
                },
                {
                  titleKey: '4K',
                  descriptionKey: '4K (3840x2160)',
                  ratio: 3840 / 2160,
                  width: 3840,
                  height: 2160,
                },
              ],
            }}
            annotationsCommon={{
              fill: '#ff0000'
            }}
            Text={{ text: 'Add text here...' }}
            Rotate={{ angle: 90, componentType: 'slider' }}
          
            tabsIds={[
              'Adjust',
              'Annotate',
              'Watermark',
              'Filters',
              'Finetune',
              'Resize',
              'Rotate'
            ]}
            defaultTabId="Adjust"
            defaultToolId="Crop"
          />
          
        </div>
      ):
      <>  
      <Row>
        <Col>
          <Form.Group controlId="formSearch">
            <Form.Control
              type="text"
              placeholder="Search by name"
              value={searchValue}
              onChange={handleSearchChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formDate">
            <Form.Control as="select" onChange={handleDateChange}>
              <option value="decreasing">Sort by Date (Decreasing)</option>
              <option value="increasing">Sort by Date (Increasing)</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <MediaCardContainer>
        {filteredData.map((item) => (
          <MediaCard
            key={item._id}
            item={item}
            onDelete={() => {
              setShowModal(true);
              setItemId(item._id);
            }}
            onPlay={() => handleVideoClick(item.downloadUrl)}
            onEdit={() => handleImageEdit(item.downloadUrl, item._id)}
          />
          ))}
        </MediaCardContainer>
        </>
      }
    </div>
  );
}

const MediaCard = ({ item, onDelete, onPlay, onEdit }) => {
  const [showImageModal, setShowImageModal] = useState(false);

  const getMediaIcon = (type) => {
    if (type.startsWith("image")) return faImage;
    if (type.startsWith("video")) return faVideo;
    return faFile;
  };

  const handleImageClick = () => {
    if (item.type.startsWith("image")) {
      setShowImageModal(true);
    }
  };

  return (
    <>
      <StyledCard>
        <MediaHeader>
          <MediaIcon>
            <FontAwesomeIcon icon={getMediaIcon(item.type)} size="3x" color="#ffffff" />
          </MediaIcon>
          <MediaInfo>
            <MediaName title={item.fileName}>{item.fileName}</MediaName>
            {item.date && (
              <MediaDetail>
                <FontAwesomeIcon icon={faSort} style={{ marginRight: '0.5rem' }} />
                {new Date(item.date).toLocaleDateString()}
              </MediaDetail>
            )}
            <MediaDetail>
              <strong>Type:</strong> {item.type.split("/")[0]}
            </MediaDetail>
            {item.fileSizeMB && (
              <MediaDetail>
                <strong>Size:</strong> {item.fileSizeMB.toFixed(2)} MB
              </MediaDetail>
            )}
          </MediaInfo>
        </MediaHeader>
        <Card.Body>
          {item.type.startsWith("image") ? (
            <img
              src={item.downloadUrl}
              alt={item.fileName}
              style={{
                width: "100%",
                height: "150px",
                objectFit: "cover",
                marginBottom: "1rem",
                cursor: "pointer",
              }}
              crossOrigin="anonymous"
              onClick={handleImageClick}
            />
          ) : (
            <video
              src={item.downloadUrl}
              alt={item.fileName}
              style={{
                width: "100%",
                height: "150px",
                objectFit: "cover",
                marginBottom: "1rem",
              }}
              crossOrigin="anonymous"
            />
          )}
          <ButtonGroup className="w-100">
            <ActionButton
              variant="outline-danger"
              onClick={onDelete}
            >
              <FontAwesomeIcon icon={faTrash} /> Delete
            </ActionButton>
            {item.type.startsWith("video") && (
              <ActionButton
                variant="outline-primary"
                onClick={onPlay}
              >
                <FontAwesomeIcon icon={faPlayCircle} /> Play
              </ActionButton>
            )}
            <ActionButton
              variant="outline-secondary"
              onClick={onEdit}
            >
              <FontAwesomeIcon icon={faImage} /> Edit Image
            </ActionButton>
          </ButtonGroup>
        </Card.Body>
      </StyledCard>

      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} size="xl" centered>
        <Modal.Body className="p-0">
          <img
            src={item.downloadUrl}
            alt={item.fileName}
            style={{ width: "100%", height: "auto" }}
            crossOrigin="anonymous"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GridViewComponent;
