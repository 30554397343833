import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCode
} from "@fortawesome/free-solid-svg-icons";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { deleteWidget } from "../api/routes";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const WidgetCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`;

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 15px;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const WidgetHeader = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  display: flex;
  align-items: center;
  min-height: 100px;
`;

const WidgetIcon = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4a90e2;
`;

const WidgetInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const WidgetName = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WidgetType = styled.p`
  color: #6c757d;
  margin-bottom: 0;
  font-size: 0.9rem;
`;

const ActionButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-top: 1rem;

  svg {
    margin-right: 0.5rem;
  }
`;

function MyWidgetGrid({ data, getUserInfoHandler }) {
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [itemId, setItemId] = useState(null);

  const handleCloseModal = () => {
    setShowModal(false);
    setItemId(null);
  };

  const handleDelete = async (id) => {
    setDeleting(true);
    try {
      const response = await axios.delete(deleteWidget, { data: { id: id } });
      toast.success(response?.data?.message || "Widget deleted successfully");
      getUserInfoHandler();
      setShowModal(false);
      setItemId(null);
    } catch (error) {
      setShowModal(false);
      setItemId(null);
      console.error("Error deleting widget:", error);
      toast.error(error.response?.data?.message || "Error deleting widget");
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Container>
      <DeleteConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirmDelete={() => handleDelete(itemId)}
        loading={deleting}
        title="Delete Widget"
        body="Are you sure you want to delete this widget?"
      />
      <WidgetCardContainer>
        {data.map((item) => (
          <StyledCard key={item._id}>
            <WidgetHeader>
              <WidgetIcon>
                <FontAwesomeIcon icon={faCode} size="2x" color="#ffffff" />
              </WidgetIcon>
              <WidgetInfo>
                <WidgetName title={item.name}>{item.name}</WidgetName>
                <WidgetType>Widget</WidgetType>
              </WidgetInfo>
            </WidgetHeader>
            <Card.Body>
              <iframe
                srcDoc={item.html}
                title="RSS Feed Preview"
                style={{
                  width: '100%',
                  height: '200px',
                  border: 'none',
                  overflow: 'hidden'
                }}
              />
              <ActionButton
                variant="outline-danger"
                onClick={() => {
                  setShowModal(true);
                  setItemId(item._id);
                }}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete
              </ActionButton>
            </Card.Body>
          </StyledCard>
        ))}
      </WidgetCardContainer>
    </Container>
  );
}

export default MyWidgetGrid;
