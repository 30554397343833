import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import axios from "../api/axios";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import {
  changePasswordRoute,
  companyList,
  list,
  updateCompany,
} from "../api/routes";
import CreateNewCompnayPopup from "./CreateNewCompanyPopup";
import FileUploadModal from "./FileUploadModal";
function FloatingActionButton({
  onClick,
  userId,
  type,
  onSuccess,
  accountType,
}) {
  const [showModal, setShowModal] = useState(false);
  const handleButtonClick = () => {
    setShowModal(true);
    if (onClick) {
      onClick();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button
        className="floating-action-button"
        onClick={handleButtonClick}
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: "black",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
        }}
      >
        <FontAwesomeIcon icon={faPlus} size="lg" />
      </Button>
      {type === "createcompany" ? (
        accountType === "b2c" ? (
          showModal ? (
            alert("only B2C account create branch or another company")
          ) : null
        ) : (
          <CreateNewCompnayPopup
            userId={userId}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
          />
        )
      ) : type === "mymedia" ? (
        <FileUploadModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          onSuccess={onSuccess}
        />
      ) : null}
    </>
  );
}

export default FloatingActionButton;
