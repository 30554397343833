import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Card,
  Container,
  Button,
  ButtonGroup,
  Row,
  Col,
} from "react-bootstrap";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { deleteFilter, getAllFilters } from "../api/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEdit,
  faCalendar,
  faClock,
  faPlay,
  faPause,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import FilterModal from "./FilterModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
const dayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const FilterCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
`;

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 15px;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const FilterHeader = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  min-height: 180px; // Set a minimum height
`;

const FilterIcon = styled.div`
  width: 80px;
  height: 80px;
  min-width: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cd204f; // Updated to match sidebar color
`;

const FilterInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 140px; // Ensure minimum height for content
`;

const FilterName = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FilterDetail = styled.p`
  display: flex;
  align-items: center;
  color: #6c757d;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
`;

const DaysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
`;

const DayTag = styled.span`
  background-color: #e9ecef;
  color: #495057;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-bottom: 4px;
`;

const ActionButton = styled(Button)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;

  svg {
    margin-right: 0.25rem;
  }
`;

const IconWrapper = styled.span`
  color: #cd204f;
  margin-right: 0.5rem;
`;

const UserFilterList = ({refresh}) => {
  const [filters, setFilters] = useState([]);
  const [getAgainFilter, setAgainFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    // Fetch all user filters from the backend API
    axios
      .get(getAllFilters)
      .then((response) => {
        if (response.data.success) {
          setFilters(response.data.filters);
        }
      })
      .catch((error) => {
        console.error("Error fetching filters:", error);
      });
  }, [getAgainFilter,refresh]);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (filter) => {
    setShowModal(true);
  };

  const handleCloseModal = (status) => {
    if (status) setAgainFilter(!getAgainFilter);
    setShowModal(false);
    setSelectedFilter(null);
  };

  const handleDelete = (filterId) => {
    // Implement the logic to delete the filter with the given ID
    // You can make a DELETE request to the backend API
    // and update the state after successful deletion
    console.log("Delete filter with ID:", filterId);
    axios
      .delete(deleteFilter(filterId))
      .then((response) => {
        if (response.data.success) {
          console.log("delete filter", response.data);
          setFilters(response.data.data);
        }
        setShowDeleteModal(false);
        setItemId(null);
      })
      .catch((error) => {
        console.error("Error fetching filters:", error);
          setShowDeleteModal(false);
        setItemId(null);
      });

  };

  const handleEdit = (filter) => {
    // Implement the logic to edit the filter with the given ID
    // You can navigate to an edit page or show a modal for editing
    console.log("Edit filter with ID:", filter);
    setSelectedFilter(filter);
    handleOpenModal(filter);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [itemId, setItemId] = useState(null);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setItemId(null);
  };
  return (
    <div >
        <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirmDelete={()=>handleDelete(itemId)}
        loading={deleting}
        title="Delete Widget"
        body="Are you sure you want to delete this widget?"
      />
      {showModal && (
        <FilterModal
          show={showModal}
          handleClose={handleCloseModal}
          selectedFilter={selectedFilter}
        />
      )}
      <FilterCardContainer>
        {filters.length === 0 ? (
          <div style={{ gridColumn: "1 / -1", textAlign: "center" }}>
            <h2 style={{ color: "#ffffff" }}>No filters found</h2>
            <h3 style={{ color: "#cccccc" }}>Click the button on the left bottom to add a new filter</h3>
          </div>
        ) : (
          filters.map((filter) => (
            <FilterCard
              key={filter._id}
              filter={filter}
              onDelete={() => {
                setShowDeleteModal(true);
                setItemId(filter._id);
              }}
              onEdit={() => handleEdit(filter)}
            />
          ))
        )}
      </FilterCardContainer>
    </div>
  );
};

const FilterCard = ({ filter, onDelete, onEdit }) => {
  return (
    <StyledCard>
      <FilterHeader>
        <FilterIcon>
          <FontAwesomeIcon icon={faFilter} size="3x" color="#ffffff" />
        </FilterIcon>
        <FilterInfo>
          <div>
            <FilterName title={filter.label}>{filter.label}</FilterName>
            <FilterDetail>
              <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '0.5rem' }} />
              Selected days:
            </FilterDetail>
            <DaysContainer>
              {filter.selectedDays.map((dayIndex) => (
                <DayTag key={dayIndex}>{dayNames[dayIndex]}</DayTag>
              ))}
            </DaysContainer>
          </div>
          <div>
            <FilterDetail>
              <FontAwesomeIcon icon={faClock} style={{ marginRight: '0.5rem' }} />
              {filter.selectedHour[0]} - {filter.selectedHour[1]}
            </FilterDetail>
          </div>
        </FilterInfo>
      </FilterHeader>
      <Card.Body>
        <FilterDetail>
          <strong>Device Label:</strong> {filter.deviceLabel}
        </FilterDetail>
        <FilterDetail>
          <FontAwesomeIcon
            icon={filter.isPlaying ? faPlay : faPause}
            style={{ marginRight: '0.5rem' }}
          />
        </FilterDetail>
        <ButtonGroup className="w-100 mt-3">
          <ActionButton
            variant="outline-danger"
            onClick={() => onDelete(filter._id)}
          >
            <FontAwesomeIcon icon={faTrashAlt} /> Delete
          </ActionButton>
          <ActionButton
            variant="outline-primary"
            onClick={() => onEdit(filter)}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit
          </ActionButton>
        </ButtonGroup>
      </Card.Body>
    </StyledCard>
  );
};

export default UserFilterList;
