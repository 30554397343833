import React, { useEffect, useState } from "react";
import "./devices.scss";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useDevice } from "../contexts/Device/DeviceState";
import {
  getDevice,
  setLoading,
  getMedia,
  getAllPlaylist,
  getWidget,
} from "../contexts/Device/DeviceAction";
import AddDeviceModal from "../components/AddDeviceModal";
import DeviceCard from "../components/DeviceCard";
import FloatingActionButton from "../components/FloatingActionButton";
import { OverlayTrigger, Popover } from "react-bootstrap";
var es;
function Dashboard(props) {
  console.log("Dashboard props", props);
  const [userState, userDispatch] = useDevice();
  const { device, loading, error, message, media, companyId } = userState;
  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);
  // get user info handler
  const getUserInfoHandler = async () => {
    if (companyId) {
      await getDevice(userDispatch, companyId);
      await getMedia(userDispatch);
      await getWidget(userDispatch);
      getAllPlaylist(userDispatch);
      setLoading(userDispatch, false);
    }
  };

  useEffect(() => {
    getUserInfoHandler();
    if (device && device.length) {
      const isProduction = process.env.NODE_ENV === "production";

      const baseURL = isProduction
        ? "https://dfnplayer.com/api/stream"
        : "http://localhost:5003/stream";
      es = new EventSource(baseURL);
      console.log("SSE instance:", es);

      es.onopen = () => {
        console.log("SSE connection opened");
      };

      es.onerror = (error) => {
        console.error("SSE error:", error);
      };

      return () => {
        console.log("Closing SSE connection");
        es?.close();
      }
    }
  }, [companyId]);

  const fabContainerStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: "1",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  };

  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus" title="Popover bottom">
      <strong>Create new device with id</strong> Click to create
    </Popover>
  );
  return (
    <div style={{ display: "flex" }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Spinner animation="grow" />
        </div>
      ) : device && device.length ? (
        <>
          {device.map((d) => (
            <Col key={d.id} xs={12} sm={6} md={4} lg={4}>
              <DeviceCard
                es={es}
                d={d}
                getDevice={() => getDevice(userDispatch, companyId)}
              />
            </Col>
          ))}
        </>
      ) : (<div style={{ display: 'flex', flexGrow: 1, justifyContent: "center", alignItems: "center", minHeight: "500px", flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
        <h1>No device found</h1>
        <h2>Watch demo youtube video how can add device</h2>
        {/*<div>

          <iframe style={{ borderRadius: '10px' }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            width={window.innerWidth > 600 ? window.innerWidth / 2 : window.innerWidth / 2}
            height={window.innerWidth > 600 ? window.innerHeight : window.innerHeight / 3}
            src="https://www.youtube.com/embed/oTJuiXqyQpo?si=21g9LW3zT2z5OWDe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>*/}
      </div>)}

      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="top"
        overlay={popoverHoverFocus}
      >
        <div style={fabContainerStyle}>
          <FloatingActionButton
            onClick={() => {
              setShowAddDeviceModal(true);
            }}
            userId={""}
          />
          <AddDeviceModal
            companyid={companyId}
            show={showAddDeviceModal}
            onHide={() => {
              getDevice(userDispatch, companyId);
              setShowAddDeviceModal(false);
            }}
          />
        </div>
      </OverlayTrigger>
    </div>
  );
}

export default Dashboard;
