import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import uploadWidget from './uploadWidget';

const ImageQRWidget = () => {
    const [url, setUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [description, setDescription] = useState('');
    const [textColor, setTextColor] = useState('#000000');
    const [fontSize, setFontSize] = useState('16');
    const [qrPosition, setQrPosition] = useState('right');
    const [scrollSpeed, setScrollSpeed] = useState(50);
    const [imageStyle, setImageStyle] = useState('fit');
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [error, setError] = useState('');
    const [iframeContent, setIframeContent] = useState('');
    const iframeRef = useRef(null);

    useEffect(() => {
        if (url) {
            fetch(url)
                .then(response => {
                    if (response.ok) {
                        setImageUrl(url);
                        setError('');
                    } else {
                        throw new Error('Invalid image URL');
                    }
                })
                .catch(() => {
                    setError('Invalid image URL. Please enter a valid image URL.');
                    setImageUrl('');
                });
        }
    }, [url]);

    useEffect(() => {
        const content = `
            <!DOCTYPE html>
            <html>
            <head>
                <style>
                    body {
                        margin: 0;
                        padding: 0;
                        font-family: Arial, sans-serif;
                        overflow: hidden;
                        background-color: ${backgroundColor};
                    }
                    .widget-container {
                        display: flex;
                        flex-direction: ${qrPosition === 'top' || qrPosition === 'bottom' ? 'column' : 'row'};
                        align-items: center;
                        justify-content: center;
                        padding: 20px;
                        height: 100vh;
                    }
                    .image-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        height: 300px;
                    }
                    .image-wrapper {
                        width: 100%;
                        height: 100%;
                        ${imageStyle === 'repeat' ? `
                            background-image: url("${imageUrl}");
                            background-repeat: repeat;
                            background-size: auto;
                        ` : imageStyle === 'fit' ? `
                            background-image: url("${imageUrl}");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                        ` : `
                            background-image: url("${imageUrl}");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                        `}
                    }
                    .description {
                        color: ${textColor};
                        font-size: ${fontSize}px;
                        text-align: center;
                        margin-top: 10px;
                    }
                    .qr-code {
                        margin: 10px;
                    }
                </style>
            </head>
            <body>
                <div class="widget-container">
                    ${qrPosition === 'top' || qrPosition === 'left' ? `
                        <div class="qr-code">
                            ${url ? `<img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(url)}" alt="QR Code" />` : ''}
                        </div>
                    ` : ''}
                    <div class="image-container">
                        <div class="image-wrapper"></div>
                        ${description ? `<p class="description">${description}</p>` : ''}
                    </div>
                    ${qrPosition === 'bottom' || qrPosition === 'right' ? `
                        <div class="qr-code">
                            ${url ? `<img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(url)}" alt="QR Code" />` : ''}
                        </div>
                    ` : ''}
                </div>
            </body>
            </html>
        `;
        setIframeContent(content);
    }, [imageUrl, url, description, textColor, fontSize, qrPosition, imageStyle, backgroundColor]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // URL validation is handled in the useEffect hook
    };

    const handleSaveHtml = () => {
        const blob = new Blob([iframeContent], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'image-qr-widget.html';
        link.click();
        uploadWidget('imageQrWidget', iframeContent);
    };

    return (
        <Container>
            <Row>
                <Col xs={12} md={4} lg={3}>
                    <Card className="mb-4">
                        <Card.Header>Image QR Widget Settings</Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="imageUrl">
                                    <Form.Label>Image URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter image URL"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="description" className="mt-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="textColor" className="mt-3">
                                    <Form.Label>Text Color</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={textColor}
                                        onChange={(e) => setTextColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="backgroundColor" className="mt-3">
                                    <Form.Label>Background Color</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={backgroundColor}
                                        onChange={(e) => setBackgroundColor(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="fontSize" className="mt-3">
                                    <Form.Label>Font Size (px)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={fontSize}
                                        onChange={(e) => setFontSize(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="qrPosition" className="mt-3">
                                    <Form.Label>QR Code Position</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={qrPosition}
                                        onChange={(e) => setQrPosition(e.target.value)}
                                    >
                                        <option value="left">Left</option>
                                        <option value="right">Right</option>
                                        <option value="top">Top</option>
                                        <option value="bottom">Bottom</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="imageStyle" className="mt-3">
                                    <Form.Label>Image Style</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={imageStyle}
                                        onChange={(e) => setImageStyle(e.target.value)}
                                    >
                                        <option value="repeat">Repeat</option>
                                        <option value="fit">Fit</option>
                                        <option value="fill">Fill</option>
                                    </Form.Control>
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-3">
                                    Widget Oluştur
                                </Button>
                                <Button variant="secondary" className="mt-3 ms-2" onClick={handleSaveHtml}>
                                    HTML Kaydet
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <Card>
                        <Card.Header>Preview</Card.Header>
                        <Card.Body style={{ padding: 0 }}>
                            {error && <Alert variant="danger" className="m-3">{error}</Alert>}
                            <iframe
                                ref={iframeRef}
                                srcDoc={iframeContent}
                                title="Image and QR Code Preview"
                                style={{
                                    width: '100%',
                                    height: '600px',
                                    border: 'none'
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ImageQRWidget;
