import React, { useState } from "react";
import Sidebar from "./Sidebar";
import NavBar from "./Navbar/Navbar";
import { useAuthContext } from "../contexts/AuthContext";
import styled from "styled-components";
function Layout(props) {
  const { isLoggedIn, setIsLoggedIn } = useAuthContext();
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <LayoutContainer>
      <div style={{display:'flex'}}>
        {isLoggedIn ? <Sidebar history={props.history} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} /> : null}
        </div>
        <MainContent isCollapsed={isCollapsed} isLoggedIn={isLoggedIn}>
          {isLoggedIn ? null : <NavBar />}
          {props.children}
        </MainContent>
    </LayoutContainer>
  );
}
const LayoutContainer = styled.div`
  display: flex;
 
`;

const MainContent = styled.main`
flex:1;
  padding: ${props => props.isLoggedIn ? '20px' : '0px'};
  transition: margin-left 0.3s ease-in-out;
  margin-left: ${props => props.isLoggedIn ? (props.isCollapsed ? '60px' : '250px') : '0px'};
  
  @media (max-width: 768px) {
    margin-left: ${props => props.isLoggedIn ? '60px' : '0px'};
  }
`;
export default Layout;
