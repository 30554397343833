import React from "react";
import { Link } from "react-router-dom";
import FileDragDrop from "../../components/FileDragDrop/FileDragDrop";
import { Container, Row, Col } from "react-bootstrap";

import "./Home.scss";

function Home({ isLoggedIn }) {
  console.log('isLoggedIn', isLoggedIn);
  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs={12} className="p-0">
          <div className="text-center">
            <main className="p-3">
              <h1 className="mt-4 mb-2">Welcome to the Home Page!</h1>
              <p className="mb-4">Believe you can and you're halfway there.</p>
              <div className="mb-4">
                <FileDragDrop />
              </div>
            </main>
            <nav className="mb-3">
              <Link to="/about" className="btn btn-primary">About</Link>
            </nav>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
