import React, { useState } from "react";
import { Card, Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCode } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import '../App.scss'

const WidgetIcon = styled.div`
width: 60px;
height: 60px;
min-width: 60px;
border-radius: 50%;
overflow: hidden;

border: 3px solid #fff;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
display: flex;
justify-content: center;
align-items: center;
background-color: #4a90e2;
`;
const WidgetCard = ({  widget,
  onDelete,
  index,
  updateDurationAtIndex,
  filters,
  updateFilterAtIndex,
}) => {
  
  const cardContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingLeft: "40px",
    paddingRight: "40px",
    flex:1,
    boxSizing: "border-box" /* Include padding in the width calculation */,

  };
  const cardStyle = {
    width: "calc(100% - 10px)", // Full width for single item
    marginBottom: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    border: "1px solid #e5e5e5",
    borderRadius: "8px",
    transition: "box-shadow 0.2s",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px",
  };

  const titleStyle = {
    fontSize: "24px", // Increase font size for the title
    marginBottom: "10px", // Add margin at the bottom of the title
  };

  const mediaContainerStyle = {
    display: "flex",
    alignItems: "center",
 
  };

  const mediaStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "4px",
    margin: "0 5px", // Add margin to separate images and videos
  };

  const infoContainerStyle = {
    flex: "1",
    display: "flex",
    flexDirection: "column", // Display text content in a column
    color: "black", // Set text color to black
  };

  const cardHoverStyle = {
    boxShadow: "0 4px 16px rgba(0, 0, 0, 0.15)",
    transform: "scale(1.04)",
  };

  const [hoveredItem, setHoveredItem] = useState(null);
  const [name, setName] = useState(null);
  const [label, setLabel] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(null);

  const [selectedFilter, setSelectedFilter] = useState(widget?.filter);

  const getSelectedFilterName = () => {
    console.log("getSElectedFilter", filters);
    const x = filters?.filter((f) => f._id === selectedFilter);
    console.log("getSelectedFilterName", x);
    return x[0]?.filtername;
  };
  const handleShowPopup = () => {
    setShowPopup(true); // Popup'ı göster
  };

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
  };

  const handleSaveFilter = () => {
    
    if (currentFilter) {
      updateFilterAtIndex?.(index, currentFilter._id);
      setSelectedFilter(currentFilter._id);
    }
    setShowPopup(false); // Popup'ı kapat
  };

  return (
    <div style={cardContainerStyle}>
       <Card
        key={widget.id}
        style={{
          ...cardStyle,
          ...(hoveredItem === widget.id ? cardHoverStyle : {}),
        }}
        onMouseEnter={() => setHoveredItem(widget.id)}
        onMouseLeave={() => setHoveredItem(null)}
      >
        <p
          style={{
            fontFamily: "Arial, sans-serif", // Use any desired font-family
            fontSize: "48px", // Adjust the font size as needed
            fontWeight: "bold",
            color: "#333", // Adjust the color to your preference
            textAlign: "center", // Align the text to the center horizontally
            margin: "0", // Remove default margin for the <p> element
          }}
        >
          {index + 1}
        </p>
        <div style={mediaContainerStyle}>
        <WidgetIcon>
            <FontAwesomeIcon icon={faCode} style={{ fontSize: '2rem', color:"#ffffff" }} />
          </WidgetIcon>
          <Card.Title style={titleStyle}>{widget.name}</Card.Title>
          
        </div>
        <div style={infoContainerStyle}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: " end",
            }}
          >
            {/* Add text input field for duration */}
            <Form.Group
              controlId={`duration-${widget.id}`}
              style={{ marginBottom: 0, marginRight: "20px" }}
            >
              <Form.Control
                type="text"
                placeholder={widget.duration ? `${widget.duration} secs` : "5 secs"}
                // You can add logic to handle duration here
                onChange={(e) => {
                  updateDurationAtIndex(index, e.target.value);
                }}
              />
            </Form.Group>
          
               {filters ? (
              <Button 
                variant="secondary" 
                onClick={handleShowPopup} 
                style={{ 
                  backgroundColor: '#6c757d', // Varsayılan arka plan rengi
                  borderColor: '#6c757d', // Varsayılan kenar rengi
                  marginRight:"20px"
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#cd204f'; // Hover rengi
                  e.target.style.borderColor = '#cd204f'; // Hover kenar rengi
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = '#6c757d'; // Varsayılan rengi geri getir
                  e.target.style.borderColor = '#6c757d'; // Varsayılan kenar rengi geri getir
                }}
              >
                {selectedFilter ? getSelectedFilterName() : "Select Filter"}
              </Button>
            ) : null}
            <Button
              variant="outline-danger"
              className="mr-2"
              onClick={() => onDelete(index)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
       
          {/* Add more widget-specific information here */}
        </div>
      </Card>
      {/* Popup için Modal */}
      <Modal show={showPopup} onHide={() => setShowPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Choose a filter:</p>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))', gap: '10px' }}>
            {filters.map((filter) => (
              <Button
                key={filter.id}
                variant="outline-primary"
                onClick={() => handleFilterChange(filter)} // Filtreyi seç
                style={{ 
                  display: 'block', 
                  margin: '5px 0',
                  backgroundColor: 'transparent', // Arka plan rengi
                  borderColor: '#cd204f', // Kenar rengi
                  color: '#cd204f' // Yazı rengi
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#cd204f'; // Hover rengi
                  e.target.style.color = 'white'; // Hover yazı rengi
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'transparent'; // Varsayılan arka plan rengi
                  e.target.style.color = '#cd204f'; // Varsayılan yazı rengi
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = '#cd204f'; // Tıklama sonrası border rengi
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = '#cd204f'; // Tıklama sonrası border rengi
                }}
              >
                {filter.filtername}
              </Button>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPopup(false)}>
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSaveFilter} 
            disabled={!currentFilter}
            style={{ 
              backgroundColor: '#cd204f', // Confirm butonunun arka plan rengi
              borderColor: '#cd204f' // Confirm butonunun kenar rengi
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#a61e34'; // Hover rengi
              e.target.style.borderColor = '#a61e34'; // Hover kenar rengi
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#cd204f'; // Varsayılan rengi geri getir
              e.target.style.borderColor = '#cd204f'; // Varsayılan kenar rengi geri getir
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WidgetCard;
