import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

import AR from './locales/ar/translation.json';
import DE from './locales/de/translation.json';
import EN from './locales/en/translation.json';
import ES from './locales/es/translation.json';
import FR from './locales/fr/translation.json';
import HI from './locales/hi/translation.json';
import JA from './locales/ja/translation.json';
import KO from './locales/ko/translation.json';
import PT from './locales/pt/translation.json';
import TR from './locales/tr/translation.json';
import UR from './locales/ur/translation.json';


import XHR from "i18next-http-backend" // <---- add this

export const resources = {
  ar: {
    translation: AR,
  },
  de: {
    translation: DE,
  },
  en: {
    translation: EN,
  },
  es: {
    translation: ES,
  },
  fr: {
    translation: FR,
  },
  hi: {
    translation: HI,
  },
  ja: {
    translation: JA,
  },
  tr: {
    translation: TR,
  },
  ko: {
    translation: KO,
  },
  pt: {
    translation: PT,
  },
  ur: {
    translation: UR,
  },
};


i18n
.use(XHR)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    resources,
    fallbackLng: ['en', 'tr','de','fr','es','hi','ja','ko','pt','ur','ar'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n