import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const DeleteConfirmationModal = ({
  show,
  loading,
  handleClose,
  handleConfirmDelete,
  title,
  body,
  buttonText
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status" />
            <p>Deleting...</p>
          </div>
        ) : (
          body
        )}
      </Modal.Body>
      <Modal.Footer>
        {!loading && (
          <>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              {buttonText??'Delete'}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
