import { useContext, useState } from "react";
import { toast } from "react-toastify";
import validator from "validator";

import { AuthContext } from "../../contexts/AuthContext";
import { Button } from "react-bootstrap";
import axios from "../../api/axios";
import { changePasswordRoute, companyList, list,updateCompany } from "../../api/routes";
import FormField from "../../components/FormField/FormField";
import { useTranslation } from 'react-i18next';
import "./Profile.scss";

const Profile = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [file, setFile] = useState()
  const [description, setDescription] = useState("")
  const { t } = useTranslation(); 
  const submit = async event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append("image", file)
    formData.append("description", description)
  
    const result = await axios.post('/user/company-profile', formData, { headers: {'Content-Type': 'multipart/form-data',companyName:'emress'},body:{companyName:'emress'}})
    console.log(result.data)

    
   /*
    const result = await axios.post('/media/uploadMedia', formData, { headers: {'Content-Type': 'multipart/form-data',companyName:'emress',type:'image'},body:{companyName:'emress'}})
    console.log(result.data)
*/
 
    // Send the file and description to the server
  }
  const { user } = useContext(AuthContext);
  console.log('user,',user)
  const handleValidation = (event) => {
    let formIsValid = true;

    if (
      !validator.isStrongPassword(currentPassword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      formIsValid = false;
      setpasswordError(
        t('description.passworderror')      );
      return false;
    } else {
      setpasswordError("");
      formIsValid = true;
    }
    setFormValid(formIsValid);
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    handleValidation();

    if (formValid) {
      axios
        .post(changePasswordRoute, {
          email: user.email,
          currentPassword: currentPassword,
          newPassword: newPassword,
          newConfirmPassword: newConfirmPassword,
        })
        .then(function (response) {
          toast.success(t('description.passwordchanged'));
        })
        .catch(function (error) {
          toast.error(error.response.data.msg);
        });
    }
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-md-4">
          <form id="loginform" onSubmit={loginSubmit} className="login-form">
            <h3 className="text-center login-header">Change Password</h3>
            <div className="form-group mt-4">
              <h5>{t('description.currentpassword')}</h5>
              <FormField
                type="password"
                placeholder="Enter Password"
                setFunc={setCurrentPassword}
              />
              <small id="passworderror" className="text-danger form-text">
                {passwordError}
              </small>
            </div>

            <div className="form-group mt-4">
              <h5>{t('description.newpassword')}</h5>
              <FormField
                type="password"
                placeholder="Enter Password"
                setFunc={setNewPassword}
              />
            </div>

            <div className="form-group mt-4">
              <h5>{t('description.confirmpassword')}</h5>
              <FormField
                type="password"
                placeholder="Enter Password"
                setFunc={setNewConfirmPassword}
              />
            </div>
            <div className="text-center submit-btn">
              <button type="submit" className="btn btn-dark mt-4">
              {t('description.submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
      <form onSubmit={submit}>
        <input
          filename={file} 
          onChange={e => setFile(e.target.files[0])} 
          type="file" 
          accept="image/video"
        ></input>
        <input
          onChange={e => setDescription(e.target.value)} 
          type="text"
        ></input>
        <button type="submit">   {t('description.submit')}</button>
      </form>
   
      <Button onClick={()=>{
         axios
         .post(updateCompany,{
          email:'w@w.com',
          companyName: 'emress',
          location: 'sincan',
          companyid: user?.company[0]?._id,
        })
         .then(function (response) {
          console.log('responses',response)
           toast.success("Password Changed Successfully!");
         })
         .catch(function (error) {
          toast.error(error); 
          //toast.error(error.response.data.msg);
         });
      }}>Add company</Button>
      <Button onClick={()=>{
         axios
         .post(companyList,{
          email:user.email
        })
         .then(function (response) {
          console.log('responses',response)
           toast.success("Companlist get it!");
         })
         .catch(function (error) {
          toast.error(error); 
          //toast.error(error.response.data.msg);
         });
      }}>get company</Button>
    </div>
  );
};

export default Profile;
